import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { SvgIcon } from "../../icons";

import { staticTexts } from "../../utils/Translate";
import { appDownloadLinks } from "../../utils/utils";

import "./mobile-play-store.m.css";

const MobilePlayStore = ({ lang, domainSlug }) => (
  <div styleName="wrapper">
    <span styleName="description">{staticTexts(lang).downloadMobileApps}</span>

    <div styleName="app-store">
      <a
        aria-label="Prothomalo app link"
        href={appDownloadLinks(lang, "google", domainSlug)}
        styleName="app-store-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SvgIcon type="playstore" />
      </a>
      <a
        aria-label="Prothom alo apple store app link"
        href={appDownloadLinks(lang, "apple", domainSlug)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SvgIcon type="appstore" />
      </a>
    </div>
  </div>
);

MobilePlayStore.propTypes = {
  lang: string,
  domainSlug: string,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
  domainSlug: get(state, ["qt", "config", "domainSlug"], ""),
});

export default connect(mapStateToProps)(MobilePlayStore);
