import wretch from "wretch";
import axios from "axios";
import { memoize } from "lodash";

import { urlToBase64 } from "../utils/utils";

export function prayerApi() {
  return fetch("https://services.prothomalo.com/api/prayer-time");
}

export function login(body, callbackUrl, redirectUrl, authHost) {
  return wretch()
    .options({ credentials: "include" })
    .url(`${authHost}/api/auth/v1/login`)
    .query({
      "callback-url": callbackUrl,
      "redirect-url": redirectUrl,
    })
    .post(body)
    .json((res) => {
      return res;
    })
    .catch((ex) => Promise.reject(new Error(ex.message)));
}

export async function getCollectionBySlug(slug, fields, limit, otherParams = {}) {
  return wretch()
    .url(`/api/v1/collections/${slug}`)
    .query({
      limit,
      fields,
      "item-type": "story",
      ...otherParams,
    })
    .get()
    .json((response) => response || {});
}

export function register(body, callbackUrl, redirectUrl, authHost) {
  return wretch()
    .options({ credentials: "include" })
    .url(`${authHost}/sso-signup`)
    .query({
      "callback-url": callbackUrl,
      "redirect-url": redirectUrl,
    })
    .post(body)
    .json((res) => {
      return res;
    })
    .catch((ex) => Promise.reject(ex));
}

// Logout the User
export function logout() {
  return wretch()
    .options({ credentials: "include" })
    .url("/api/auth/v1/logout")
    .get()
    .res(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

export function sendOTP(email) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/send-otp")
    .post({
      email,
      mediums: ["email"],
      "always-send": true,
    })
    .json(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

// Auth link
export function emailLink(email, redirectUrl) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/send-verification-link")
    .post({
      email: email,
      "redirect-url": redirectUrl,
    })
    .json(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

// Send the email with OTP for verification for resetting the password
export function forgotPassword(email, redirectUrl) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/forgot-password")
    .post({ email, "current-host": redirectUrl })
    .res(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

// Verify the email by validating OTP
export function verifyEmailOtp(otp, email, password = null) {
  const member = {
    "verification-status": "email",
  };

  if (password) {
    member.password = password;
  }

  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/api/auth/v1/users/reset-password`)
    .post({
      email: email,
      otp: otp.toString(),
      "new-password": password,
    })
    .json((res) => Promise.resolve(res))
    .catch((ex) => Promise.reject(ex));
}

// Returns the current User
export function getUser() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/me")
    .get()
    .json((res) => Promise.resolve(res))
    .catch((ex) => Promise.reject(ex));
}

// Reset the password
export function resetPassword(token, password, repeatPassword) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/reset-password-with-token")
    .post({
      token,
      "new-password": password,
      "confirm-password": repeatPassword,
    })
    .json((res) => Promise.resolve(res))
    .catch((ex) => {
      if (ex.message) {
        const res = JSON.parse(ex.message);
        return Promise.reject(res.error);
      }

      return Promise.reject(ex);
    });
}

// Update User Information
export function updateUserInformation(data) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/member/metadata")
    .post(data)
    .res(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

// Get Author Stories
export function getAuthorStories(authorId, params) {
  return wretch()
    .url(`/api/v1/authors/${authorId}/collection`)
    .query(params)
    .get()
    .json((response) => (response.items || []).map((item) => item.story));
}

// Get Search Stories

const getQueryObject = (offset, itemsToLoad, queryParams, storyFields) => {
  const { q, ...otherParams } = queryParams;

  if (!q)
    return {
      fields: storyFields,
      offset: offset,
      limit: itemsToLoad,
      sort: "latest-published",
      ...otherParams,
    };

  return {
    fields: storyFields,
    offset: offset,
    limit: itemsToLoad,
    ...queryParams,
  };
};

export function getSearchStories(offset, itemsToLoad, queryParams) {
  const storyFields =
    // eslint-disable-next-line max-len
    "headline,subheadline,slug,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata,tags,cards,access";

  return wretch()
    .url("/api/v1/advanced-search")
    .query(getQueryObject(offset, itemsToLoad, queryParams, storyFields))
    .get()
    .json((response) => response);
}

// Get Poll Stories
export function getPollingStories(collectionSlug, fields, offset = 0) {
  return wretch()
    .url(`api/v1/collections/${collectionSlug}`)
    .query({
      "item-type": "story",
      fields: fields,
      limit: 10,
      offset: offset,
    })
    .get()
    .json((response) => response.items || []);
}

// Get all the authors
export const getAllAuthors = memoize(async () => {
  return await wretch()
    .url("/api/v1/authors")
    .query({ limit: 1 })
    .get()
    .json((response) => {
      return wretch()
        .url("/api/v1/authors")
        .query({ limit: response.page.total })
        .get()
        .json((response) => response.authors);
    });
});

// get breaking news
export async function getBreakingNews(breakingApi) {
  try {
    return await axios.get(breakingApi);
  } catch (error) {
    console.log(error);
  }
}

// Get Story
export function getStory(storyId) {
  return wretch()
    .url(`/api/v1/stories/${storyId}`)
    .get()
    .json((response) => response || {});
}

// Get the JWT token (User should be logged in to call this api)
export function getJwt(integrationsId) {
  return fetch(`/access-token/integrations/${integrationsId}`).then((response) => response);
}

/* Metype Bookmarks - Starts Here */

// Get Comments of a User
export function getUserComments(metypeConfig, jwt, page, perPage = 20) {
  const url = `${metypeConfig.metypeHost}/api/v1/activity/comments`;

  return wretch(url)
    .query({
      account_id: metypeConfig.metypeAccountId,
      per_page: perPage,
      page,
      jwt,
    })
    .headers({ Origin: `${metypeConfig.metypeHost}` })
    .get()
    .json((response) => response.data || []);
}

// Get Bookmark Stories of particular user
export function getBookmarkStories(metypeConfig, token, page = 1) {
  const url = `${metypeConfig.metypeHost}/api/v1/accounts/${metypeConfig.metypeAccountId}/bookmarks`;

  return wretch()
    .url(url)
    .query({ jwt: token, page: page })
    .get()
    .json((response) => {
      if (!(response.data && response.data.length)) {
        return [];
      }

      return response.data.map((item) => item.attributes.metadata);
    })
    .catch((ex) => Promise.reject(ex));
}

export function createBookmark(metypeConfig, storyUrl, data, token) {
  const pageId = urlToBase64(storyUrl);
  const url = `${metypeConfig.metypeHost}/api/v1/accounts/${metypeConfig.metypeAccountId}/pages/${pageId}/bookmark?jwt=${token}`;

  return wretch()
    .headers({ Referer: "", "Content-Type": "application/json", Origin: `${metypeConfig.metypeHost}` })
    .url(url)
    .post(data);
}

export function getBookmarkByPageId(metypeConfig, storyUrl, token) {
  const base64Url = urlToBase64(storyUrl);
  const url = `${metypeConfig.metypeHost}/api/v1/accounts/${metypeConfig.metypeAccountId}/pages/${base64Url}/bookmark`;

  return wretch()
    .url(url)
    .query({ jwt: token })
    .get()
    .json((response) => response.data || null);
}

export function getCommentsCountForStories(metypeConfig, encodedUrl) {
  const url = `${metypeConfig.metypeHost}/api/v1/accounts/${metypeConfig.metypeAccountId}/pages?page_ids=${encodedUrl}`;

  return wretch()
    .url(url)
    .get()
    .json((response) => response || []);
}

export function deleteBookmarkByPageId(metypeConfig, storyUrl, token) {
  const base64Url = urlToBase64(storyUrl);
  const url = `${metypeConfig.metypeHost}/api/v1/accounts/${metypeConfig.metypeAccountId}/pages/${base64Url}/bookmark`;

  return wretch()
    .url(url)
    .query({ jwt: token })
    .delete()
    .res(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

/* Metype Bookmarks - Ends Here */
export const getSoriesBySlug = async (storySlug) => {
  const response = await fetch(`/api/v1/stories-by-slug?slug=${storySlug}`);
  const storyObject = await response.json();
  return storyObject.story;
};

// Get first author object from current Story
export const getAuthorObj = async (authorId) => {
  if (authorId) {
    const response = await fetch(`/api/v1/authors/${authorId}`);
    const authorObject = await response.json();
    return authorObject.author;
  }
};

// Get sponsor object from current Story
export const getSponsorObj = async (entityId) => {
  if (entityId) {
    const response = await fetch(`/api/v1/entities/${entityId}`);
    const sponsorObject = await response.json();
    return sponsorObject.entity;
  }
};

// Sign an image
export function signImage(fileName, mimeType) {
  const url = `/api/sign?file-name=${fileName}&mime-type=${mimeType}`;

  return wretch()
    .url(url)
    .get()
    .json((response) => response || {});
}

export function getDistrictTopics() {
  const url = "/front-end/district-topics";

  return wretch()
    .url(url)
    .get()
    .json((response) => response || {});
}

export function getCountriesList() {
  const url = "/front-end/countries-list";

  return wretch()
    .url(url)
    .get()
    .json((response) => response || []);
}

export function menuGroups(hostUrl) {
  const url = hostUrl ? `${hostUrl}/api/v1/menu-groups` : "/api/v1/menu-groups";

  return wretch()
    .url(url)
    .get()
    .json((response) => response || []);
}

export const uploadS3ToTemp = (res, formdata) => {
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${res.action}`, requestOptions).then((response) => {
    return response;
  });
};

export const memberProfileUpdate = (obj) => {
  const patchObj = {};
  if (obj.name) {
    patchObj.name = obj.name;
  } else if (obj.s3Key) {
    patchObj["temp-s3-key"] = obj.s3Key;
  }
  const url = "/api/auth/v1/users/profile";
  return wretch()
    .url(url)
    .post(patchObj)
    .json((res) => Promise.resolve(res))
    .catch((ex) => Promise.reject(ex));
};

export const getEntities = (type) => {
  return fetch(`/api/v1/entities/?type=${type}`).then((response) => response.json());
};

export const fetchNotifications = (offset = 0, limit = 4) => {
  fetch(`/feapi/push-engage-notifications?limit=${limit}&offset=${offset}`).then((res) => res.json());
};

export const getPollData = async (setPollData, pollTypeHostUrl, polltypeId) => {
  const response = await fetch(`${pollTypeHostUrl}/p/api/polls/${polltypeId}`);
  const result = await response.json();
  setPollData(result.poll);
};

export const getCreStories = (api = "", userId = "", limit = 4) => {
  if (!(api && userId)) return Promise.reject(new Error("User id not found"));

  const body = {
    user_id: userId,
    limit: limit,
  };

  const url = new URL(api);

  const instance = axios.create({
    baseURL: `${url.protocol}//${url.hostname}`,
    responseType: "json",
  });

  return instance.post(url.pathname, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export async function getRelatedStories(story, sections, domainSlug, isType = false, isTag = false, limit = 8) {
  const firstStorySeo = story?.seo?.["meta-keywords"]?.[0] || null;
  const requiredSectionIds = sections.filter((item) => item["domain-slug"] === domainSlug).map((item) => item.id);
  const sectionIds = requiredSectionIds.length > 0 ? requiredSectionIds.toString() : "";
  const storyType = story["story-template"];

  const typeTagParams = `${isType ? `&type=${storyType}` : ""}${
    isTag ? `&tag-name=${decodeURIComponent(firstStorySeo)}` : ""
  }`;

  const fields =
    "headline,subheadline,slug,url,last-published-at,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,story-template,metadata";

  const EXTRA_STORIES_COUNT = 2; // Fetch two extra stories to account for the possibility of skipping the existing story in the related stories
  try {
    const response = await axios.get(
      `/api/v1/advanced-search?limit=${
        limit + EXTRA_STORIES_COUNT
      }&section-id=${sectionIds}&sort=latest-published&fields=${fields}${typeTagParams}`
    );

    const { items = [] } = response?.data || {};

    const stories = items.reduce((acc, item) => {
      if (item.id !== story.id && acc.length < limit) {
        acc.push(item);
      }

      return acc;
    }, []);
    return stories;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getHeaderCollection = (api) => {
  return wretch(api).options({ credentials: "same-origin" }).get().json();
};
