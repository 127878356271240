import React from "react";
import { Link } from "@quintype/components";
import { string } from "prop-types";
import { useSelector } from "react-redux";

import { brokenStoryUrl, isUrlEncoded, linkTarget, validURL } from "../../utils/utils";

const Anchor = ({ href = "/", target, ...props }) => {
  if (href === "#") {
    return React.createElement("div", { ...props });
  }

  const host = useSelector((state) => state?.qt?.currentHostUrl);

  target = target || linkTarget(host, href) || "_self";

  if (!validURL(href)) {
    const slush = href?.match(/^\//gm);
    href = slush ? href : "/" + href;
  }

  const url = brokenStoryUrl(href);

  if (url && !isUrlEncoded(href)) {
    href = url;
    return React.createElement("a", { target: "_self", href, ...props });
  }

  return React.createElement(target === "_blank" ? "a" : Link, { target, href, ...props });
};

Anchor.defaultProps = {
  "aria-label": "",
};

Anchor.propTypes = {
  href: string,
  target: string,
  "aria-label": string,
};

export default Anchor;
