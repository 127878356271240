/* eslint-disable max-len */
import React from "react";
import { number, string } from "prop-types";

export const TodaysTime = ({ color = "#fff", height = "", width = "" }) => (
  <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <g>
      <path
        d="M16 28C12.9333 28 10.2611 26.9833 7.98333 24.95C5.70556 22.9167 4.4 20.3778 4.06667 17.3333H6.8C7.11111 19.6444 8.13889 21.5556 9.88333 23.0667C11.6278 24.5778 13.6667 25.3333 16 25.3333C18.6 25.3333 20.8056 24.4278 22.6167 22.6167C24.4278 20.8056 25.3333 18.6 25.3333 16C25.3333 13.4 24.4278 11.1944 22.6167 9.38333C20.8056 7.57222 18.6 6.66667 16 6.66667C14.4667 6.66667 13.0333 7.02222 11.7 7.73333C10.3667 8.44444 9.24444 9.42222 8.33333 10.6667H12V13.3333H4V5.33333H6.66667V8.46667C7.8 7.04444 9.18333 5.94444 10.8167 5.16667C12.45 4.38889 14.1778 4 16 4C17.6667 4 19.2278 4.31667 20.6833 4.95C22.1389 5.58333 23.4056 6.43889 24.4833 7.51667C25.5611 8.59444 26.4167 9.86111 27.05 11.3167C27.6833 12.7722 28 14.3333 28 16C28 17.6667 27.6833 19.2278 27.05 20.6833C26.4167 22.1389 25.5611 23.4056 24.4833 24.4833C23.4056 25.5611 22.1389 26.4167 20.6833 27.05C19.2278 27.6833 17.6667 28 16 28ZM19.7333 21.6L14.6667 16.5333V9.33333H17.3333V15.4667L21.6 19.7333L19.7333 21.6Z"
        fill={color}
      />
    </g>
  </svg>
);

TodaysTime.propTypes = {
  color: string,
  height: number,
  width: number,
};

export const TodaysTV = ({ color = "#fff", height = "", width = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.014 24"
    enableBackground="new 0 0 24.014 24"
    xmlSpace="preserve"
    height={height}
    width={width}
  >
    <path
      fill={color}
      d="M6.531,0.023c0.141-0.031,0.288-0.031,0.429,0C7.163,0.121,7.348,0.252,7.509,0.41
                c0.292,0.267,0.585,0.533,0.878,0.8c0.115,0.1,0.234,0.2,0.351,0.3c0.071,0.087,0.155,0.162,0.249,0.223
                c0.227,0.21,0.451,0.423,0.681,0.63c0.386,0.346,0.776,0.689,1.164,1.034s0.763,0.679,1.144,1.019c0.064,0.063,0.1,0,0.134-0.034
                c0.533-0.478,1.065-0.956,1.594-1.435c0.439-0.4,0.877-0.8,1.314-1.2c0.095-0.07,0.183-0.151,0.261-0.24
                c0.19-0.167,0.383-0.333,0.571-0.5c0.229-0.206,0.455-0.416,0.682-0.625c0.09-0.075,0.177-0.156,0.272-0.226
                c0.325-0.253,0.793-0.194,1.046,0.13c0.031,0.041,0.059,0.084,0.081,0.131c0.015,0.028,0.032,0.055,0.049,0.082
                c0.033,0.151,0.033,0.307,0,0.458c-0.06,0.145-0.148,0.276-0.26,0.386c-0.059,0.054-0.14,0.085-0.176,0.163l-1.406,1.256
                c-0.458,0.411-0.915,0.823-1.37,1.237c-0.153,0.138-0.3,0.28-0.475,0.44h6.7c0.328,0.017,0.656-0.006,0.985,0.023
                c0.984,0.103,1.786,0.836,1.977,1.807c0.047,0.412,0.065,0.827,0.053,1.242v11.982c-0.009,0.374,0.009,0.748-0.036,1.12
                l-0.108,0.385c-0.14,0.335-0.349,0.637-0.615,0.885c-0.225,0.188-0.479,0.341-0.751,0.451c-0.3,0.089-0.611,0.13-0.924,0.122
                c-0.159-0.007-0.317,0-0.476,0c-0.037,0-0.1-0.027-0.1,0.054c0,0.447-0.008,0.894,0,1.341c0,0.114-0.031,0.149-0.145,0.144
                c-0.2-0.009-0.4,0-0.6,0c-0.155-0.462-0.314-0.923-0.461-1.388c-0.016-0.099-0.109-0.167-0.208-0.151c-0.001,0-0.001,0-0.002,0
                c-2.992,0-5.984,0-8.977,0c-2.067,0-4.133,0-6.2,0c-0.056,0-0.133-0.037-0.163,0.054c-0.153,0.456-0.309,0.912-0.458,1.37
                c-0.027,0.084-0.064,0.117-0.153,0.114c-0.21-0.006-0.42,0-0.631,0l-0.007-1.482c0-0.09-0.072-0.052-0.112-0.052
                c-0.251,0-0.5,0-0.754-0.006c-0.504-0.015-0.985-0.21-1.358-0.55c-0.46-0.388-0.737-0.951-0.762-1.552
                c-0.01-0.151-0.016-0.3-0.016-0.451c0-4.368,0-8.735,0-13.1c-0.017-0.467,0.108-0.929,0.357-1.324
                c0.249-0.382,0.606-0.681,1.026-0.859c0.305-0.116,0.63-0.173,0.956-0.169c0.224-0.009,0.448,0,0.671,0h3.1h1.394h2.236
                c-0.067-0.063-0.1-0.1-0.146-0.137c-0.698-0.627-1.398-1.253-2.1-1.88C7.132,2.116,6.776,1.804,6.421,1.49
                c-0.169-0.136-0.307-0.307-0.406-0.5c-0.048-0.16-0.048-0.33,0-0.49C6.119,0.28,6.304,0.109,6.531,0.023z M8.989,20.95h4.524h0.055
                c2.728,0,5.456,0,8.183,0c0.407,0.01,0.746-0.312,0.756-0.719c0-0.016,0-0.031,0-0.047c0-4.487,0-8.973,0-13.46
                c0.002-0.059-0.004-0.118-0.016-0.176c-0.082-0.373-0.427-0.629-0.808-0.6H12.06c-0.52,0-1.041,0-1.562,0H7.537
                c-0.328,0-0.656,0-0.984,0h-3.54h-0.8C1.841,5.969,1.54,6.257,1.502,6.627c-0.006,0.292,0,0.584,0,0.876
                c0,0.045-0.005,0.091-0.005,0.136V19.42c0,0.269,0,0.538,0,0.808c0.015,0.387,0.325,0.697,0.712,0.711c0.265,0.005,0.53,0,0.794,0
                h5.978L8.989,20.95z"
    />
    <path
      fill={color}
      d="M8.989,19.5l-1.481-0.028H7.371c-0.02-0.009-0.041-0.016-0.063-0.02L6,19.417
                c-0.091,0-0.182,0.005-0.272,0c-0.412-0.034-0.824-0.072-1.236-0.108c-0.314-0.05-0.632-0.08-0.946-0.133
                c-0.134-0.009-0.243-0.111-0.262-0.244c-0.086-0.373-0.138-0.753-0.157-1.136c-0.05-0.7-0.077-1.393-0.1-2.091
                C2.995,14.576,3.01,13.448,3.01,12.32c0-0.593,0.01-1.187,0.033-1.78c0.019-0.515,0.063-1.028,0.1-1.542
                c0.015-0.342,0.071-0.681,0.166-1.01C3.323,7.915,3.383,7.86,3.457,7.853c0.346-0.049,0.691-0.105,1.038-0.141
                c0.6-0.061,1.208-0.1,1.814-0.117c0.533-0.017,1.066-0.035,1.6-0.059C8.278,7.52,8.648,7.53,9.017,7.511
                c0.172,0,0.345,0.007,0.517,0c0.322-0.006,0.644,0.013,0.965-0.014c0.5,0.024,1.005,0.013,1.507,0.01
                c0.452,0.012,0.9,0.021,1.355,0.034c0.588,0.018,1.177,0.027,1.764,0.062c0.739,0.045,1.479,0.093,2.212,0.21
                c0.325,0.051,0.371,0.084,0.424,0.4c0.074,0.45,0.115,0.9,0.146,1.357c0.056,0.857,0.064,1.714,0.086,2.571s0.017,1.69,0.01,2.534
                c-0.005,0.689-0.019,1.378-0.053,2.066c-0.02,0.683-0.083,1.364-0.19,2.039c-0.021,0.089-0.047,0.176-0.077,0.262
                c-0.025,0.087-0.1,0.1-0.171,0.115c-0.489,0.086-0.982,0.136-1.477,0.178c-0.449,0.038-0.9,0.061-1.348,0.094
                c-0.285,0.031-0.572,0.042-0.858,0.035c-0.123-0.008-0.246,0-0.37,0.005l-1.453,0.027l-1.508,0.006L8.989,19.5z M13.513,9.053
                c-0.5-0.022-1.005-0.042-1.508-0.03c-0.5-0.028-1.005-0.031-1.508-0.018c-0.456-0.007-0.912,0-1.368,0.009
                C8.152,9.027,7.177,9.05,6.201,9.098C5.709,9.121,5.219,9.159,4.728,9.187c-0.078,0.005-0.1,0.033-0.1,0.106
                c-0.067,0.622-0.081,1.247-0.1,1.87c-0.021,0.821-0.016,1.643-0.012,2.464c0,0.94-0.02,1.881,0.031,2.821
                c0.023,0.423,0.032,0.847,0.077,1.269c0.008,0.072,0.024,0.1,0.1,0.107c0.773,0.042,1.546,0.1,2.32,0.131
                c1.109,0.042,2.219,0.042,3.328,0.04c0.543,0,1.087,0,1.63-0.008c0.5,0.013,1.006,0,1.508-0.028
                c0.495,0.008,0.988-0.032,1.481-0.059c0.44-0.026,0.88-0.053,1.32-0.078c0.062,0,0.074-0.034,0.076-0.087
                c0.017-0.409,0.068-0.816,0.058-1.226c0.049-0.5,0.016-1.006,0.045-1.509l0.008-1.508l-0.007-1.481
                c-0.006-0.317-0.012-0.634-0.019-0.951c0.009-0.186,0-0.373-0.027-0.557c0.01-0.406-0.043-0.81-0.058-1.216
                c0-0.059-0.021-0.094-0.086-0.1c-0.437-0.022-0.872-0.075-1.31-0.081C14.501,9.078,14.008,9.054,13.513,9.053L13.513,9.053z"
    />
    <path
      fill={color}
      d="M21.001,11.207c0,0.059,0,0.119,0,0.178c0.01,0.305-0.229,0.561-0.534,0.571
                c-0.012,0-0.024,0-0.036,0c-0.1,0-0.192,0-0.288,0c-0.309,0.038-0.59-0.182-0.628-0.492c-0.002-0.017-0.003-0.034-0.004-0.05
                c-0.053-0.234-0.023-0.478,0.083-0.693c0.095-0.162,0.267-0.262,0.455-0.265c0.182-0.019,0.366-0.012,0.546,0.022
                c0.219,0.063,0.378,0.254,0.4,0.481c0,0.082,0,0.165,0,0.247L21.001,11.207z"
    />
    <path
      fill={color}
      d="M21.001,14.208c0.023,0.164-0.004,0.33-0.078,0.478c-0.088,0.142-0.234,0.238-0.4,0.261
                c-0.176,0.029-0.354,0.029-0.53,0c-0.197-0.021-0.365-0.15-0.435-0.335c-0.105-0.271-0.1-0.572,0.013-0.84
                c0.079-0.192,0.268-0.316,0.476-0.31c0.128,0,0.257,0.006,0.384,0c0.305-0.009,0.56,0.231,0.569,0.537c0,0.01,0,0.021,0,0.031
                C20.997,14.089,20.997,14.149,21.001,14.208z"
    />
  </svg>
);

TodaysTV.propTypes = {
  color: string,
  height: number,
  width: number,
};

export const TodaysDefault = ({ color = "#fff", height = "", width = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    height={height}
    width={width}
  >
    <path
      fill={color}
      d="M2,6.25C2,5.559,2.559,5,3.25,5h15c0.691,0,1.25,0.559,1.25,1.25S18.941,7.5,18.25,7.5h-15
	C2.559,7.5,2,6.941,2,6.25z M4.5,12.5c0-0.691,0.559-1.25,1.25-1.25h15c0.691,0,1.25,0.559,1.25,1.25s-0.559,1.25-1.25,1.25h-15
	C5.059,13.75,4.5,13.191,4.5,12.5z M19.5,18.75c0,0.691-0.559,1.25-1.25,1.25h-15C2.559,20,2,19.441,2,18.75s0.559-1.25,1.25-1.25
	h15C18.941,17.5,19.5,18.059,19.5,18.75z"
    />
  </svg>
);

TodaysDefault.propTypes = {
  color: string,
  height: number,
  width: number,
};

export const AudioOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      d="M8.74 25.333c-.57 0-1.059-.203-1.465-.609a1.997 1.997 0 0 1-.609-1.465V16c0-1.296.246-2.51.739-3.643A9.487 9.487 0 0 1 9.4 9.402a9.487 9.487 0 0 1 2.956-1.996 9.03 9.03 0 0 1 3.642-.74 9.03 9.03 0 0 1 3.643.74 9.487 9.487 0 0 1 2.956 1.996 9.487 9.487 0 0 1 1.996 2.956A9.03 9.03 0 0 1 25.333 16v7.26c0 .57-.203 1.058-.61 1.464-.406.406-.894.61-1.464.61h-2.075c-.57 0-1.058-.204-1.464-.61a1.997 1.997 0 0 1-.61-1.465v-4.148c0-.57.204-1.058.61-1.465.406-.406.894-.609 1.465-.609h2.074V16c0-2.022-.705-3.738-2.113-5.146-1.409-1.409-3.124-2.113-5.147-2.113-2.022 0-3.737.704-5.146 2.113C9.444 12.262 8.74 13.978 8.74 16v1.037h2.074c.57 0 1.059.203 1.465.61.406.406.61.894.61 1.464v4.148c0 .57-.204 1.059-.61 1.465-.406.406-.895.61-1.465.61H8.74zm0-2.074h2.074v-4.148H8.74v4.148zm12.444 0h2.075v-4.148h-2.075v4.148z"
      fill="#D60000"
    />
  </svg>
);

export const InterviewOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.527 6.815v1.459a.74.74 0 0 1-1.482 0V6.297c0-.532.431-.964.964-.964h10.694c.532 0 .964.432.964.964v7.46a.964.964 0 0 1-.964.964h-2.222l-2.256 2.619a.964.964 0 0 1-1.68-.465l-.37-2.154h-.49a.74.74 0 0 1 0-1.482h.926c.47 0 .87.338.95.8l.258 1.502 1.694-1.967a.964.964 0 0 1 .73-.335h1.942V6.815h-9.658z"
      fill="#D60000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.367 17.214a3.158 3.158 0 1 0 0-6.316 3.158 3.158 0 0 0 0 6.316zm0 1.482a4.64 4.64 0 1 0 0-9.28 4.64 4.64 0 0 0 0 9.28z"
      fill="#D60000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.441 19.07a6.2 6.2 0 0 0-6.2 6.199.74.74 0 0 1-1.481 0 7.681 7.681 0 0 1 15.362 0 .74.74 0 0 1-1.482 0 6.2 6.2 0 0 0-6.199-6.2z"
      fill="#D60000"
    />
    <path
      d="M5.044 12.201a3.158 3.158 0 0 1 5.759-1.792.74.74 0 0 0 1.22-.842A4.64 4.64 0 1 0 5.7 16.11a7.682 7.682 0 0 0-4.367 6.932.74.74 0 0 0 1.482 0 6.2 6.2 0 0 1 6.2-6.2c.325 0 .602-.21.701-.503a.74.74 0 0 0-.824-1.054 3.158 3.158 0 0 1-3.85-3.083z"
      fill="#D60000"
    />
  </svg>
);

export const PhotoOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      fill="#D60000"
      d="M7.408 25.333c-.57 0-1.059-.203-1.465-.609a1.997 1.997 0 0 1-.609-1.465V10.815c0-.57.203-1.059.61-1.465.405-.406.894-.61 1.464-.61h3.267l1.296-1.4c.19-.224.42-.392.687-.505.268-.112.549-.168.843-.168h4.407c.294 0 .575.056.843.168.268.113.497.281.687.506l1.296 1.4h3.267c.57 0 1.058.203 1.464.61.407.405.61.893.61 1.464v12.444c0 .57-.203 1.059-.61 1.465-.406.406-.894.61-1.464.61H7.408zm16.593-2.074V10.815H7.408v12.444h16.593z"
    />
    <circle cx="15.734" cy="17.067" r="3.333" fill="#D60000" />
  </svg>
);

export const ShortsOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.134 7.333h-9.43a.724.724 0 0 0-.691.864c.44 2.409 1.005 5.842 1.005 7.803 0 1.961-.565 5.394-1.005 7.803a.724.724 0 0 0 .69.864h9.43a.725.725 0 0 0 .691-.864c-.44-2.41-1.006-5.842-1.006-7.803 0-1.961.566-5.394 1.006-7.803a.725.725 0 0 0-.69-.864zm-9.43-2c-1.694 0-2.963 1.557-2.659 3.223.449 2.454.973 5.699.973 7.444s-.524 4.99-.973 7.444c-.304 1.666.965 3.223 2.658 3.223h9.43c1.694 0 2.963-1.558 2.659-3.223-.449-2.454-.974-5.699-.974-7.444s.525-4.99.974-7.444c.304-1.666-.965-3.223-2.658-3.223h-9.43z"
      fill="#D60000"
    />
    <path
      d="M13.906 19.358a.508.508 0 0 1-.581.027.553.553 0 0 1-.296-.51V13.54c0-.226.099-.396.296-.51a.508.508 0 0 1 .581.027l4.188 2.667c.18.12.269.28.269.483 0 .203-.09.364-.269.483l-4.188 2.667z"
      fill="#D60000"
    />
  </svg>
);

export const TrendingOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      d="M25.412 6.565A13.327 13.327 0 0 0 2.866 13.7a13.333 13.333 0 0 0 1.794 9.313.393.393 0 0 0 .575.102l1.139-.876a.438.438 0 0 0 .038-.034l5.733-4.345a.442.442 0 0 1 .559.029l2.287 2.083a.442.442 0 0 0 .604-.008l6.477-6.276a.442.442 0 0 1 .709.132l.481 1.04c.151.328.645.258.687-.099v-.008l.447-4.495a.442.442 0 0 0-.483-.483l-4.494.447h-.008c-.357.042-.431.532-.11.69l1.066.523a.443.443 0 0 1 .11.714l-4.93 4.785a.442.442 0 0 1-.604.008l-2.095-1.907a.442.442 0 0 0-.56-.03l-6.65 5.022a11.07 11.07 0 0 1-.757-3.995C4.861 9.916 9.841 4.9 15.953 4.878 22.1 4.855 27.108 9.85 27.108 15.993c0 6.13-4.985 11.116-11.11 11.116a11.076 11.076 0 0 1-7.726-3.132.395.395 0 0 0-.517-.03l-1.142.885a.391.391 0 0 0-.04.584l.003.004a13.323 13.323 0 0 0 22.757-9.427 13.33 13.33 0 0 0-3.918-9.426l-.003-.002z"
      fill="#D60000"
    />
  </svg>
);

export const VideoOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      d="M14.5607 19.9999L19.7607 16.6666C20.0095 16.5066 20.134 16.2844 20.134 15.9999C20.134 15.7155 20.0095 15.4933 19.7607 15.3333L14.5607 11.9999C14.294 11.8221 14.0229 11.8088 13.7473 11.9599C13.4718 12.111 13.334 12.3466 13.334 12.6666V19.3333C13.334 19.6533 13.4718 19.8888 13.7473 20.0399C14.0229 20.191 14.294 20.1777 14.5607 19.9999ZM16.0007 26.6666C14.5251 26.6666 13.1384 26.3866 11.8407 25.8266C10.5429 25.2666 9.41398 24.5066 8.45398 23.5466C7.49398 22.5866 6.73398 21.4577 6.17398 20.1599C5.61398 18.8621 5.33398 17.4755 5.33398 15.9999C5.33398 14.5244 5.61398 13.1377 6.17398 11.8399C6.73398 10.5421 7.49398 9.41325 8.45398 8.45325C9.41398 7.49325 10.5429 6.73325 11.8407 6.17325C13.1384 5.61325 14.5251 5.33325 16.0007 5.33325C17.4762 5.33325 18.8629 5.61325 20.1607 6.17325C21.4584 6.73325 22.5873 7.49325 23.5473 8.45325C24.5073 9.41325 25.2673 10.5421 25.8273 11.8399C26.3873 13.1377 26.6673 14.5244 26.6673 15.9999C26.6673 17.4755 26.3873 18.8621 25.8273 20.1599C25.2673 21.4577 24.5073 22.5866 23.5473 23.5466C22.5873 24.5066 21.4584 25.2666 20.1607 25.8266C18.8629 26.3866 17.4762 26.6666 16.0007 26.6666ZM16.0007 24.5333C18.3829 24.5333 20.4007 23.7066 22.054 22.0533C23.7073 20.3999 24.534 18.3821 24.534 15.9999C24.534 13.6177 23.7073 11.5999 22.054 9.94659C20.4007 8.29325 18.3829 7.46659 16.0007 7.46659C13.6184 7.46659 11.6007 8.29325 9.94732 9.94659C8.29398 11.5999 7.46732 13.6177 7.46732 15.9999C7.46732 18.3821 8.29398 20.3999 9.94732 22.0533C11.6007 23.7066 13.6184 24.5333 16.0007 24.5333Z"
      fill="#D60000"
    />
  </svg>
);

export const VisualStoryOutline = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      d="M10.667 26.667a1.29 1.29 0 0 1-.95-.384 1.29 1.29 0 0 1-.384-.95V6.667c0-.378.128-.695.384-.95a1.29 1.29 0 0 1 .95-.384h10.666c.378 0 .695.128.95.384.256.255.384.572.384.95v18.666c0 .378-.128.695-.384.95a1.29 1.29 0 0 1-.95.384H10.667zm-6.667-4V9.3c0-.378.128-.689.383-.933.256-.245.573-.367.95-.367.378 0 .695.128.95.383.256.256.384.572.384.95V22.7c0 .378-.128.689-.384.933a1.321 1.321 0 0 1-.95.367 1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.383-.95zm21.333 0V9.3c0-.378.128-.689.384-.933a1.32 1.32 0 0 1 .95-.367c.377 0 .694.128.95.383.255.256.383.572.383.95V22.7c0 .378-.128.689-.383.933a1.321 1.321 0 0 1-.95.367 1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.384-.95zM12 24h8V8h-8v16z"
      fill="#D60000"
    />
  </svg>
);

export const LiveIcon = ({ ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 26.667c5.89 0 10.666-4.776 10.666-10.667S21.89 5.333 15.999 5.333C10.11 5.333 5.333 10.11 5.333 16s4.775 10.667 10.666 10.667zm0 2.666c7.363 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.334-13.333C8.636 2.667 2.666 8.637 2.666 16c0 7.364 5.97 13.333 13.333 13.333z"
      fill="#D60000"
    />
    <path d="M24 16a8 8 0 1 1-16 0 8 8 0 0 1 16 0z" fill="#D60000" />
  </svg>
);
export const YoutubeSubscription = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 87 24"
    enableBackground="new 0 0 87 24"
    xmlSpace="preserve"
  >
    <path fill="#E42524" d="M0,2c0-1.105,0.895-2,2-2h85v24H2c-1.105,0-2-0.895-2-2V2z" />
    <path
      fill="#FFFFFF"
      d="M27.582,7.2c-0.23-0.866-0.908-1.548-1.768-1.78C24.254,5,18,5,18,5s-6.254,0-7.814,0.421
	C9.326,5.652,8.648,6.334,8.418,7.2C8,8.77,8,12.045,8,12.045s0,3.275,0.418,4.845c0.23,0.866,0.908,1.52,1.768,1.751
	C11.746,19.062,18,19.062,18,19.062s6.254,0,7.814-0.421c0.861-0.231,1.538-0.885,1.768-1.751C28,15.321,28,12.045,28,12.045
	S28,8.77,27.582,7.2z M15.955,15.019V9.072l5.227,2.974L15.955,15.019z"
    />
    <path
      fill="#FFFFFF"
      d="M35.383,7.469l2.215,4.283l2.221-4.283h1.283l-2.941,5.344V16h-1.131v-3.188l-2.941-5.344H35.383z"
    />
    <path
      fill="#FFFFFF"
      d="M41.359,12.9v-0.135c0-0.457,0.066-0.881,0.199-1.272c0.133-0.394,0.324-0.736,0.574-1.025
	c0.25-0.293,0.553-0.52,0.908-0.68c0.355-0.164,0.754-0.246,1.195-0.246c0.445,0,0.846,0.082,1.201,0.246
	c0.359,0.16,0.664,0.387,0.914,0.68c0.254,0.289,0.447,0.631,0.58,1.025c0.133,0.391,0.199,0.815,0.199,1.272V12.9
	c0,0.457-0.066,0.881-0.199,1.271c-0.133,0.391-0.326,0.732-0.58,1.025c-0.25,0.289-0.553,0.516-0.908,0.68
	c-0.352,0.16-0.75,0.24-1.195,0.24c-0.445,0-0.846-0.08-1.201-0.24c-0.356-0.164-0.66-0.391-0.914-0.68
	c-0.25-0.293-0.441-0.635-0.574-1.025C41.426,13.781,41.359,13.357,41.359,12.9z M42.443,12.766V12.9
	c0,0.316,0.037,0.615,0.111,0.896c0.074,0.277,0.186,0.523,0.334,0.738c0.152,0.215,0.342,0.385,0.568,0.51
	c0.227,0.121,0.49,0.182,0.791,0.182c0.297,0,0.557-0.061,0.779-0.182c0.227-0.125,0.414-0.295,0.562-0.51
	c0.148-0.215,0.26-0.461,0.334-0.738c0.078-0.281,0.117-0.58,0.117-0.896v-0.135c0-0.312-0.039-0.607-0.117-0.885
	c-0.074-0.281-0.188-0.529-0.34-0.744c-0.148-0.219-0.336-0.391-0.562-0.516c-0.223-0.125-0.484-0.188-0.785-0.188
	c-0.297,0-0.559,0.062-0.785,0.188c-0.223,0.125-0.41,0.297-0.562,0.516c-0.148,0.215-0.26,0.463-0.334,0.744
	C42.48,12.158,42.443,12.453,42.443,12.766z"
    />
    <path
      fill="#FFFFFF"
      d="M52.369,14.535V9.66h1.09V16h-1.037L52.369,14.535z M52.574,13.199l0.451-0.012
	c0,0.422-0.045,0.812-0.135,1.172c-0.086,0.355-0.226,0.664-0.422,0.926c-0.195,0.262-0.451,0.467-0.768,0.615
	c-0.316,0.145-0.701,0.217-1.154,0.217c-0.309,0-0.592-0.045-0.85-0.135c-0.254-0.09-0.473-0.229-0.656-0.416
	s-0.326-0.432-0.428-0.732c-0.098-0.301-0.146-0.662-0.146-1.084V9.66h1.084v4.102c0,0.285,0.031,0.522,0.094,0.709
	c0.066,0.184,0.154,0.33,0.264,0.439c0.113,0.105,0.238,0.18,0.375,0.223c0.141,0.043,0.285,0.064,0.434,0.064
	c0.461,0,0.826-0.088,1.096-0.264c0.27-0.18,0.463-0.42,0.58-0.721C52.514,13.908,52.574,13.57,52.574,13.199z"
    />
    <path fill="#FFFFFF" d="M58.428,7.469V16h-1.113V7.469H58.428z M61.17,7.469v0.926h-6.592V7.469H61.17z" />
    <path
      fill="#FFFFFF"
      d="M65.588,14.535V9.66h1.09V16h-1.037L65.588,14.535z M65.793,13.199l0.451-0.012
	c0,0.422-0.045,0.812-0.135,1.172c-0.086,0.355-0.227,0.664-0.422,0.926c-0.195,0.262-0.451,0.467-0.768,0.615
	c-0.316,0.145-0.701,0.217-1.154,0.217c-0.309,0-0.592-0.045-0.85-0.135c-0.254-0.09-0.473-0.229-0.656-0.416
	s-0.326-0.432-0.428-0.732c-0.098-0.301-0.146-0.662-0.146-1.084V9.66h1.084v4.102c0,0.285,0.031,0.522,0.094,0.709
	c0.066,0.184,0.154,0.33,0.264,0.439c0.113,0.105,0.238,0.18,0.375,0.223c0.141,0.043,0.285,0.064,0.434,0.064
	c0.461,0,0.826-0.088,1.096-0.264c0.27-0.18,0.463-0.42,0.58-0.721C65.732,13.908,65.793,13.57,65.793,13.199z"
    />
    <path
      fill="#FFFFFF"
      d="M68.324,7h1.09v7.769L69.32,16h-0.996V7z M73.697,12.777V12.9c0,0.461-0.055,0.889-0.164,1.283
	c-0.109,0.391-0.27,0.73-0.481,1.019c-0.211,0.289-0.469,0.514-0.773,0.674c-0.305,0.16-0.654,0.24-1.049,0.24
	c-0.402,0-0.756-0.068-1.061-0.205c-0.301-0.141-0.555-0.342-0.762-0.603c-0.207-0.262-0.373-0.578-0.498-0.949
	c-0.121-0.371-0.205-0.789-0.252-1.254v-0.539c0.047-0.469,0.131-0.889,0.252-1.26c0.125-0.371,0.291-0.688,0.498-0.949
	c0.207-0.266,0.461-0.467,0.762-0.603c0.301-0.141,0.65-0.211,1.049-0.211c0.398,0,0.752,0.078,1.06,0.234
	c0.309,0.152,0.566,0.371,0.773,0.656c0.211,0.285,0.371,0.627,0.481,1.025C73.643,11.854,73.697,12.293,73.697,12.777z
	 M72.607,12.9v-0.123c0-0.316-0.029-0.613-0.088-0.891c-0.059-0.281-0.152-0.527-0.281-0.738c-0.129-0.215-0.299-0.383-0.51-0.504
	c-0.211-0.125-0.471-0.188-0.779-0.188c-0.273,0-0.512,0.047-0.715,0.141c-0.199,0.094-0.369,0.221-0.51,0.381
	c-0.141,0.156-0.256,0.336-0.346,0.539c-0.086,0.199-0.15,0.406-0.193,0.621v1.412c0.062,0.273,0.164,0.537,0.305,0.791
	c0.145,0.25,0.336,0.455,0.574,0.615c0.242,0.16,0.541,0.24,0.896,0.24c0.293,0,0.543-0.059,0.75-0.176
	c0.211-0.121,0.381-0.287,0.51-0.498c0.133-0.211,0.23-0.455,0.293-0.732C72.576,13.514,72.607,13.217,72.607,12.9z"
    />
    <path
      fill="#FFFFFF"
      d="M77.699,16.117c-0.441,0-0.842-0.074-1.201-0.223c-0.355-0.152-0.662-0.365-0.92-0.639
	c-0.254-0.274-0.449-0.598-0.586-0.973c-0.137-0.375-0.205-0.785-0.205-1.231v-0.246c0-0.516,0.076-0.975,0.229-1.377
	c0.152-0.406,0.359-0.75,0.621-1.031c0.262-0.281,0.559-0.494,0.891-0.639c0.332-0.145,0.676-0.217,1.031-0.217
	c0.453,0,0.844,0.078,1.172,0.234c0.332,0.156,0.604,0.375,0.814,0.656c0.211,0.277,0.367,0.605,0.469,0.984
	c0.101,0.375,0.152,0.785,0.152,1.23v0.486h-4.734V12.25h3.65v-0.082c-0.016-0.281-0.074-0.555-0.176-0.82
	c-0.098-0.266-0.254-0.484-0.469-0.656c-0.215-0.172-0.508-0.258-0.879-0.258c-0.246,0-0.473,0.053-0.68,0.158
	c-0.207,0.102-0.385,0.254-0.533,0.457C76.197,11.252,76.082,11.5,76,11.793c-0.082,0.293-0.123,0.631-0.123,1.014v0.246
	c0,0.301,0.041,0.584,0.123,0.85c0.086,0.262,0.209,0.492,0.369,0.691c0.164,0.199,0.361,0.355,0.592,0.469
	c0.234,0.113,0.5,0.17,0.797,0.17c0.383,0,0.707-0.078,0.973-0.234c0.266-0.156,0.498-0.365,0.697-0.627l0.656,0.521
	c-0.137,0.207-0.311,0.404-0.521,0.592s-0.471,0.34-0.779,0.457C78.479,16.059,78.117,16.117,77.699,16.117z"
    />
  </svg>
);

export const InstagramOutline = ({ ...ops }) => (
  <svg viewBox="0 0 40 40" {...ops} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="16.167" stroke="#000" strokeOpacity=".12" />
    <path
      d="M20 17.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0-1.666a4.166 4.166 0 1 1 0 8.333 4.166 4.166 0 0 1 0-8.333zm5.417-.21a1.042 1.042 0 0 1-2.084 0 1.042 1.042 0 0 1 2.084 0zM20 13.335c-2.062 0-2.398.005-3.357.048-.654.03-1.092.118-1.499.276-.361.14-.622.308-.9.586-.28.28-.446.54-.586.901-.158.408-.246.845-.276 1.498-.043.92-.049 1.241-.049 3.357 0 2.062.006 2.398.049 3.358.03.653.118 1.091.276 1.497.14.363.308.624.585.9.28.28.541.448.9.587.411.159.85.247 1.5.277.92.043 1.241.048 3.357.048 2.062 0 2.398-.006 3.357-.048.652-.03 1.091-.119 1.498-.276a2.43 2.43 0 0 0 .9-.586c.28-.28.448-.54.587-.9.158-.41.247-.848.277-1.5.043-.92.048-1.24.048-3.357 0-2.062-.006-2.398-.048-3.357-.031-.652-.12-1.091-.277-1.498a2.427 2.427 0 0 0-.586-.9 2.403 2.403 0 0 0-.9-.587c-.408-.158-.846-.246-1.499-.276-.92-.043-1.241-.048-3.357-.048zm0-1.667c2.264 0 2.547.008 3.436.05.886.041 1.491.181 2.022.387a4.06 4.06 0 0 1 1.477.962c.462.463.748.928.96 1.476.206.53.347 1.136.388 2.023.04.889.05 1.171.05 3.435 0 2.264-.008 2.547-.05 3.436-.04.887-.182 1.491-.387 2.023a4.07 4.07 0 0 1-.961 1.476c-.463.463-.93.749-1.477.961-.53.206-1.136.346-2.022.388-.89.04-1.172.05-3.436.05-2.264 0-2.547-.009-3.435-.05-.887-.041-1.491-.182-2.023-.388a4.074 4.074 0 0 1-1.477-.96 4.088 4.088 0 0 1-.96-1.477c-.207-.532-.347-1.136-.388-2.023-.04-.89-.05-1.172-.05-3.436 0-2.264.008-2.546.05-3.435.04-.888.181-1.492.387-2.023a4.066 4.066 0 0 1 .961-1.476 4.08 4.08 0 0 1 1.477-.962c.531-.206 1.135-.345 2.023-.387.888-.04 1.171-.05 3.435-.05z"
      fill="#fff"
    />
    <path
      d="M20 17.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0-1.666a4.166 4.166 0 1 1 0 8.333 4.166 4.166 0 0 1 0-8.333zm5.417-.21a1.042 1.042 0 0 1-2.084 0 1.042 1.042 0 0 1 2.084 0zM20 13.335c-2.062 0-2.398.005-3.357.048-.654.03-1.092.118-1.499.276-.361.14-.622.308-.9.586-.28.28-.446.54-.586.901-.158.408-.246.845-.276 1.498-.043.92-.049 1.241-.049 3.357 0 2.062.006 2.398.049 3.358.03.653.118 1.091.276 1.497.14.363.308.624.585.9.28.28.541.448.9.587.411.159.85.247 1.5.277.92.043 1.241.048 3.357.048 2.062 0 2.398-.006 3.357-.048.652-.03 1.091-.119 1.498-.276a2.43 2.43 0 0 0 .9-.586c.28-.28.448-.54.587-.9.158-.41.247-.848.277-1.5.043-.92.048-1.24.048-3.357 0-2.062-.006-2.398-.048-3.357-.031-.652-.12-1.091-.277-1.498a2.427 2.427 0 0 0-.586-.9 2.403 2.403 0 0 0-.9-.587c-.408-.158-.846-.246-1.499-.276-.92-.043-1.241-.048-3.357-.048zm0-1.667c2.264 0 2.547.008 3.436.05.886.041 1.491.181 2.022.387a4.06 4.06 0 0 1 1.477.962c.462.463.748.928.96 1.476.206.53.347 1.136.388 2.023.04.889.05 1.171.05 3.435 0 2.264-.008 2.547-.05 3.436-.04.887-.182 1.491-.387 2.023a4.07 4.07 0 0 1-.961 1.476c-.463.463-.93.749-1.477.961-.53.206-1.136.346-2.022.388-.89.04-1.172.05-3.436.05-2.264 0-2.547-.009-3.435-.05-.887-.041-1.491-.182-2.023-.388a4.074 4.074 0 0 1-1.477-.96 4.088 4.088 0 0 1-.96-1.477c-.207-.532-.347-1.136-.388-2.023-.04-.89-.05-1.172-.05-3.436 0-2.264.008-2.546.05-3.435.04-.888.181-1.492.387-2.023a4.066 4.066 0 0 1 .961-1.476 4.08 4.08 0 0 1 1.477-.962c.531-.206 1.135-.345 2.023-.387.888-.04 1.171-.05 3.435-.05z"
      fill="url(#k3y174jd2a)"
    />
    <path
      d="M20 17.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0-1.666a4.166 4.166 0 1 1 0 8.333 4.166 4.166 0 0 1 0-8.333zm5.417-.21a1.042 1.042 0 0 1-2.084 0 1.042 1.042 0 0 1 2.084 0zM20 13.335c-2.062 0-2.398.005-3.357.048-.654.03-1.092.118-1.499.276-.361.14-.622.308-.9.586-.28.28-.446.54-.586.901-.158.408-.246.845-.276 1.498-.043.92-.049 1.241-.049 3.357 0 2.062.006 2.398.049 3.358.03.653.118 1.091.276 1.497.14.363.308.624.585.9.28.28.541.448.9.587.411.159.85.247 1.5.277.92.043 1.241.048 3.357.048 2.062 0 2.398-.006 3.357-.048.652-.03 1.091-.119 1.498-.276a2.43 2.43 0 0 0 .9-.586c.28-.28.448-.54.587-.9.158-.41.247-.848.277-1.5.043-.92.048-1.24.048-3.357 0-2.062-.006-2.398-.048-3.357-.031-.652-.12-1.091-.277-1.498a2.427 2.427 0 0 0-.586-.9 2.403 2.403 0 0 0-.9-.587c-.408-.158-.846-.246-1.499-.276-.92-.043-1.241-.048-3.357-.048zm0-1.667c2.264 0 2.547.008 3.436.05.886.041 1.491.181 2.022.387a4.06 4.06 0 0 1 1.477.962c.462.463.748.928.96 1.476.206.53.347 1.136.388 2.023.04.889.05 1.171.05 3.435 0 2.264-.008 2.547-.05 3.436-.04.887-.182 1.491-.387 2.023a4.07 4.07 0 0 1-.961 1.476c-.463.463-.93.749-1.477.961-.53.206-1.136.346-2.022.388-.89.04-1.172.05-3.436.05-2.264 0-2.547-.009-3.435-.05-.887-.041-1.491-.182-2.023-.388a4.074 4.074 0 0 1-1.477-.96 4.088 4.088 0 0 1-.96-1.477c-.207-.532-.347-1.136-.388-2.023-.04-.89-.05-1.172-.05-3.436 0-2.264.008-2.546.05-3.435.04-.888.181-1.492.387-2.023a4.066 4.066 0 0 1 .961-1.476 4.08 4.08 0 0 1 1.477-.962c.531-.206 1.135-.345 2.023-.387.888-.04 1.171-.05 3.435-.05z"
      fill="url(#a6f9dxtyeb)"
    />
    <defs>
      <radialGradient
        id="k3y174jd2a"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -16.5179 15.363 0 16.093 29.617)"
      >
        <stop stopColor="#FD5" />
        <stop offset=".1" stopColor="#FD5" />
        <stop offset=".5" stopColor="#FF543E" />
        <stop offset="1" stopColor="#C837AB" />
      </radialGradient>
      <radialGradient
        id="a6f9dxtyeb"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(75.203 2.35 12.88) scale(19.8615)"
      >
        <stop stopColor="#3771C8" />
        <stop offset=".146" stopColor="#3771C8" />
        <stop offset="1" stopColor="#60F" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const InstagramFill = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    {...ops}
    xmlSpace="preserve"
  >
    <radialGradient
      id="SVGID_1_"
      cx="-131.0306"
      cy="197.0595"
      r="1"
      gradientTransform="matrix(1.213717e-15 -19.8215 -18.4356 -1.128855e-15 3640.2224 -2573.6829)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#FFDD55" }} />
      <stop offset="0.1" style={{ stopColor: "#FFDD55" }} />
      <stop offset="0.5" style={{ stopColor: "#FF543E" }} />
      <stop offset="1" style={{ stopColor: "#C837AB" }} />
    </radialGradient>
    <path
      fill="url(#SVGID_1_)"
      d="M12,22c5.523,0,10-4.477,10-10c0-5.523-4.477-10-10-10C6.477,2,2,6.477,2,12C2,17.523,6.477,22,12,22z"
    />
    <radialGradient
      id="SVGID_00000045621147425384861340000010489493058111978938_"
      cx="-99.4879"
      cy="205.9906"
      r="1"
      gradientTransform="matrix(1.7391 8.6879 35.812 -7.1685 -7205.2686 2344.4299)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#3771C8" }} />
      <stop offset="0.128" style={{ stopColor: "#3771C8" }} />
      <stop offset="1" style={{ stopColor: "#6600FF", stopOpacity: 0 }} />
    </radialGradient>
    <path
      fill="url(#SVGID_00000045621147425384861340000010489493058111978938_)"
      d="M12,22c5.523,0,10-4.477,10-10
c0-5.523-4.477-10-10-10C6.477,2,2,6.477,2,12C2,17.523,6.477,22,12,22z"
    />
    <path
      fill="#FFFFFF"
      d="M12.001,5.75c-1.698,0-1.911,0.008-2.577,0.037C8.758,5.818,8.304,5.923,7.907,6.078
c-0.411,0.159-0.76,0.373-1.108,0.721C6.452,7.146,6.238,7.495,6.078,7.906C5.923,8.303,5.818,8.758,5.787,9.423
C5.758,10.089,5.75,10.303,5.75,12s0.008,1.91,0.037,2.576c0.031,0.666,0.136,1.119,0.291,1.517c0.16,0.411,0.373,0.76,0.721,1.107
c0.347,0.347,0.696,0.562,1.107,0.721c0.398,0.154,0.852,0.26,1.517,0.291c0.666,0.031,0.879,0.038,2.577,0.038
s1.91-0.007,2.577-0.038c0.666-0.031,1.12-0.136,1.518-0.291c0.411-0.159,0.759-0.374,1.106-0.721
c0.347-0.347,0.561-0.696,0.721-1.107c0.154-0.398,0.259-0.852,0.291-1.517C18.242,13.91,18.25,13.698,18.25,12
s-0.007-1.911-0.038-2.577c-0.031-0.666-0.137-1.119-0.291-1.517c-0.16-0.411-0.374-0.76-0.721-1.107
c-0.347-0.347-0.696-0.561-1.107-0.721c-0.399-0.154-0.853-0.26-1.518-0.291c-0.667-0.031-0.879-0.038-2.577-0.038H12.001z
M11.44,6.876c0.166,0,0.352,0,0.561,0c1.669,0,1.867,0.006,2.526,0.036c0.609,0.028,0.94,0.129,1.161,0.215
c0.292,0.113,0.499,0.249,0.718,0.468c0.219,0.219,0.354,0.427,0.467,0.719c0.086,0.22,0.188,0.551,0.215,1.16
c0.03,0.659,0.036,0.857,0.036,2.525c0,1.668-0.006,1.866-0.036,2.525c-0.028,0.609-0.13,0.94-0.215,1.16
c-0.113,0.292-0.249,0.499-0.467,0.718c-0.219,0.219-0.426,0.354-0.718,0.468c-0.22,0.086-0.551,0.188-1.161,0.216
c-0.659,0.03-0.857,0.036-2.526,0.036c-1.669,0-1.867-0.006-2.526-0.036c-0.609-0.028-0.94-0.13-1.161-0.216
c-0.292-0.113-0.5-0.249-0.719-0.468c-0.219-0.219-0.354-0.427-0.467-0.718c-0.086-0.22-0.188-0.551-0.215-1.16
c-0.03-0.659-0.036-0.857-0.036-2.526c0-1.669,0.006-1.866,0.036-2.525c0.028-0.609,0.129-0.94,0.215-1.161
c0.113-0.292,0.249-0.5,0.467-0.719s0.427-0.354,0.719-0.468C8.534,7.04,8.866,6.938,9.475,6.91c0.576-0.026,0.8-0.034,1.965-0.035
V6.876z M15.337,7.914c-0.414,0-0.75,0.336-0.75,0.75c0,0.414,0.336,0.75,0.75,0.75c0.414,0,0.75-0.336,0.75-0.75
C16.087,8.25,15.751,7.914,15.337,7.914z M12.001,8.791c-1.773,0-3.209,1.437-3.209,3.209s1.437,3.209,3.209,3.209
S15.21,13.773,15.21,12S13.773,8.791,12.001,8.791z M12.001,9.917c1.151,0,2.083,0.933,2.083,2.083s-0.933,2.083-2.083,2.083
c-1.151,0-2.083-0.933-2.083-2.083C9.917,10.849,10.85,9.917,12.001,9.917z"
    />
  </svg>
);

export const ArrowUp = ({ ...ops }) => (
  <svg fill="black" fillOpacity="0.6" strokeWidth="0" viewBox="0 0 512 512" {...ops} xmlns="http://www.w3.org/2000/svg">
    <path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path>
  </svg>
);

export const LogoutCircle = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="#EEEEEE" cx="12" cy="12" r="10" />
    <path
      fillOpacity="0.68"
      d="M8.111,17c-0.306,0-0.567-0.109-0.785-0.326C7.109,16.456,7,16.194,7,15.889V8.111
c0-0.306,0.109-0.567,0.326-0.785C7.544,7.109,7.806,7,8.111,7h3.333c0.158,0,0.289,0.053,0.396,0.16
C11.947,7.266,12,7.398,12,7.556c0,0.157-0.053,0.289-0.16,0.396c-0.106,0.106-0.238,0.16-0.396,0.16H8.111v7.778h3.333
c0.158,0,0.289,0.053,0.396,0.16c0.107,0.107,0.16,0.238,0.16,0.396c0,0.157-0.053,0.289-0.16,0.396
C11.734,16.947,11.602,17,11.444,17H8.111z M14.875,12.556h-3.986c-0.157,0-0.289-0.053-0.396-0.16
c-0.107-0.106-0.16-0.238-0.16-0.396s0.053-0.289,0.16-0.396c0.106-0.107,0.238-0.16,0.396-0.16h3.986l-1.042-1.042
c-0.102-0.102-0.153-0.227-0.153-0.375c0-0.148,0.051-0.278,0.153-0.389c0.102-0.111,0.232-0.169,0.389-0.174
c0.157-0.005,0.292,0.049,0.403,0.16l1.986,1.986c0.111,0.111,0.167,0.241,0.167,0.389s-0.056,0.278-0.167,0.389l-1.986,1.986
c-0.111,0.111-0.243,0.164-0.396,0.16c-0.153-0.005-0.285-0.062-0.396-0.174c-0.102-0.111-0.15-0.243-0.146-0.396
c0.005-0.153,0.058-0.28,0.16-0.382L14.875,12.556z"
    />
  </svg>
);

export const NewsPaper = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fillOpacity="0.68"
      d="M4.058,20.75c-0.497,0-0.923-0.177-1.277-0.531s-0.531-0.78-0.531-1.277V4.49
c0-0.247,0.06-0.396,0.181-0.447c0.121-0.051,0.268,0.011,0.442,0.185l0.802,0.802l1.017-1.027C4.786,3.91,4.885,3.842,4.99,3.798
c0.105-0.044,0.217-0.065,0.337-0.065c0.12,0,0.233,0.019,0.341,0.058c0.108,0.039,0.208,0.105,0.3,0.198L7,5.031l1.033-1.042
C8.124,3.895,8.223,3.83,8.33,3.791c0.107-0.039,0.221-0.058,0.343-0.058c0.122,0,0.235,0.022,0.339,0.065
C9.115,3.842,9.214,3.91,9.308,4.004l1.017,1.027l1.042-1.042c0.094-0.094,0.193-0.16,0.297-0.198
c0.105-0.038,0.217-0.058,0.337-0.058c0.12,0,0.231,0.019,0.335,0.058c0.104,0.038,0.203,0.104,0.296,0.198l1.042,1.042l1.017-1.027
c0.094-0.094,0.193-0.162,0.297-0.206c0.105-0.044,0.217-0.065,0.337-0.065c0.12,0,0.233,0.019,0.341,0.058
c0.108,0.039,0.208,0.105,0.3,0.198L17,5.031l1.033-1.042c0.091-0.093,0.191-0.159,0.297-0.198c0.107-0.039,0.221-0.058,0.343-0.058
c0.122,0,0.235,0.022,0.339,0.065c0.104,0.044,0.203,0.112,0.296,0.206l1.017,1.027l0.802-0.802
c0.174-0.174,0.322-0.236,0.442-0.185c0.12,0.051,0.181,0.2,0.181,0.447v14.452c0,0.497-0.177,0.923-0.531,1.277
s-0.78,0.531-1.277,0.531H4.058z M4.058,19.25h7.192v-6.5h-7.5v6.192c0,0.09,0.029,0.163,0.087,0.221
C3.894,19.221,3.968,19.25,4.058,19.25z M12.75,19.25h7.192c0.09,0,0.163-0.029,0.221-0.087c0.058-0.058,0.087-0.131,0.087-0.221
V16.75h-7.5V19.25z M12.75,15.25h7.5v-2.5h-7.5V15.25z M3.75,11.25h16.5V7.654H3.75V11.25z"
    />
  </svg>
);

export const Globe = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fillOpacity="0.68"
      d="M12,22c-1.367,0-2.658-0.263-3.875-0.788s-2.279-1.242-3.188-2.15s-1.625-1.971-2.15-3.188
S2,13.367,2,12c0-1.383,0.263-2.679,0.787-3.887s1.242-2.267,2.15-3.175s1.971-1.625,3.188-2.15S10.633,2,12,2
c1.383,0,2.679,0.263,3.887,0.787s2.267,1.242,3.175,2.15s1.625,1.967,2.15,3.175S22,10.617,22,12c0,1.367-0.263,2.658-0.788,3.875
s-1.242,2.279-2.15,3.188s-1.967,1.625-3.175,2.15S13.383,22,12,22z M12,19.95c0.433-0.6,0.808-1.225,1.125-1.875
C13.442,17.425,13.7,16.733,13.9,16h-3.8c0.2,0.733,0.458,1.425,0.775,2.075C11.192,18.725,11.567,19.35,12,19.95z M9.4,19.55
C9.1,19,8.837,18.429,8.613,17.838C8.387,17.246,8.2,16.633,8.05,16H5.1c0.483,0.833,1.088,1.558,1.812,2.175
C7.637,18.792,8.467,19.25,9.4,19.55z M14.6,19.55c0.933-0.3,1.762-0.758,2.487-1.375c0.725-0.617,1.329-1.342,1.812-2.175h-2.95
c-0.15,0.633-0.337,1.246-0.562,1.837C15.163,18.429,14.9,19,14.6,19.55z M4.25,14h3.4c-0.05-0.333-0.088-0.663-0.113-0.988
S7.5,12.35,7.5,12s0.012-0.688,0.037-1.012S7.6,10.333,7.65,10h-3.4c-0.083,0.333-0.146,0.663-0.188,0.988S4,11.65,4,12
s0.021,0.688,0.062,1.012S4.167,13.667,4.25,14z M9.65,14h4.7c0.05-0.333,0.087-0.663,0.112-0.988C14.488,12.688,14.5,12.35,14.5,12
s-0.012-0.688-0.038-1.012C14.438,10.663,14.4,10.333,14.35,10h-4.7c-0.05,0.333-0.087,0.663-0.112,0.988
C9.512,11.313,9.5,11.65,9.5,12s0.012,0.688,0.038,1.012C9.562,13.338,9.6,13.667,9.65,14z M16.35,14h3.4
c0.083-0.333,0.146-0.663,0.188-0.988S20,12.35,20,12s-0.021-0.688-0.062-1.012S19.833,10.333,19.75,10h-3.4
c0.05,0.333,0.087,0.663,0.112,0.988S16.5,11.65,16.5,12s-0.013,0.688-0.038,1.012S16.4,13.667,16.35,14z M15.95,8h2.95
c-0.483-0.833-1.087-1.558-1.812-2.175S15.533,4.75,14.6,4.45c0.3,0.55,0.562,1.121,0.787,1.713C15.613,6.754,15.8,7.367,15.95,8z
M10.1,8h3.8c-0.2-0.733-0.458-1.425-0.775-2.075S12.433,4.65,12,4.05c-0.433,0.6-0.808,1.225-1.125,1.875S10.3,7.267,10.1,8z
M5.1,8h2.95C8.2,7.367,8.387,6.754,8.613,6.163C8.837,5.571,9.1,5,9.4,4.45c-0.933,0.3-1.762,0.758-2.487,1.375S5.583,7.167,5.1,8z
"
    />
  </svg>
);

export const Search = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fillOpacity="0.68"
      d="M25.2,27.067L17.733,19.6c-0.667,0.533-1.433,0.956-2.3,1.267c-0.867,0.311-1.789,0.467-2.767,0.467
c-2.422,0-4.472-0.839-6.15-2.517C4.839,17.139,4,15.089,4,12.667c0-2.422,0.839-4.472,2.517-6.15C8.194,4.839,10.244,4,12.667,4
c2.422,0,4.472,0.839,6.15,2.517c1.678,1.678,2.517,3.728,2.517,6.15c0,0.978-0.156,1.9-0.467,2.767
c-0.311,0.867-0.733,1.633-1.267,2.3l7.5,7.5c0.244,0.245,0.367,0.545,0.367,0.9c0,0.356-0.133,0.667-0.4,0.933
c-0.244,0.244-0.556,0.367-0.933,0.367C25.756,27.433,25.444,27.311,25.2,27.067z M12.667,18.667c1.667,0,3.083-0.583,4.25-1.75
c1.167-1.167,1.75-2.583,1.75-4.25c0-1.667-0.583-3.083-1.75-4.25c-1.167-1.167-2.583-1.75-4.25-1.75
c-1.667,0-3.083,0.583-4.25,1.75C7.25,9.583,6.667,11,6.667,12.667c0,1.667,0.583,3.083,1.75,4.25
C9.583,18.083,11,18.667,12.667,18.667z"
    />
  </svg>
);

export const Hamburger = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fillOpacity="0.87"
      d="M4,18c-0.283,0-0.521-0.096-0.713-0.288C3.096,17.521,3,17.283,3,17s0.096-0.521,0.287-0.712
	C3.479,16.096,3.717,16,4,16h16c0.283,0,0.521,0.096,0.712,0.288S21,16.717,21,17s-0.096,0.521-0.288,0.712S20.283,18,20,18H4z
	 M4,13c-0.283,0-0.521-0.096-0.713-0.288C3.096,12.521,3,12.283,3,12s0.096-0.521,0.287-0.712C3.479,11.096,3.717,11,4,11h16
	c0.283,0,0.521,0.096,0.712,0.288S21,11.717,21,12s-0.096,0.521-0.288,0.712S20.283,13,20,13H4z M4,8
	C3.717,8,3.479,7.904,3.287,7.713C3.096,7.521,3,7.283,3,7s0.096-0.521,0.287-0.713C3.479,6.096,3.717,6,4,6h16
	c0.283,0,0.521,0.096,0.712,0.287S21,6.717,21,7s-0.096,0.521-0.288,0.713S20.283,8,20,8H4z"
    />
  </svg>
);

export const Close = ({ ...ops }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...ops}>
    <path
      d="M20.0001 22.3337L11.8334 30.5003C11.5279 30.8059 11.139 30.9587 10.6667 30.9587C10.1945 30.9587 9.80564 30.8059 9.50008 30.5003C9.19453 30.1948 9.04175 29.8059 9.04175 29.3337C9.04175 28.8614 9.19453 28.4725 9.50008 28.167L17.6667 20.0003L9.50008 11.8337C9.19453 11.5281 9.04175 11.1392 9.04175 10.667C9.04175 10.1948 9.19453 9.80588 9.50008 9.50033C9.80564 9.19477 10.1945 9.04199 10.6667 9.04199C11.139 9.04199 11.5279 9.19477 11.8334 9.50033L20.0001 17.667L28.1667 9.50033C28.4723 9.19477 28.8612 9.04199 29.3334 9.04199C29.8056 9.04199 30.1945 9.19477 30.5001 9.50033C30.8056 9.80588 30.9584 10.1948 30.9584 10.667C30.9584 11.1392 30.8056 11.5281 30.5001 11.8337L22.3334 20.0003L30.5001 28.167C30.8056 28.4725 30.9584 28.8614 30.9584 29.3337C30.9584 29.8059 30.8056 30.1948 30.5001 30.5003C30.1945 30.8059 29.8056 30.9587 29.3334 30.9587C28.8612 30.9587 28.4723 30.8059 28.1667 30.5003L20.0001 22.3337Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const FacebookOutline = ({ ...ops }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...ops}>
    <circle cx="15.999" cy="16" r="12.833" stroke="#000" strokeOpacity=".12" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.172 10.505A4 4 0 0 1 18 9.333h2.667v4H18v1.334h2.667l-.667 4h-2V24h-4v-5.333h-2V14.667h2v-1.334a4 4 0 0 1 1.172-2.828zm2.828.162a2.667 2.667 0 0 0-2.667 2.666V16h-2v1.333h2v5.334h1.334v-5.334h2.146L19.146 16h-2.48v-2.667A1.333 1.333 0 0 1 18 12h1.333v-1.333H18z"
      fill="#1877F2"
    />
  </svg>
);

export const FacebookFill = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="#1877F2" cx="12" cy="12" r="10" />
    <path
      fill="#FFFFFF"
      d="M13.25,12.938h1.562l0.625-2.5H13.25v-1.25c0-0.644,0-1.25,1.25-1.25h0.938v-2.1
	c-0.204-0.027-0.973-0.088-1.786-0.088c-1.697,0-2.902,1.036-2.902,2.937v1.75H8.875v2.5h1.875v5.313h2.5V12.938z"
    />
  </svg>
);

export const TwitterOutline = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="none" stroke="#000000" strokeOpacity="0.12" cx="12" cy="12" r="9.5" />
    <path
      fill="#0F1419"
      d="M15.531,7h1.662l-3.63,4.236L17.833,17h-3.343l-2.62-3.495L8.876,17H7.212l3.882-4.531L7,7h3.427  l2.366,3.195L15.531,7z M14.947,15.986h0.92L9.926,7.962H8.937L14.947,15.986z"
    />
  </svg>
);
export const TwitterFill = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="#0F1419" cx="12" cy="12" r="10" />
    <path
      fill="#FFFFFF"
      d="M15.531,7h1.662l-3.63,4.236L17.833,17h-3.343l-2.62-3.495L8.876,17H7.212l3.882-4.531L7,7h3.427  l2.366,3.195L15.531,7z M14.947,15.986h0.92L9.926,7.962H8.937L14.947,15.986z"
    />
  </svg>
);

export const YoutubeFill = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fill="#FF0000"
      d="M12,2c5.523,0,10,4.477,10,10c0,5.523-4.477,10-10,10C6.477,22,2,17.523,2,12C2,6.477,6.477,2,12,2z"
    />
    <path
      fill="#FFFFFF"
      d="M17.964,8.561C18.25,9.675,18.25,12,18.25,12s0,2.325-0.286,3.439c-0.159,0.616-0.623,1.1-1.211,1.264
	C15.685,17,12,17,12,17s-3.683,0-4.753-0.298c-0.591-0.166-1.054-0.65-1.211-1.264C5.75,14.325,5.75,12,5.75,12s0-2.325,0.286-3.439
	c0.159-0.616,0.623-1.1,1.211-1.264C8.317,7,12,7,12,7s3.685,0,4.753,0.298C17.344,7.464,17.808,7.948,17.964,8.561z M10.75,14.188
	L14.5,12l-3.75-2.188V14.188z"
    />
  </svg>
);

export const YoutubeOutline = ({ ...ops }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...ops}>
    <circle cx="15.999" cy="16" r="12.833" stroke="#000" strokeOpacity=".12" />
    <path
      d="M21.004 12.872a.508.508 0 0 0-.337-.37c-.274-.077-1.605-.252-4.417-.252-2.813 0-4.143.175-4.418.252a.508.508 0 0 0-.336.37c-.068.265-.246 1.376-.246 3.128 0 1.753.178 2.863.246 3.129a.507.507 0 0 0 .336.369c.275.077 1.605.252 4.418.252s4.143-.175 4.418-.252a.508.508 0 0 0 .336-.37c.068-.265.246-1.378.246-3.128 0-1.75-.178-2.863-.246-3.128zm1.21-.31C22.5 13.674 22.5 16 22.5 16s0 2.325-.285 3.439a1.756 1.756 0 0 1-1.212 1.263C19.935 21 16.25 21 16.25 21s-3.683 0-4.753-.298a1.756 1.756 0 0 1-1.211-1.263C10 18.325 10 16 10 16s0-2.325.286-3.439c.159-.616.623-1.1 1.211-1.263C12.567 11 16.25 11 16.25 11s3.685 0 4.753.298c.59.166 1.055.65 1.212 1.263zM15 18.187v-4.375L18.75 16 15 18.188z"
      fill="red"
    />
  </svg>
);
export const User = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    {...ops}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <path
      fillOpacity="0.68"
      d="M12,12c0.904,0,1.788-0.264,2.54-0.758c0.752-0.494,1.338-1.197,1.684-2.019
	c0.346-0.822,0.437-1.727,0.26-2.6c-0.176-0.873-0.612-1.675-1.251-2.304c-0.639-0.629-1.454-1.058-2.34-1.232
	c-0.887-0.174-1.806-0.085-2.641,0.256C9.416,3.683,8.702,4.26,8.199,5C7.697,5.74,7.429,6.61,7.429,7.5
	c0,0.591,0.118,1.176,0.348,1.722c0.23,0.546,0.566,1.042,0.991,1.46c0.425,0.418,0.928,0.749,1.483,0.976
	C10.805,11.884,11.4,12,12,12z M15.2,13.125h-0.596c-0.816,0.37-1.705,0.562-2.603,0.562c-0.899,0-1.787-0.192-2.604-0.562H8.8
	c-1.273,0-2.494,0.498-3.394,1.384C4.506,15.395,4,16.597,4,17.85v1.463c0,0.448,0.181,0.877,0.502,1.193
	C4.824,20.822,5.26,21,5.714,21h12.571c0.455,0,0.891-0.178,1.212-0.494C19.819,20.189,20,19.76,20,19.312V17.85
	c0-1.253-0.506-2.455-1.406-3.341C17.694,13.623,16.473,13.125,15.2,13.125z"
    />
  </svg>
);

export const Arrow = ({ ...ops }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...ops}>
    <path
      d="M17.7637 10.5646L12.5646 15.7637C12.477 15.8512 12.3895 15.9125 12.302 15.9475C12.2144 15.9825 12.1182 16 12.0131 16C11.9081 16 11.8118 15.9825 11.7243 15.9475C11.6368 15.9125 11.5492 15.8512 11.4617 15.7637L6.23632 10.5383C6.07877 10.3807 6 10.1926 6 9.97374C6 9.75492 6.07877 9.56674 6.23632 9.40919C6.39387 9.25164 6.58643 9.17724 6.814 9.186C7.04158 9.19475 7.23414 9.2779 7.39168 9.43545L12.0131 14.0569L16.6608 9.40919C16.8184 9.25164 17.0022 9.17287 17.2123 9.17287C17.4223 9.17287 17.6061 9.25164 17.7637 9.40919C17.9212 9.56674 18 9.7593 18 9.98687C18 10.2144 17.9212 10.407 17.7637 10.5646Z"
      fill="black"
    />
  </svg>
);

export const DoubleArrow = ({ ...ops }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...ops}>
    <path
      d="m8.326 12 3.875 3.9c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 5.926 12c0-.133.02-.258.062-.375a.877.877 0 0 1 .213-.325l4.6-4.6a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L8.326 12zm6.6 0 3.875 3.9c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.876.876 0 0 1-.213-.325 1.106 1.106 0 0 1-.062-.375c0-.133.02-.258.062-.375a.876.876 0 0 1 .213-.325l4.6-4.6a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L14.926 12z"
      fill="#fff"
      fillOpacity=".87"
    />
  </svg>
);

export const GoogleNews = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <path
      fill="#0C9D58"
      d="M17.909,15.1c0,0.278-0.23,0.506-0.51,0.506H6.601c-0.281,0-0.51-0.228-0.51-0.506V4.506  C6.091,4.228,6.32,4,6.601,4h10.798c0.281,0,0.51,0.228,0.51,0.506L17.909,15.1L17.909,15.1z"
    />
    <path opacity="0.2" fill="#004D40" enableBackground="new" d="M17.909,6.723l-4.047-0.521l4.047,1.086V6.723z" />
    <path
      opacity="0.2"
      fill="#004D40"
      enableBackground="new    "
      d="M12.195,4.552L6.067,7.289l6.773-2.465V4.817  C12.721,4.561,12.431,4.442,12.195,4.552z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new"
      d="M17.399,4H6.601c-0.281,0-0.51,0.228-0.51,0.506V4.62  c0-0.278,0.23-0.507,0.51-0.507h10.798c0.281,0,0.51,0.228,0.51,0.507V4.506C17.909,4.228,17.68,4,17.399,4z"
    />
    <path
      fill="#EA4335"
      d="M8.711,14.774c-0.073,0.272,0.073,0.55,0.324,0.617l10.001,2.69c0.251,0.067,0.516-0.1,0.589-0.372  l2.356-8.793c0.073-0.272-0.073-0.55-0.324-0.617l-10.001-2.69c-0.251-0.067-0.516,0.1-0.589,0.372L8.711,14.774z"
    />
    <path opacity="0.2" fill="#3E2723" enableBackground="new    " d="M19.727,12.054l0.526,3.196l-0.526,1.964V12.054z" />
    <path opacity="0.2" fill="#3E2723" enableBackground="new" d="M13.909,6.333l0.471,0.127l0.292,1.972L13.909,6.333z" />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new"
      d="M21.657,8.299l-10.001-2.69c-0.251-0.067-0.516,0.1-0.589,0.372  l-2.356,8.793c-0.004,0.015-0.005,0.03-0.008,0.044l2.338-8.726c0.073-0.272,0.338-0.44,0.589-0.372l10.001,2.69  c0.237,0.064,0.378,0.315,0.331,0.573l0.018-0.067C22.054,8.644,21.908,8.366,21.657,8.299z"
    />
    <path
      fill="#FFC107"
      d="M16.588,13.692c0.096,0.265-0.025,0.554-0.269,0.643L5.766,18.175c-0.244,0.089-0.523-0.055-0.619-0.32  L2.033,9.302C1.937,9.037,2.058,8.748,2.302,8.659l10.554-3.841c0.244-0.089,0.523,0.055,0.619,0.32L16.588,13.692z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new"
      d="M2.073,9.409C1.977,9.145,2.098,8.856,2.342,8.767l10.554-3.841  c0.241-0.088,0.516,0.052,0.615,0.311l-0.036-0.1c-0.096-0.265-0.375-0.409-0.619-0.32L2.302,8.659  C2.058,8.748,1.937,9.037,2.033,9.302l3.114,8.554c0.001,0.003,0.003,0.005,0.004,0.008L2.073,9.409z"
    />
    <path
      fill="#4285F4"
      d="M19.727,19.852c0,0.281-0.23,0.511-0.511,0.511H4.784c-0.281,0-0.511-0.23-0.511-0.511V8.943  c0-0.281,0.23-0.511,0.511-0.511h14.432c0.281,0,0.511,0.23,0.511,0.511V19.852z"
    />
    <path
      fill="#FFFFFF"
      d="M16.972,12.75h-4.318v-1.136h4.318c0.125,0,0.227,0.102,0.227,0.227v0.682  C17.199,12.648,17.097,12.75,16.972,12.75z M16.972,17.068h-4.318v-1.136h4.318c0.125,0,0.227,0.102,0.227,0.227v0.682  C17.199,16.966,17.097,17.068,16.972,17.068z M17.653,14.909h-5v-1.136h5c0.125,0,0.227,0.102,0.227,0.227v0.682  C17.881,14.807,17.779,14.909,17.653,14.909z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new"
      d="M19.216,20.25H4.784c-0.281,0-0.511-0.23-0.511-0.511v0.114  c0,0.281,0.23,0.511,0.511,0.511h14.432c0.281,0,0.511-0.23,0.511-0.511v-0.114C19.727,20.02,19.497,20.25,19.216,20.25z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new"
      d="M4.784,8.545h14.432c0.281,0,0.511,0.23,0.511,0.511V8.943  c0-0.281-0.23-0.511-0.511-0.511H4.784c-0.281,0-0.511,0.23-0.511,0.511v0.114C4.273,8.776,4.503,8.545,4.784,8.545z"
    />
    <path
      fill="#FFFFFF"
      d="M8.79,13.886v1.025h1.471c-0.122,0.624-0.67,1.077-1.471,1.077c-0.893,0-1.617-0.755-1.617-1.648  s0.724-1.648,1.617-1.648c0.402,0,0.762,0.138,1.046,0.409v0.001l0.778-0.778c-0.473-0.441-1.089-0.711-1.825-0.711  c-1.506,0-2.727,1.221-2.727,2.727s1.221,2.727,2.727,2.727c1.575,0,2.611-1.107,2.611-2.665c0-0.179-0.016-0.351-0.043-0.517H8.79z  "
    />
    <path fill="#1A237E" d="M8.818,14.911v0.114h1.443c0.011-0.037,0.02-0.075,0.028-0.114H8.818z" />
    <path
      fill="#1A237E"
      d="M8.818,17.068c-1.487,0-2.696-1.19-2.726-2.67c0,0.019-0.002,0.038-0.002,0.057  c0,1.506,1.221,2.727,2.727,2.727c1.575,0,2.611-1.107,2.611-2.665c0-0.013-0.002-0.024-0.002-0.037  C11.394,15.997,10.367,17.068,8.818,17.068z M9.864,13.102c-0.284-0.271-0.644-0.409-1.046-0.409c-0.893,0-1.617,0.755-1.617,1.648  c0,0.019,0.002,0.038,0.003,0.057c0.03-0.868,0.74-1.591,1.614-1.591c0.402,0,0.762,0.138,1.046,0.409v0.001l0.835-0.835  c-0.019-0.018-0.041-0.035-0.061-0.052L9.864,13.102L9.864,13.102z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new"
      d="M17.199,12.636v-0.114c0,0.125-0.102,0.227-0.227,0.227h-4.318  v0.114h4.318C17.097,12.864,17.199,12.762,17.199,12.636z M16.972,17.068h-4.318v0.114h4.318c0.125,0,0.227-0.102,0.227-0.227  v-0.114C17.199,16.966,17.097,17.068,16.972,17.068z M17.653,14.909h-5v0.114h5c0.125,0,0.227-0.102,0.227-0.227v-0.113  C17.881,14.807,17.779,14.909,17.653,14.909z"
    />
    <radialGradient
      id="SVGID_25_"
      cx="-270.3468"
      cy="411.8614"
      r="1"
      gradientTransform="matrix(19.4497 0 0 -19.4497 5264.6719 8015.9062)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: "0.1" }} />
      <stop offset="1" style={{ stopColor: "#FFFFFF", stopOpacity: "0" }} />
    </radialGradient>
    <path
      fill="url(#SVGID_25_)"
      d="M21.657,8.299l-3.748-1.008V4.506c0-0.278-0.23-0.506-0.51-0.506H6.601  c-0.281,0-0.51,0.228-0.51,0.506V7.28L2.302,8.659C2.058,8.748,1.937,9.037,2.033,9.302l2.239,6.153v4.397  c0,0.281,0.23,0.511,0.511,0.511h14.432c0.281,0,0.511-0.23,0.511-0.511v-2.525l2.254-8.412C22.054,8.644,21.908,8.366,21.657,8.299  z"
    />
  </svg>
);

export const Location = ({ ...ops }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...ops}>
    <path
      d="M16 10.667A5.332 5.332 0 0 0 10.668 16a5.332 5.332 0 0 0 5.334 5.333A5.332 5.332 0 0 0 21.334 16a5.332 5.332 0 0 0-5.333-5.333zm11.92 4A11.992 11.992 0 0 0 17.335 4.08V1.333h-2.667V4.08A11.992 11.992 0 0 0 4.081 14.667H1.334v2.666h2.747A11.992 11.992 0 0 0 14.667 27.92v2.747h2.667V27.92a11.992 11.992 0 0 0 10.587-10.587h2.746v-2.666h-2.746zM16 25.333A9.327 9.327 0 0 1 6.668 16a9.327 9.327 0 0 1 9.334-9.333A9.327 9.327 0 0 1 25.334 16a9.327 9.327 0 0 1-9.333 9.333z"
      fill="#D60000"
    />
  </svg>
);

export const InterviewStoryIcon = ({ ...ops }) => (
  <svg viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...ops}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.936 15.834v3.283a1.667 1.667 0 0 1-3.334 0v-4.449c0-1.197.971-2.168 2.169-2.168h24.063c1.197 0 2.168.97 2.168 2.168v16.786c0 1.197-.97 2.168-2.168 2.168h-5.001l-5.074 5.893c-1.196 1.389-3.469.76-3.78-1.047l-.834-4.846h-1.103a1.667 1.667 0 1 1 0-3.334h2.085c1.055 0 1.957.76 2.136 1.8l.582 3.379 3.81-4.425a2.168 2.168 0 0 1 1.643-.754h4.37V15.834H43.936z"
      fill="#7797B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.825 39.232a7.106 7.106 0 1 0 0-14.212 7.106 7.106 0 0 0 0 14.212zm0 3.334c5.766 0 10.44-4.674 10.44-10.44 0-5.765-4.674-10.44-10.44-10.44-5.765 0-10.44 4.675-10.44 10.44 0 5.766 4.675 10.44 10.44 10.44z"
      fill="#7797B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.99 43.406c-7.703 0-13.948 6.245-13.948 13.949a1.667 1.667 0 1 1-3.334 0c0-9.545 7.738-17.283 17.283-17.283 9.545 0 17.282 7.738 17.282 17.283a1.667 1.667 0 0 1-3.333 0c0-7.704-6.245-13.95-13.95-13.95z"
      fill="#7797B7"
    />
    <path
      d="M11.347 27.953a7.106 7.106 0 0 1 12.958-4.032 1.667 1.667 0 0 0 2.744-1.895 10.43 10.43 0 0 0-8.596-4.513c-5.766 0-10.44 4.674-10.44 10.44 0 3.692 1.918 6.938 4.811 8.793C7.014 39.531 3 45.468 3 52.341a1.667 1.667 0 0 0 3.334 0c0-7.703 6.245-13.949 13.949-13.949.733 0 1.356-.474 1.579-1.132a1.667 1.667 0 0 0-1.854-2.372 7.106 7.106 0 0 1-8.661-6.936z"
      fill="#7797B7"
    />
  </svg>
);

export const GoogleNewsFill = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="#EEEEEE" cx="12" cy="12" r="10" />
    <path
      fill="#0C9D58"
      d="M15.939,14.067c0,0.186-0.153,0.338-0.34,0.338H8.401c-0.187,0-0.34-0.152-0.34-0.338V7.004  c0-0.186,0.153-0.338,0.34-0.338h7.199c0.187,0,0.34,0.152,0.34,0.338L15.939,14.067L15.939,14.067z"
    />
    <path opacity="0.2" fill="#004D40" enableBackground="new    " d="M15.939,8.482l-2.698-0.347l2.698,0.724V8.482z" />
    <path
      opacity="0.2"
      fill="#004D40"
      enableBackground="new    "
      d="M12.13,7.035L8.044,8.859l4.515-1.644V7.211  C12.481,7.041,12.287,6.962,12.13,7.035z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M15.599,6.667H8.401c-0.187,0-0.34,0.152-0.34,0.338V7.08  c0-0.186,0.153-0.338,0.34-0.338h7.199c0.187,0,0.34,0.152,0.34,0.338V7.004C15.939,6.819,15.786,6.667,15.599,6.667z"
    />
    <path
      fill="#EA4335"
      d="M9.807,13.85c-0.049,0.181,0.048,0.366,0.216,0.411l6.668,1.793c0.167,0.045,0.344-0.067,0.392-0.248  l1.571-5.862c0.049-0.181-0.048-0.366-0.216-0.411l-6.668-1.793c-0.167-0.045-0.344,0.067-0.392,0.248L9.807,13.85z"
    />
    <path opacity="0.2" fill="#3E2723" enableBackground="new    " d="M17.152,12.036l0.351,2.13l-0.351,1.309V12.036z" />
    <path
      opacity="0.2"
      fill="#3E2723"
      enableBackground="new    "
      d="M13.273,8.222l0.314,0.084l0.195,1.315L13.273,8.222z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M18.438,9.533l-6.667-1.793  c-0.167-0.045-0.344,0.067-0.392,0.248L9.808,13.85c-0.003,0.01-0.003,0.02-0.005,0.03l1.559-5.818  c0.049-0.181,0.225-0.293,0.392-0.248l6.668,1.793c0.158,0.042,0.252,0.21,0.221,0.382l0.012-0.045  C18.703,9.762,18.605,9.577,18.438,9.533z"
    />
    <path
      fill="#FFC107"
      d="M15.059,13.128c0.064,0.177-0.017,0.369-0.179,0.429l-7.036,2.561c-0.163,0.059-0.348-0.037-0.413-0.213  l-2.076-5.703c-0.064-0.177,0.016-0.369,0.179-0.428l7.036-2.561c0.163-0.059,0.349,0.037,0.413,0.213L15.059,13.128z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M5.382,10.273c-0.064-0.176,0.016-0.369,0.179-0.428l7.036-2.561  c0.161-0.059,0.344,0.035,0.41,0.208l-0.024-0.066c-0.064-0.176-0.25-0.272-0.413-0.213L5.535,9.773  c-0.163,0.059-0.243,0.252-0.179,0.428l2.076,5.703c0.001,0.002,0.002,0.003,0.003,0.005L5.382,10.273z"
    />
    <path
      fill="#4285F4"
      d="M17.152,17.235c0,0.188-0.154,0.341-0.341,0.341H7.189c-0.187,0-0.341-0.154-0.341-0.341V9.962  c0-0.187,0.153-0.341,0.341-0.341h9.621c0.188,0,0.341,0.153,0.341,0.341V17.235z"
    />
    <path
      fill="#FFFFFF"
      d="M15.314,12.5h-2.879v-0.758h2.879c0.084,0,0.151,0.068,0.151,0.151v0.455  C15.466,12.432,15.398,12.5,15.314,12.5z M15.314,15.379h-2.879v-0.758h2.879c0.084,0,0.151,0.068,0.151,0.151v0.455  C15.466,15.311,15.398,15.379,15.314,15.379z M15.769,13.939h-3.333v-0.758h3.333c0.084,0,0.151,0.068,0.151,0.151v0.454  C15.92,13.871,15.852,13.939,15.769,13.939z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new    "
      d="M16.81,17.5H7.189c-0.187,0-0.341-0.153-0.341-0.341v0.076  c0,0.188,0.153,0.341,0.341,0.341h9.621c0.188,0,0.341-0.153,0.341-0.341v-0.076C17.152,17.346,16.998,17.5,16.81,17.5z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M7.189,9.697h9.621c0.188,0,0.341,0.153,0.341,0.341V9.962  c0-0.187-0.154-0.341-0.341-0.341H7.189c-0.187,0-0.341,0.153-0.341,0.341v0.076C6.848,9.85,7.002,9.697,7.189,9.697z"
    />
    <path
      fill="#FFFFFF"
      d="M9.86,13.258v0.683h0.98c-0.081,0.416-0.447,0.718-0.98,0.718c-0.595,0-1.078-0.503-1.078-1.099  c0-0.595,0.483-1.099,1.078-1.099c0.268,0,0.508,0.092,0.697,0.273v0l0.519-0.519c-0.315-0.294-0.726-0.474-1.216-0.474  c-1.004,0-1.818,0.814-1.818,1.818c0,1.004,0.814,1.818,1.818,1.818c1.05,0,1.741-0.738,1.741-1.777  c0-0.119-0.011-0.234-0.029-0.344H9.86z"
    />
    <path fill="#1A237E" d="M9.879,13.941v0.076h0.962c0.007-0.025,0.013-0.05,0.018-0.076H9.879z" />
    <path
      fill="#1A237E"
      d="M9.879,15.379c-0.991,0-1.797-0.794-1.817-1.78c0,0.013-0.001,0.025-0.001,0.038  c0,1.004,0.814,1.818,1.818,1.818c1.05,0,1.741-0.738,1.741-1.777c0-0.008-0.001-0.016-0.001-0.024  C11.596,14.665,10.911,15.379,9.879,15.379z M10.576,12.735c-0.189-0.18-0.43-0.273-0.698-0.273c-0.595,0-1.078,0.503-1.078,1.099  c0,0.013,0.001,0.025,0.002,0.038c0.02-0.578,0.493-1.061,1.076-1.061c0.268,0,0.508,0.092,0.698,0.273v0l0.557-0.557  c-0.013-0.012-0.027-0.023-0.041-0.035L10.576,12.735L10.576,12.735z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new    "
      d="M15.466,12.424v-0.076c0,0.084-0.068,0.151-0.151,0.151h-2.879  v0.076h2.879C15.398,12.576,15.466,12.508,15.466,12.424z M15.314,15.379h-2.879v0.076h2.879c0.084,0,0.151-0.068,0.151-0.151  v-0.076C15.466,15.311,15.398,15.379,15.314,15.379z M15.769,13.939h-3.333v0.076h3.333c0.084,0,0.151-0.068,0.151-0.152v-0.076  C15.92,13.871,15.852,13.939,15.769,13.939z"
    />
    <radialGradient
      id="SVGID_1_I"
      cx="-263.0203"
      cy="401.3472"
      r="1"
      gradientTransform="matrix(12.9665 0 0 -12.9665 3418.791 5211.6187)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: "0.1" }} />
      <stop offset="1" style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
    </radialGradient>
    <path
      fill="url(#SVGID_1_I)"
      d="M18.438,9.533L15.94,8.861V7.004c0-0.186-0.153-0.338-0.34-0.338H8.401  c-0.187,0-0.34,0.152-0.34,0.338v1.849L5.535,9.773c-0.163,0.059-0.243,0.252-0.179,0.428l1.493,4.102v2.932  c0,0.187,0.153,0.341,0.341,0.341h9.621c0.188,0,0.341-0.154,0.341-0.341v-1.683l1.503-5.608  C18.703,9.762,18.606,9.577,18.438,9.533z"
    />
  </svg>
);

export const GoogleNewsOutLine = ({ ...ops }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    {...ops}
  >
    <circle fill="none" stroke="#000000" strokeOpacity="0.12" cx="12" cy="12" r="9.5" />
    <path
      fill="#0C9D58"
      d="M15.939,14.067c0,0.186-0.153,0.338-0.34,0.338H8.401c-0.187,0-0.34-0.152-0.34-0.338V7.004  c0-0.186,0.153-0.338,0.34-0.338h7.199c0.187,0,0.34,0.152,0.34,0.338L15.939,14.067L15.939,14.067z"
    />
    <path opacity="0.2" fill="#004D40" enableBackground="new    " d="M15.939,8.482l-2.698-0.347l2.698,0.724V8.482z" />
    <path
      opacity="0.2"
      fill="#004D40"
      enableBackground="new    "
      d="M12.13,7.035L8.044,8.859l4.515-1.644V7.211  C12.481,7.041,12.287,6.962,12.13,7.035z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M15.599,6.667H8.401c-0.187,0-0.34,0.152-0.34,0.338V7.08  c0-0.186,0.153-0.338,0.34-0.338h7.199c0.187,0,0.34,0.152,0.34,0.338V7.004C15.939,6.819,15.786,6.667,15.599,6.667z"
    />
    <path
      fill="#EA4335"
      d="M9.807,13.85c-0.049,0.181,0.048,0.366,0.216,0.411l6.668,1.793c0.167,0.045,0.344-0.067,0.392-0.248  l1.571-5.862c0.049-0.181-0.048-0.366-0.216-0.411l-6.668-1.793c-0.167-0.045-0.344,0.067-0.392,0.248L9.807,13.85z"
    />
    <path opacity="0.2" fill="#3E2723" enableBackground="new    " d="M17.152,12.036l0.351,2.13l-0.351,1.309V12.036z" />
    <path
      opacity="0.2"
      fill="#3E2723"
      enableBackground="new    "
      d="M13.273,8.222l0.314,0.084l0.195,1.315L13.273,8.222z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M18.438,9.533l-6.667-1.793  c-0.167-0.045-0.344,0.067-0.392,0.248L9.808,13.85c-0.003,0.01-0.003,0.02-0.005,0.03l1.559-5.818  c0.049-0.181,0.225-0.293,0.392-0.248l6.668,1.793c0.158,0.042,0.252,0.21,0.221,0.382l0.012-0.045  C18.703,9.762,18.605,9.577,18.438,9.533z"
    />
    <path
      fill="#FFC107"
      d="M15.059,13.128c0.064,0.177-0.017,0.369-0.179,0.429l-7.036,2.561c-0.163,0.059-0.348-0.037-0.413-0.213  l-2.076-5.703c-0.064-0.177,0.016-0.369,0.179-0.428l7.036-2.561c0.163-0.059,0.349,0.037,0.413,0.213L15.059,13.128z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M5.382,10.273c-0.064-0.176,0.016-0.369,0.179-0.428l7.036-2.561  c0.161-0.059,0.344,0.035,0.41,0.208l-0.024-0.066c-0.064-0.176-0.25-0.272-0.413-0.213L5.535,9.773  c-0.163,0.059-0.243,0.252-0.179,0.428l2.076,5.703c0.001,0.002,0.002,0.003,0.003,0.005L5.382,10.273z"
    />
    <path
      fill="#4285F4"
      d="M17.152,17.235c0,0.188-0.154,0.341-0.341,0.341H7.189c-0.187,0-0.341-0.154-0.341-0.341V9.962  c0-0.187,0.153-0.341,0.341-0.341h9.621c0.188,0,0.341,0.153,0.341,0.341V17.235z"
    />
    <path
      fill="#FFFFFF"
      d="M15.314,12.5h-2.879v-0.758h2.879c0.084,0,0.151,0.068,0.151,0.151v0.455  C15.466,12.432,15.398,12.5,15.314,12.5z M15.314,15.379h-2.879v-0.758h2.879c0.084,0,0.151,0.068,0.151,0.151v0.455  C15.466,15.311,15.398,15.379,15.314,15.379z M15.769,13.939h-3.333v-0.758h3.333c0.084,0,0.151,0.068,0.151,0.151v0.454  C15.92,13.871,15.852,13.939,15.769,13.939z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new    "
      d="M16.81,17.5H7.189c-0.187,0-0.341-0.153-0.341-0.341v0.076  c0,0.188,0.153,0.341,0.341,0.341h9.621c0.188,0,0.341-0.153,0.341-0.341v-0.076C17.152,17.346,16.998,17.5,16.81,17.5z"
    />
    <path
      opacity="0.2"
      fill="#FFFFFF"
      enableBackground="new    "
      d="M7.189,9.697h9.621c0.188,0,0.341,0.153,0.341,0.341V9.962  c0-0.187-0.154-0.341-0.341-0.341H7.189c-0.187,0-0.341,0.153-0.341,0.341v0.076C6.848,9.85,7.002,9.697,7.189,9.697z"
    />
    <path
      fill="#FFFFFF"
      d="M9.86,13.258v0.683h0.98c-0.081,0.416-0.447,0.718-0.98,0.718c-0.595,0-1.078-0.503-1.078-1.099  c0-0.595,0.483-1.099,1.078-1.099c0.268,0,0.508,0.092,0.697,0.273v0l0.519-0.519c-0.315-0.294-0.726-0.474-1.216-0.474  c-1.004,0-1.818,0.814-1.818,1.818c0,1.004,0.814,1.818,1.818,1.818c1.05,0,1.741-0.738,1.741-1.777  c0-0.119-0.011-0.234-0.029-0.344H9.86z"
    />
    <path fill="#1A237E" d="M9.879,13.941v0.076h0.962c0.007-0.025,0.013-0.05,0.018-0.076H9.879z" />
    <path
      fill="#1A237E"
      d="M9.879,15.379c-0.991,0-1.797-0.794-1.817-1.78c0,0.013-0.001,0.025-0.001,0.038  c0,1.004,0.814,1.818,1.818,1.818c1.05,0,1.741-0.738,1.741-1.777c0-0.008-0.001-0.016-0.001-0.024  C11.596,14.665,10.911,15.379,9.879,15.379z M10.576,12.735c-0.189-0.18-0.43-0.273-0.698-0.273c-0.595,0-1.078,0.503-1.078,1.099  c0,0.013,0.001,0.025,0.002,0.038c0.02-0.578,0.493-1.061,1.076-1.061c0.268,0,0.508,0.092,0.698,0.273v0l0.557-0.557  c-0.013-0.012-0.027-0.023-0.041-0.035L10.576,12.735L10.576,12.735z"
    />
    <path
      opacity="0.2"
      fill="#1A237E"
      enableBackground="new    "
      d="M15.466,12.424v-0.076c0,0.084-0.068,0.151-0.151,0.151h-2.879  v0.076h2.879C15.398,12.576,15.466,12.508,15.466,12.424z M15.314,15.379h-2.879v0.076h2.879c0.084,0,0.151-0.068,0.151-0.151  v-0.076C15.466,15.311,15.398,15.379,15.314,15.379z M15.769,13.939h-3.333v0.076h3.333c0.084,0,0.151-0.068,0.151-0.152v-0.076  C15.92,13.871,15.852,13.939,15.769,13.939z"
    />
    <radialGradient
      id="SVGID_1-G"
      cx="-263.0203"
      cy="401.3472"
      r="1"
      gradientTransform="matrix(12.9665 0 0 -12.9665 3418.791 5211.6187)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: "0.1" }} />
      <stop offset="1" style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
    </radialGradient>
    <path
      fill="url(#SVGID_1_G)"
      d="M18.438,9.533L15.94,8.861V7.004c0-0.186-0.153-0.338-0.34-0.338H8.401  c-0.187,0-0.34,0.152-0.34,0.338v1.849L5.535,9.773c-0.163,0.059-0.243,0.252-0.179,0.428l1.493,4.102v2.932  c0,0.187,0.153,0.341,0.341,0.341h9.621c0.188,0,0.341-0.154,0.341-0.341v-1.683l1.503-5.608  C18.703,9.762,18.606,9.577,18.438,9.533z"
    />
  </svg>
);

export const PlayStore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 40"
    enableBackground="new 0 0 128 40"
    xmlSpace="preserve"
    width="128"
    height="40"
  >
    <g>
      <defs>
        <rect id="SVGID_1_PS" y="0" width="128" height="40" />
      </defs>
      <clipPath id="SVGID_00000005951966355763680580000013145984348499725447_">
        <use xlinkHref="#SVGID_1_PS" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_00000005951966355763680580000013145984348499725447_)">
        <path
          fill="#FFFFFF"
          d="M123.485,0H4.515C2.021,0,0,2.052,0,4.582v30.836C0,37.949,2.021,40,4.515,40h118.97
			c2.494,0,4.515-2.051,4.515-4.582V4.582C128,2.052,125.979,0,123.485,0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity="0.12"
          d="M123.485,1H4.515C2.587,1,1,2.59,1,4.582v30.836
			C1,37.41,2.587,39,4.515,39h118.97c1.928,0,3.515-1.59,3.515-3.582V4.582C127,2.59,125.413,1,123.485,1z M4.515,0h118.97
			C125.979,0,128,2.052,128,4.582v30.836c0,2.531-2.021,4.582-4.515,4.582H4.515C2.021,40,0,37.949,0,35.418V4.582
			C0,2.052,2.021,0,4.515,0z"
        />
        <path
          fill="#333333"
          d="M50.589,13.402c0,0.588-0.174,1.056-0.523,1.405c-0.396,0.415-0.911,0.623-1.546,0.623
			c-0.289,0.003-0.576-0.053-0.844-0.163c-0.268-0.11-0.51-0.272-0.714-0.478c-0.204-0.205-0.365-0.449-0.472-0.718
			c-0.108-0.268-0.161-0.556-0.156-0.845c-0.005-0.289,0.049-0.576,0.157-0.844c0.108-0.268,0.269-0.512,0.472-0.717
			c0.204-0.205,0.446-0.368,0.714-0.478c0.267-0.11,0.554-0.165,0.843-0.163c0.297-0.001,0.591,0.059,0.864,0.177
			c0.253,0.103,0.479,0.264,0.658,0.47l-0.371,0.378c-0.278-0.333-0.663-0.5-1.153-0.5c-0.213-0.002-0.425,0.038-0.622,0.118
			c-0.197,0.08-0.377,0.199-0.528,0.349c-0.322,0.312-0.484,0.717-0.484,1.215c-0.001,0.498,0.161,0.903,0.484,1.215
			c0.312,0.301,0.729,0.469,1.163,0.469c0.434,0,0.85-0.168,1.163-0.469c0.204-0.204,0.322-0.488,0.353-0.853h-1.528v-0.506h2.04
			C50.578,13.191,50.588,13.296,50.589,13.402z"
        />
        <path
          fill="#333333"
          d="M53.823,11.643h-1.917v1.335h1.729v0.506h-1.729v1.335h1.917v0.517h-2.458v-4.21h2.458V11.643z"
        />
        <path fill="#333333" d="M56.104,15.335h-0.541v-3.692h-1.176v-0.518h2.893v0.518h-1.177V15.335z" />
        <path fill="#333333" d="M59.373,15.335v-4.21h0.541v4.21H59.373z" />
        <path fill="#333333" d="M62.313,15.335h-0.542v-3.692h-1.176v-0.518h2.893v0.518h-1.176V15.335z" />
        <path
          fill="#333333"
          d="M68.963,14.791c-0.41,0.408-0.965,0.638-1.543,0.638s-1.134-0.229-1.543-0.638
			c-0.398-0.422-0.62-0.98-0.62-1.561c0-0.58,0.222-1.139,0.62-1.561c0.202-0.203,0.443-0.364,0.707-0.474
			c0.264-0.11,0.548-0.166,0.835-0.166c0.286,0,0.57,0.057,0.835,0.166c0.265,0.11,0.505,0.271,0.707,0.474
			c0.399,0.421,0.621,0.979,0.621,1.56c0,0.58-0.222,1.138-0.621,1.56L68.963,14.791z M66.275,14.439
			c0.304,0.303,0.715,0.473,1.144,0.473c0.429,0,0.84-0.17,1.144-0.473c0.311-0.315,0.467-0.718,0.468-1.208
			c0-0.49-0.155-0.893-0.468-1.208c-0.304-0.303-0.715-0.473-1.144-0.473c-0.429,0-0.84,0.17-1.144,0.473
			c-0.311,0.315-0.466,0.718-0.467,1.208c0,0.491,0.156,0.893,0.47,1.208H66.275z"
        />
        <path
          fill="#333333"
          d="M70.345,15.335v-4.21h0.659L73.05,14.4h0.023l-0.023-0.811v-2.464h0.542v4.21h-0.565l-2.14-3.433h-0.024
			l0.023,0.811v2.622H70.345z"
        />
        <path
          fill="#333333"
          d="M65.125,21.476c-0.794-0.006-1.558,0.301-2.127,0.856c-0.284,0.29-0.507,0.634-0.656,1.012
			c-0.149,0.378-0.22,0.781-0.21,1.187c0.01,0.406,0.101,0.805,0.268,1.175c0.167,0.37,0.406,0.703,0.704,0.979
			c0.544,0.475,1.237,0.744,1.959,0.76c0.722,0.015,1.426-0.224,1.99-0.676c0.339-0.28,0.612-0.633,0.797-1.032
			c0.185-0.399,0.278-0.835,0.273-1.275c0.006-0.395-0.067-0.786-0.216-1.152c-0.148-0.366-0.369-0.698-0.649-0.976
			c-0.28-0.279-0.612-0.498-0.978-0.645c-0.366-0.147-0.758-0.219-1.153-0.212H65.125z M65.125,26.269
			c-0.904,0-1.684-0.746-1.684-1.808c0-1.062,0.78-1.809,1.684-1.809s1.685,0.735,1.685,1.809
			C66.81,25.535,66.03,26.269,65.125,26.269z M58.593,21.476c-0.593-0.003-1.173,0.167-1.671,0.488
			c-0.367,0.25-0.675,0.578-0.901,0.96c-0.226,0.382-0.365,0.81-0.408,1.252c-0.042,0.442,0.013,0.888,0.163,1.307
			c0.15,0.418,0.389,0.799,0.702,1.114c0.523,0.497,1.204,0.795,1.924,0.841c0.72,0.046,1.433-0.162,2.015-0.589
			c0.37-0.278,0.669-0.639,0.873-1.054c0.204-0.415,0.307-0.872,0.301-1.335c0.005-0.539-0.135-1.069-0.405-1.536
			c-0.267-0.448-0.647-0.819-1.103-1.073c-0.456-0.254-0.97-0.384-1.492-0.376H58.593z M58.593,26.269
			c-0.904,0-1.684-0.746-1.684-1.808c0-1.062,0.78-1.809,1.684-1.809c0.904,0,1.685,0.735,1.685,1.809
			c0,1.074-0.783,1.808-1.688,1.808H58.593z M50.817,22.392v1.266h3.032c-0.09,0.712-0.327,1.232-0.69,1.595
			c-0.44,0.441-1.13,0.927-2.34,0.927c-1.865,0-3.323-1.504-3.323-3.368c0-1.865,1.456-3.368,3.32-3.368
			c0.851-0.011,1.672,0.314,2.284,0.905l0.893-0.893c-0.76-0.723-1.764-1.278-3.177-1.278c-0.843,0.001-1.669,0.228-2.394,0.658
			c-1.372,0.81-2.308,2.291-2.308,3.978c0,1.74,0.996,3.26,2.439,4.052c0.693,0.382,1.472,0.582,2.263,0.583
			c1.379,0,2.419-0.456,3.233-1.3c0.836-0.835,1.097-2.012,1.097-2.962c0.003-0.265-0.02-0.53-0.068-0.791h-4.262V22.392z
			 M82.607,23.375c-0.248-0.667-1.006-1.899-2.554-1.899c-0.641-0.004-1.262,0.216-1.759,0.621
			c-0.641,0.526-1.056,1.353-1.056,2.364c0,1.671,1.266,2.984,2.962,2.984c0.492,0.003,0.976-0.117,1.41-0.348
			c0.434-0.231,0.804-0.566,1.077-0.975l-1.018-0.679c-0.339,0.497-0.803,0.825-1.47,0.825c-0.667,0-1.139-0.304-1.443-0.904
			l3.99-1.65L82.607,23.375z M78.539,24.37c-0.034-1.154,0.892-1.741,1.559-1.741c0.52,0,0.961,0.26,1.108,0.633l-1.394,0.579
			L78.539,24.37z M75.294,27.266h1.311v-8.774h-1.311V27.266z M73.146,22.146h-0.045c-0.294-0.351-0.86-0.668-1.571-0.668
			c-0.694,0.009-1.36,0.276-1.868,0.749c-0.337,0.305-0.601,0.682-0.772,1.104c-0.171,0.421-0.245,0.876-0.216,1.33
			c0.029,0.454,0.161,0.895,0.384,1.291c0.224,0.396,0.534,0.736,0.908,0.996c0.457,0.321,1.002,0.495,1.561,0.5
			c0.712,0,1.277-0.317,1.571-0.678h0.045v0.429c0,1.139-0.608,1.752-1.595,1.752c-0.803,0-1.3-0.576-1.504-1.063l-1.139,0.475
			c0.327,0.791,1.199,1.764,2.645,1.764c1.537,0,2.837-0.904,2.837-3.109v-5.36h-1.241V22.146z M71.643,26.271
			c-0.905,0-1.662-0.759-1.662-1.798c0-1.038,0.759-1.823,1.662-1.823c0.892,0,1.595,0.769,1.595,1.823
			c0,1.054-0.706,1.795-1.595,1.795V26.271z M88.75,18.494h-3.137v8.772h1.309V23.94h1.828c0.415,0.002,0.826-0.083,1.207-0.248
			c0.381-0.165,0.723-0.408,1.005-0.713c0.426-0.484,0.662-1.106,0.665-1.75c0.003-0.645-0.227-1.269-0.648-1.757
			c-0.282-0.31-0.627-0.558-1.01-0.727c-0.384-0.169-0.799-0.255-1.219-0.254V18.494z M88.784,22.722h-1.862v-3.009h1.864
			c0.2-0.004,0.4,0.031,0.586,0.104c0.186,0.074,0.356,0.184,0.499,0.324c0.143,0.14,0.257,0.307,0.335,0.492
			c0.078,0.185,0.118,0.383,0.118,0.583s-0.04,0.399-0.118,0.583c-0.078,0.185-0.192,0.352-0.335,0.492
			c-0.143,0.14-0.313,0.25-0.499,0.324c-0.186,0.074-0.385,0.109-0.586,0.105L88.784,22.722z M96.875,21.462
			c-0.948,0-1.929,0.418-2.335,1.343l1.162,0.485c0.248-0.485,0.711-0.643,1.196-0.643c0.677,0,1.367,0.406,1.377,1.129v0.09
			c-0.421-0.223-0.89-0.34-1.367-0.339c-0.532-0.005-1.057,0.124-1.526,0.375c-0.588,0.328-1.002,0.867-1.002,1.6
			c-0.002,0.291,0.065,0.578,0.198,0.837c0.132,0.259,0.325,0.482,0.562,0.651c0.413,0.293,0.909,0.448,1.416,0.443
			c0.88,0,1.367-0.395,1.671-0.858h0.046v0.677h1.264v-3.365c0-0.925-0.409-1.606-1.057-2.008c-0.44-0.275-0.994-0.418-1.604-0.418
			V21.462z M96.717,26.27c-0.428,0-1.027-0.215-1.027-0.745c0-0.677,0.745-0.937,1.388-0.937c0.576,0,0.846,0.124,1.196,0.293
			c-0.049,0.38-0.233,0.73-0.519,0.985c-0.286,0.255-0.655,0.397-1.038,0.401V26.27z M104.142,21.654l-0.622,1.572l-0.879,2.228
			h-0.046l-1.557-3.803h-1.41l1.126,2.563l1.209,2.752l-1.329,2.958h1.367l2.554-5.868l1.046-2.404L104.142,21.654z M92.35,27.266
			h1.309v-8.774H92.35V27.266z"
        />

        <linearGradient
          id="SVGID_00000000180554073665380300000016593351629163378586_"
          gradientUnits="userSpaceOnUse"
          x1="10.1447"
          y1="42.3321"
          x2="31.6516"
          y2="24.663"
          gradientTransform="matrix(1 0 0 -1 0 40.6772)"
        >
          <stop offset="0" style={{ stopColor: "#32A071" }} />
          <stop offset="0.07" style={{ stopColor: "#2DA771" }} />
          <stop offset="0.48" style={{ stopColor: "#15CF74" }} />
          <stop offset="0.8" style={{ stopColor: "#06E775" }} />
          <stop offset="1" style={{ stopColor: "#00F076" }} />
        </linearGradient>
        <path
          fill="url(#SVGID_00000000180554073665380300000016593351629163378586_)"
          d="M36.189,16.937l-10.427-6.116
			c-0.242-0.143-0.528-0.19-0.803-0.134c-0.275,0.056-0.52,0.212-0.686,0.438l8.831,8.886L36.189,16.937z"
        />

        <linearGradient
          id="SVGID_00000029043098951313910080000002071023123748380571_"
          gradientUnits="userSpaceOnUse"
          x1="39.2724"
          y1="20.7944"
          x2="23.2135"
          y2="20.6995"
          gradientTransform="matrix(1 0 0 -1 0 40.6772)"
        >
          <stop offset="0" style={{ stopColor: "#00A0FF" }} />
          <stop offset="0.01" style={{ stopColor: "#00A1FF" }} />
          <stop offset="0.26" style={{ stopColor: "#00BEFF" }} />
          <stop offset="0.51" style={{ stopColor: "#00D2FF" }} />
          <stop offset="0.76" style={{ stopColor: "#00DFFF" }} />
          <stop offset="1" style={{ stopColor: "#00E3FF" }} />
        </linearGradient>
        <path
          fill="url(#SVGID_00000029043098951313910080000002071023123748380571_)"
          d="M24.052,28.168
			c-0.001,0.232,0.069,0.458,0.202,0.648l8.849-8.806l-8.831-8.882c-0.144,0.195-0.222,0.431-0.22,0.673V28.168z"
        />

        <linearGradient
          id="SVGID_00000090289587144546955170000013349687814057412022_"
          gradientUnits="userSpaceOnUse"
          x1="41.5214"
          y1="20.2584"
          x2="27.0263"
          y2="21.3954"
          gradientTransform="matrix(1 0 0 -1 0 40.6772)"
        >
          <stop offset="0" style={{ stopColor: "#FFE000" }} />
          <stop offset="0.41" style={{ stopColor: "#FFBD00" }} />
          <stop offset="0.78" style={{ stopColor: "#FFA500" }} />
          <stop offset="1" style={{ stopColor: "#FF9C00" }} />
        </linearGradient>
        <path
          fill="url(#SVGID_00000090289587144546955170000013349687814057412022_)"
          d="M36.136,23.06l3.574-2.098
			c0.17-0.101,0.31-0.244,0.408-0.416c0.098-0.172,0.149-0.366,0.149-0.563c0-0.197-0.051-0.391-0.149-0.563
			c-0.098-0.172-0.238-0.315-0.408-0.416l-3.521-2.067l-3.086,3.071L36.136,23.06z"
        />

        <linearGradient
          id="SVGID_00000145760142535933119020000011614569235631617971_"
          gradientUnits="userSpaceOnUse"
          x1="39.634"
          y1="22.4709"
          x2="11.7329"
          y2="3.9489"
          gradientTransform="matrix(1 0 0 -1 0 40.6772)"
        >
          <stop offset="0" style={{ stopColor: "#FF3A44" }} />
          <stop offset="1" style={{ stopColor: "#C31162" }} />
        </linearGradient>
        <path
          fill="url(#SVGID_00000145760142535933119020000011614569235631617971_)"
          d="M24.254,28.816
			c0.163,0.235,0.409,0.4,0.689,0.461c0.28,0.061,0.572,0.015,0.819-0.13l10.375-6.087l-3.033-3.05L24.254,28.816z"
        />
      </g>
    </g>
  </svg>
);

export const AppStore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 40"
    enableBackground="new 0 0 128 40"
    xmlSpace="preserve"
    width="128"
    height="40"
  >
    <g>
      <defs>
        <rect id="SVGID_1_AS" y="0" width="128" height="40" />
      </defs>
      <clipPath id="SVGID_00000111168740145395156610000018076327908212086451_">
        <use xlinkHref="#SVGID_1_AS" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_00000111168740145395156610000018076327908212086451_)">
        <path
          fill="#FFFFFF"
          d="M123.485,0H4.515C2.021,0,0,2.052,0,4.582v30.836C0,37.949,2.021,40,4.515,40h118.97
			c2.494,0,4.515-2.051,4.515-4.582V4.582C128,2.052,125.979,0,123.485,0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity="0.12"
          d="M123.485,1H4.515C2.587,1,1,2.59,1,4.582v30.836
			C1,37.41,2.587,39,4.515,39h118.97c1.928,0,3.515-1.59,3.515-3.582V4.582C127,2.59,125.413,1,123.485,1z M4.515,0h118.97
			C125.979,0,128,2.052,128,4.582v30.836c0,2.531-2.021,4.582-4.515,4.582H4.515C2.021,40,0,37.949,0,35.418V4.582
			C0,2.052,2.021,0,4.515,0z"
        />
        <path
          fill="#333333"
          d="M50.636,10.396c1.413,0,2.248,0.881,2.248,2.38s-0.832,2.383-2.248,2.383h-1.601v-4.764H50.636z
			 M49.629,14.625h0.968c1.079,0,1.68-0.66,1.68-1.842s-0.597-1.852-1.68-1.852h-0.968V14.625z"
        />
        <path
          fill="#333333"
          d="M53.701,13.443c0-1.096,0.64-1.776,1.591-1.776c0.951,0,1.588,0.684,1.588,1.776
			c0,1.093-0.641,1.776-1.588,1.776C54.346,15.22,53.701,14.536,53.701,13.443z M56.296,13.443c0-0.809-0.396-1.271-1.003-1.271
			c-0.608,0-1.007,0.463-1.007,1.271c0,0.809,0.4,1.271,1.007,1.271c0.608,0,1-0.462,1-1.271H56.296z"
        />
        <path
          fill="#333333"
          d="M61.207,15.16h-0.585l-0.775-2.658h-0.014l-0.772,2.658h-0.584l-0.961-3.433h0.571l0.684,2.793h0.013
			l0.776-2.793h0.554l0.779,2.793h0.013l0.684-2.793h0.567L61.207,15.16z"
        />
        <path
          fill="#333333"
          d="M62.97,11.727h0.542v0.548h0.014c0.191-0.37,0.557-0.608,1.089-0.608c0.753,0,1.189,0.485,1.189,1.265
			v2.228h-0.574v-2.127c0-0.554-0.261-0.858-0.789-0.858c-0.542,0-0.895,0.38-0.895,0.964v2.02H62.97V11.727z"
        />
        <path fill="#333333" d="M66.901,10.396h0.574v4.764h-0.574V10.396z" />
        <path
          fill="#333333"
          d="M68.421,13.443c0-1.096,0.64-1.776,1.595-1.776c0.955,0,1.588,0.684,1.588,1.776
			c0,1.093-0.64,1.776-1.588,1.776C69.068,15.22,68.421,14.536,68.421,13.443z M71.016,13.443c0-0.809-0.396-1.271-1.004-1.271
			c-0.608,0-1.007,0.463-1.007,1.271c0,0.809,0.399,1.271,1.007,1.271C70.619,14.715,71.016,14.252,71.016,13.443z"
        />
        <path
          fill="#333333"
          d="M72.377,14.189c0-0.587,0.456-0.957,1.245-1.003l0.987-0.056v-0.287c0-0.419-0.277-0.671-0.753-0.671
			c-0.442,0-0.719,0.208-0.792,0.545H72.51c0.039-0.595,0.548-1.05,1.36-1.05c0.813,0,1.314,0.436,1.314,1.12v2.373h-0.545v-0.591
			h-0.013c-0.108,0.198-0.268,0.363-0.462,0.477c-0.194,0.115-0.416,0.175-0.641,0.174C72.836,15.22,72.377,14.81,72.377,14.189z
			 M74.605,13.856v-0.28l-0.918,0.056c-0.462,0.03-0.726,0.234-0.726,0.557c0,0.323,0.273,0.542,0.684,0.542
			C74.19,14.734,74.605,14.355,74.605,13.856z"
        />
        <path
          fill="#333333"
          d="M76.079,13.443c0-1.076,0.591-1.776,1.456-1.776c0.491,0,0.888,0.242,1.092,0.617h0.014v-1.888h0.574
			v4.764h-0.548v-0.577h-0.01c-0.108,0.198-0.269,0.363-0.465,0.476c-0.196,0.113-0.419,0.17-0.645,0.165
			C76.673,15.22,76.079,14.52,76.079,13.443z M76.667,13.443c0,0.779,0.383,1.271,0.987,1.271c0.592,0,0.987-0.502,0.987-1.271
			c0-0.769-0.396-1.271-0.987-1.271C77.05,12.172,76.667,12.667,76.667,13.443z"
        />
        <path
          fill="#333333"
          d="M81.888,13.443c0-1.096,0.64-1.776,1.595-1.776c0.955,0,1.588,0.684,1.588,1.776
			c0,1.093-0.641,1.776-1.588,1.776S81.888,14.536,81.888,13.443z M84.483,13.443c0-0.809-0.396-1.271-1.003-1.271
			c-0.608,0-1.007,0.463-1.007,1.271c0,0.809,0.399,1.271,1.007,1.271c0.608,0,1.001-0.462,1.001-1.271H84.483z"
        />
        <path
          fill="#333333"
          d="M85.98,11.727h0.541v0.548h0.014c0.191-0.37,0.558-0.608,1.089-0.608c0.753,0,1.189,0.485,1.189,1.265
			v2.228h-0.574v-2.127c0-0.554-0.261-0.858-0.789-0.858c-0.542,0-0.895,0.38-0.895,0.964v2.02H85.98L85.98,11.727z"
        />
        <path
          fill="#333333"
          d="M92.365,10.897v0.829h0.667v0.472h-0.667v2.041c0,0.314,0.132,0.456,0.426,0.456
			c0.066,0,0.198-0.01,0.238-0.016v0.482c-0.12,0.02-0.242,0.029-0.363,0.027c-0.63,0-0.875-0.258-0.875-0.918v-2.071h-0.512v-0.472
			h0.512v-0.829H92.365z"
        />
        <path
          fill="#333333"
          d="M94.011,10.396h0.574v1.878h0.013c0.192-0.38,0.568-0.608,1.09-0.608c0.753,0,1.208,0.509,1.208,1.274
			v2.219h-0.577v-2.113c0-0.545-0.273-0.871-0.812-0.871c-0.571,0-0.924,0.39-0.924,0.984v2.001h-0.574L94.011,10.396z"
        />
        <path
          fill="#333333"
          d="M100.806,14.176c-0.099,0.601-0.68,1.044-1.429,1.044c-0.987,0-1.581-0.684-1.581-1.764
			c0-1.063,0.608-1.789,1.555-1.789c0.928,0,1.499,0.674,1.499,1.714v0.215h-2.463v0.033c0,0.657,0.386,1.089,1.003,1.089
			c0.436,0,0.76-0.221,0.853-0.545L100.806,14.176z M98.389,13.149h1.865c-0.012-0.574-0.372-0.984-0.911-0.984
			C98.805,12.166,98.426,12.578,98.389,13.149z"
        />
        <path
          fill="#333333"
          d="M51.993,18.977h1.486l3.218,8.862h-1.443l-0.81-2.396h-3.418l-0.817,2.395h-1.443L51.993,18.977z
			 M51.396,24.338h2.672l-1.321-3.893h-0.024L51.396,24.338z"
        />
        <path
          fill="#333333"
          d="M58.142,21.379h1.327v1.111h0.03c0.387-0.767,1.1-1.234,2.02-1.234c1.646,0,2.702,1.291,2.702,3.353v0.006
			c0,2.058-1.063,3.353-2.683,3.353c-0.915,0-1.659-0.467-2.039-1.215h-0.03v3.236h-1.327V21.379z M62.871,24.615v-0.007
			c0-1.375-0.657-2.217-1.694-2.217c-1.007,0-1.713,0.872-1.713,2.217v0.006c0,1.339,0.712,2.211,1.713,2.211
			C62.217,26.826,62.871,25.975,62.871,24.615z"
        />
        <path
          fill="#333333"
          d="M65.877,21.379h1.326v1.111h0.031c0.386-0.767,1.099-1.234,2.02-1.234c1.646,0,2.702,1.291,2.702,3.353
			v0.006c0,2.058-1.063,3.353-2.684,3.353c-0.911,0-1.658-0.467-2.038-1.215h-0.031v3.236h-1.326V21.379z M70.606,24.615v-0.007
			c0-1.375-0.657-2.217-1.695-2.217c-1.007,0-1.714,0.872-1.714,2.217v0.006c0,1.339,0.712,2.211,1.714,2.211
			C69.955,26.826,70.606,25.975,70.606,24.615z"
        />
        <path
          fill="#333333"
          d="M76.556,25.481l-0.006-0.092h1.339l0.012,0.08c0.123,0.817,0.939,1.326,2.127,1.326
			c1.187,0,1.935-0.532,1.935-1.367v-0.006c0-0.712-0.485-1.099-1.659-1.357l-0.964-0.203c-1.738-0.368-2.582-1.167-2.582-2.48
			v-0.006c0.006-1.511,1.339-2.549,3.212-2.549c1.849,0,3.114,1.007,3.206,2.493l0.006,0.104h-1.339l-0.006-0.086
			c-0.123-0.823-0.835-1.32-1.885-1.32c-1.1,0.006-1.8,0.528-1.8,1.302v0.006c0,0.663,0.504,1.069,1.609,1.308l0.97,0.203
			c1.83,0.387,2.617,1.13,2.617,2.5v0.006c0,1.615-1.277,2.641-3.378,2.641C77.98,27.986,76.666,27.038,76.556,25.481z"
        />
        <path
          fill="#333333"
          d="M85.427,26.138v-3.703h-0.927v-1.056h0.927v-1.683h1.357v1.683h1.21v1.056h-1.21v3.623
			c0,0.657,0.295,0.854,0.854,0.854c0.119-0.001,0.238-0.009,0.356-0.024v1.025c-0.224,0.035-0.45,0.054-0.676,0.055
			C86.04,27.968,85.427,27.427,85.427,26.138z"
        />
        <path
          fill="#333333"
          d="M89.274,24.608v-0.012c0-2.063,1.222-3.342,3.059-3.342s3.07,1.271,3.07,3.342v0.012
			c0,2.082-1.215,3.359-3.064,3.359C90.49,27.967,89.274,26.684,89.274,24.608z M94.046,24.608v-0.012
			c0-1.413-0.676-2.229-1.713-2.229c-1.02,0-1.701,0.816-1.701,2.229v0.012c0,1.431,0.675,2.247,1.713,2.247
			C93.382,26.855,94.046,26.034,94.046,24.608z"
        />
        <path
          fill="#333333"
          d="M97.057,21.379h1.327v1.103h0.03c0.209-0.755,0.75-1.228,1.486-1.228c0.155,0.002,0.309,0.019,0.461,0.049
			v1.234c-0.193-0.057-0.394-0.083-0.596-0.076c-0.854,0-1.382,0.559-1.382,1.519v3.851h-1.327V21.379z"
        />
        <path
          fill="#333333"
          d="M101.248,24.633v-0.006c0-2.02,1.167-3.371,2.979-3.371c1.811,0,2.923,1.302,2.923,3.242v0.449h-4.575
			c0.024,1.222,0.693,1.934,1.747,1.934c0.786,0,1.307-0.406,1.474-0.891l0.018-0.049h1.259l-0.013,0.068
			c-0.208,1.026-1.203,1.96-2.769,1.96C102.395,27.968,101.248,26.672,101.248,24.633z M102.593,24.006h3.242
			c-0.11-1.117-0.736-1.664-1.602-1.664C103.367,22.342,102.71,22.926,102.593,24.006z"
        />
        <path
          fill="#333333"
          d="M36.949,17.221c-0.826,0.575-1.388,1.153-1.687,1.733c-0.298,0.583-0.451,1.229-0.446,1.884
			c-0.006,0.501,0.084,0.999,0.264,1.467c0.184,0.457,0.436,0.883,0.749,1.263c0.323,0.399,0.862,0.795,1.618,1.188
			c-0.331,0.933-0.788,1.816-1.358,2.625c-0.615,0.893-1.172,1.526-1.671,1.899c-0.499,0.373-0.975,0.56-1.43,0.56
			c-0.363,0-0.852-0.116-1.467-0.348l-0.287-0.106l-0.469-0.197c-0.359-0.105-0.731-0.156-1.104-0.152
			c-0.476,0.012-0.946,0.104-1.391,0.272l-0.363,0.136l-0.456,0.181c-0.347,0.138-0.716,0.21-1.089,0.212
			c-0.767,0-1.589-0.537-2.466-1.611c-0.855-1.035-1.529-2.207-1.992-3.467c-0.442-1.199-0.67-2.466-0.673-3.744
			c0-1.624,0.461-2.976,1.384-4.055c0.923-1.08,2.176-1.619,3.76-1.619c0.555-0.004,1.106,0.104,1.618,0.318l0.35,0.138l0.363,0.152
			c0.323,0.132,0.58,0.197,0.772,0.197c0.29-0.002,0.578-0.058,0.848-0.166l0.484-0.182l0.348-0.137
			c0.601-0.218,1.237-0.325,1.876-0.318C34.655,15.343,35.961,15.968,36.949,17.221z M33.107,10.012
			c0.02,0.222,0.03,0.393,0.03,0.514c0,0.626-0.182,1.271-0.545,1.937c-0.332,0.631-0.822,1.166-1.422,1.551
			c-0.579,0.366-1.251,0.558-1.936,0.552c-0.025-0.176-0.04-0.354-0.046-0.532c0-0.534,0.159-1.096,0.476-1.687
			c0.317-0.589,0.757-1.102,1.291-1.505c0.54-0.412,1.174-0.682,1.846-0.787L33.107,10.012z"
        />
      </g>
    </g>
  </svg>
);

export const Play = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <rect fill="#F2B1B0" width="32" height="32" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FFFFFF"
      fillOpacity="0.87"
      d="M11.568,9.757
	c-0.141-0.005-0.281,0.029-0.404,0.096c-0.124,0.068-0.227,0.168-0.298,0.289s-0.109,0.26-0.109,0.401v0.002v10.911v0.002
	c0,0.141,0.037,0.28,0.109,0.401c0.072,0.121,0.175,0.221,0.298,0.289c0.124,0.068,0.263,0.101,0.404,0.096
	c0.141-0.005,0.278-0.047,0.397-0.123l8.526-5.45c0.111-0.071,0.202-0.169,0.265-0.284c0.063-0.115,0.096-0.245,0.096-0.377
	c0-0.132-0.033-0.261-0.096-0.377c-0.063-0.115-0.154-0.213-0.265-0.284L11.965,9.88C11.846,9.804,11.708,9.762,11.568,9.757z
	 M9.001,10.547c-0.046-2.592,2.17-2.56,2.626-2.544c0.456,0.015,0.899,0.153,1.284,0.399l0.001,0.001l8.527,5.469
	c0.358,0.23,0.653,0.546,0.858,0.92c0.204,0.374,0.311,0.793,0.311,1.218c0,0.426-0.107,0.845-0.311,1.219
	c-0.204,0.374-0.499,0.69-0.858,0.92L12.91,23.6c-0.384,0.246-0.828,0.384-1.284,0.399c-0.456,0.015-0.908-0.092-1.308-0.312
	c-0.4-0.22-0.733-0.543-0.965-0.935C9.122,22.359,9,21.911,9.001,21.455v-0.001l0,0.029l0-0.028V10.547z"
    />
  </svg>
);

export const Pause = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <rect fill="#F2B1B0" width="32" height="32" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FFFFFF"
      fillOpacity="0.87"
      d="M12.112,8c0.614,0,1.112,0.43,1.112,0.96
	v14.08c0,0.53-0.498,0.96-1.112,0.96S11,23.57,11,23.04V8.96C11,8.43,11.498,8,12.112,8z M19.888,8C20.502,8,21,8.43,21,8.96v14.08
	c0,0.53-0.498,0.96-1.112,0.96c-0.614,0-1.112-0.43-1.112-0.96V8.96C18.775,8.43,19.273,8,19.888,8z"
    />
  </svg>
);
