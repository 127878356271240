const settingIntialState = {
  isVerticalMenubar: false,
};

const breakingNewsAdInitialState = {
  adPresence: true,
};

const userIntialState = {
  jwt_token: null,
  bookmark: null,
  loading: true,
};

function settingsReducer(state = settingIntialState, action) {
  switch (action.type) {
    case "VERTICAL_MENU_BAR":
      return {
        ...state,
        isVerticalMenubar: action.payload,
      };

    default:
      return state;
  }
}

function filterReducer(state = { isMobileFilter: false }, action) {
  switch (action.type) {
    case "MOBILE_FILTER":
      return {
        ...state,
        isMobileFilter: action.payload,
      };

    default:
      return state;
  }
}

function metypeReducer(state = settingIntialState, action) {
  switch (action.type) {
    case "METYPE_MENU_BAR":
      return {
        ...state,
        isMetypeBar: action.payload.isMetypeBar,
      };

    default:
      return state;
  }
}

function TopAndBottomAdReducer(state = settingIntialState, action) {
  switch (action.type) {
    case "TOP_AND_BOTTOM_AD_REDUCER":
      return {
        ...state,
        showAd: action.payload,
      };

    default:
      return state;
  }
}

function breakingNewsAdReducer(state = breakingNewsAdInitialState, action) {
  switch (action.type) {
    case "BREAKING_NEWS_AD":
      return {
        ...state,
        adPresence: action.payload,
      };

    default:
      return state;
  }
}

function userReducer(state = userIntialState, action) {
  switch (action.type) {
    case "METYPE_JWT_TOKEN":
      return {
        ...state,
        jwt_token: action.payload,
      };

    case "BOOKMARK":
      return {
        ...state,
        bookmark: action.payload,
      };

    case "CLEAR_ALL":
      return {
        ...state,
        ...userIntialState,
      };

    case "USER_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

function authorReducer(state = [], action) {
  switch (action.type) {
    case "SET_AUTHORS":
      return action.payload;

    default:
      return state;
  }
}

function commentsReducer(state = [], action) {
  switch (action.type) {
    case "SET_HOME_COMMENTS":
      return action.payload;

    default:
      return state;
  }
}

function socialShareReducer(state = [], action) {
  switch (action.type) {
    case "SET_SOCIAL_SHARE":
      return {
        ...state,
        isSocialShare: action.payload,
      };
    default:
      return state;
  }
}

function currentPathReducer(state = [], action) {
  switch (action.type) {
    case "CURRENT_PATH":
      return {
        ...state,
        currentPath: action.payload,
      };
    default:
      return state;
  }
}

function currStory(state = {}, action) {
  if (action.type === "CURRENT_STORY") {
    return action.payload;
  }

  return state;
}

function headerReducer(state = true, action) {
  if (action.type === "HEADER_DOWN") {
    return action.payload;
  }
  return state;
}

function headerStories(state = [], { type, payload }) {
  if (type === "HEADER_STORIES") {
    return payload;
  }
  return state;
}

export default {
  story: currStory,
  authorReducer,
  settingsReducer,
  commentsReducer,
  userReducer,
  metypeReducer,
  TopAndBottomAdReducer,
  socialShareReducer,
  currentPathReducer,
  breakingNewsAdReducer,
  filterReducer,
  "header-down": headerReducer,
  "header-stories": headerStories,
};
