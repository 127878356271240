import React, { useEffect, useState } from "react";
import { array, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import Icon from "../../../icons";
import FacebookIcon from "../FooterIcons/FacebookIcon";
import TwitterIcon from "../FooterIcons/TwitterIcon";
import { Instagram } from "../../../atoms/icons/StoryPageIcons";
import YoutubeIcon from "../FooterIcons/YoutubeIcon";
import FooterMenuGroup from "../../../molecules/FooterMenuGroup";

import { menuGroups } from "../../../helper/api";
import { completeUrlSetter } from "../../../utils/utils";

import "./trustFooter.m.css";

const TrustFooterBase = ({ trustMenuItems, currentPath, customSocialLinks, socialLinks, sections }) => {
  const socialShareLinks = customSocialLinks.trust || socialLinks;
  const [authMenu, setAuthMenu] = useState({});

  useEffect(() => {
    if (global.location.origin.includes("auth")) {
      menuGroups().then((res) => {
        const menu = get(res, ["menu-groups", "trust"], []);
        const footerMenu = menu.items.filter((item) => item.title === "footer");
        const footerChildren =
          footerMenu.length > 0 ? menu.items.filter((item) => item["parent-id"] === footerMenu[0].id) : [];
        const footerChildrenObj = { children: footerChildren };
        setAuthMenu(completeUrlSetter(footerChildrenObj, sections));
      });
    } else if (trustMenuItems) {
      const menuItems =
        trustMenuItems && trustMenuItems.length ? trustMenuItems.find((item) => item.title === "footer") : null;
      setAuthMenu(menuItems);
    }
  }, [trustMenuItems]);

  return (
    <div styleName="footer-wrapper">
      <div styleName="row-1">
        <div>
          <div styleName="logo-wrapper">
            <a styleName="logo-link" aria-label="Prothomalo trust logo" href="/">
              <Icon type="prothomalo-trust" styleName="trust-logo" />
            </a>
          </div>
          {authMenu && authMenu.children && authMenu.children.length > 0 && (
            <FooterMenuGroup links={authMenu.children.slice(0, 16)} className="print-none" />
          )}
        </div>
        <div className="print-none">
          <ul styleName="communication-details">
            <li>প্রথম আলো ট্রাস্ট</li>
            <li>প্রগতি ইনস্যুরেন্স ভবন, ২০-২১ কারওয়ান বাজার , ঢাকা ১২১৫</li>
            <li>
              ফোন:{" "}
              <a styleName="link-normal" href="tel:+88055013430">
                +৮৮০ ৫৫০১৩৪৩০–৩৩
              </a>
            </li>
            <li>
              ইমেইল:{" "}
              <a styleName="link-normal" href="mailto: trust@prothomalo.com">
                trust@prothomalo.com
              </a>
            </li>
          </ul>
          <div styleName="social-links">
            {socialShareLinks["facebook-url"] && (
              <Link
                aria-label="Trust Facebook Link"
                href={socialShareLinks["facebook-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon color="#222222" />
              </Link>
            )}
            {socialShareLinks["twitter-url"] && (
              <Link
                aria-label="Trust Twitter Link"
                href={socialShareLinks["twitter-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon color="#222222" />
              </Link>
            )}
            {socialShareLinks["instagram-url"] && (
              <Link
                aria-label="Trust Instagram Link"
                href={socialShareLinks["instagram-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </Link>
            )}
            {socialShareLinks["youtube-url"] && (
              <Link
                aria-label="Trust Youtube Link"
                href={socialShareLinks["youtube-url"]}
                className="youtube-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon color="#222222" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sections: get(state, ["qt", "config", "sections"], []),
  trustMenuItems: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

TrustFooterBase.propTypes = {
  trustMenuItems: array,
  currentPath: string,
  customSocialLinks: object,
  socialLinks: object,
  sections: array,
};

export const TrustFooter = connect(mapStateToProps)(TrustFooterBase);

export default TrustFooter;
