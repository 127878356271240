import React, { memo, useEffect } from "react";
import { bool, object, string } from "prop-types";
import { useSelector } from "react-redux";

import { getAdSlots } from "../utils";
import { staticTexts } from "../../utils/Translate";
import { PREVIEW_PAGE_TYPES } from "../../../constants";

import "./dfp-component.m.css";

// eslint-disable-next-line react/display-name
export const DfpComponent = memo(
  ({
    slotId,
    type = "",
    refreshAdUnit = false,
    className = "",
    collectionSlug,
    layoutName = "DefaultLayout",
    targetStory,
    adsText = false,
  }) => {
    const qtState = useSelector((state) => state?.qt || {});
    const { dfp_ads: dfpConfig = {}, slots = {} } = qtState.config?.["ads-config"] || {};
    const lang = qtState.config?.["publisher-attributes"]?.lang;
    const { pageType } = qtState;

    if (!slotId || !type) {
      return null;
    }

    const { sizes: size, adUnit: path, viewPortSizeMapping, renderOutOfThePage } = slots?.[type] || {};
    const { delay_ad_load: delayAdLoad = 4, load_ads_synchronously: loadAdsSynchronously = true } = dfpConfig;

    useEffect(() => {
      if ([...PREVIEW_PAGE_TYPES, "not-found"].includes(pageType)) return;

      const slot = getAdSlots({
        path,
        size,
        id: slotId,
        qtState,
        type,
        layoutName,
        viewPortSizeMapping,
        loadAdsSynchronously,
        delayPeriod: delayAdLoad * 0,
        renderOutOfThePage,
        refreshAdUnit,
        collectionSlug,
        targetStory,
      });

      return () => {
        if (window.googletag && window.googletag.apiReady) {
          window?.googletag?.destroySlots([slot]);
        }
      };
    }, [slotId]);

    return (
      <div
        className="dfp-ad-unit print-none"
        styleName={adsText ? `base` : ""}
        data-lang={staticTexts(lang).advertising}
      >
        <div className="adunitContainer">
          <div className={`${className} adBox`} id={slotId} />
        </div>
      </div>
    );
  }
);

DfpComponent.propTypes = {
  slotId: string,
  type: string,
  refreshAdUnit: bool,
  className: string,
  layoutName: string,
  collectionSlug: string,
  targetStory: object,
  adsText: bool,
};
