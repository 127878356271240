import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { DfpComponent } from "../../ads/dfp-component";
import DfpContainer from "../../ads/dfp-container";

import { getSlotId } from "../../utils/utils";

import "./topAds.m.css";

const TopAd = ({ lang = "bn", pageType, currentPath }) => {
  const ads = {
    "home-page": {
      en: "EnPalo_HP_970x90_T1",
      bn: "Home_728x90_T1",
    },
    en: "EnPalo_ROS_728x90_T1",
    bn: "News_728x90_T1",
  };

  return (
    <DfpContainer styleName="base">
      <div styleName="top-ad-wrapper">
        <DfpComponent
          type={ads[pageType]?.[lang] || ads[lang]}
          slotId={getSlotId("top-ad", ads[pageType]?.[lang] || ads[lang], currentPath)}
        />
      </div>
    </DfpContainer>
  );
};

TopAd.propTypes = {
  lang: string,
  pageType: string,
  currentPath: string,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], "bn"),
  pageType: get(state, ["qt", "pageType"]),
  currentPath: get(state, ["qt", "currentPath"], ""),
});

export default connect(mapStateToProps)(TopAd);
