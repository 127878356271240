export const STATIC_TAGS = {
  "twitter:site": "@Prothomalo",
  "twitter:app:name:ipad": undefined,
  "twitter:app:name:googleplay": undefined,
  "twitter:app:id:googleplay": undefined,
  "twitter:app:name:iphone": undefined,
  "twitter:app:id:iphone": undefined,
  "apple-itunes-app": undefined,
  "google-play-app": undefined,
  "fb:app_id": "1499138263726489",
  "fb:pages": "163059227060505",
  "og:site_name": "Prothomalo",
  brand_name: "প্রথম আলো",
};

export const SOCIAL_TAG = {
  protichinta: {
    "fb:pages": "659156450832814",
    "og:site_name": "Protichinta",
    "twitter:site": "@Protichinta",
    brand_name: "প্রতিচিন্তা",
  },
  bigganchinta: {
    "fb:pages": "538696889672884",
    "og:site_name": "bigganchinta",
    "twitter:site": "@bigganchinta",
    brand_name: "বিজ্ঞানচিন্তা",
  },
  bondhushava: {
    "fb:pages": "307978799220778",
    "og:site_name": "Bondhushava.PA",
    "twitter:site": "@PABondhushava",
    brand_name: "বন্ধুসভা",
  },
  kishoralo: {
    "fb:pages": "252140831603198",
    "og:site_name": "kishor.alo",
    "twitter:site": "@kishor_alo",
    brand_name: "কিশোর আলো",
  },
  trust: {
    "fb:pages": "163607250349020",
    "og:site_name": "Palotrust",
    "twitter:site": "@prothomalotrust",
    brand_name: "প্রথম আলো ট্রাস্ট",
  },
  en: {
    "fb:pages": "1410366529268742",
    "og:site_name": "ProthomAloEnglish",
    "twitter:site": "@ProthomAlo",
    brand_name: "Prothom Alo",
  },
};

export const MICROSITES_OLD_URLS = [
  ["/kishoralo", "https://www.kishoralo.com"],
  ["/bondhushava", "https://www.bondhushava.com"],
  ["/protichinta", "https://www.protichinta.com"],
  ["/bigganchinta", "https://www.bigganchinta.com"],
  ["/trust", "https://trust.prothomalo.com"],
  ["/nagorik-sangbad", "https://nagorik.prothomalo.com"],
];

export const PAGES = {
  "author-page": "authorPage",
  "home-page": "homePage",
  "section-page": "sectionPage",
  "collection-page": "collectionPage",
  "tag-page": "tagPage",
  "notification-page": "notificationPage",
  "search-page": "searchPage",
  "saved-page": "savedPage",
  "story-page": "storyPage",
  "forgot-password-page": "forgotPasswordPage",
  "reset-password-page": "resetPasswordPage",
  "change-password-page": "changPasswordPage",
  "profile-page": "profilePage",
  "edit-profile-page": "editProfilePage",
  "my-comments": "myCommentPage",
  "sign-in-page": "loginPage",
  "sign-up-page": "signupPage",
  "poll-counter-page": "pollCounterPage",
  "ugc-page": "ugcPage",
};

export const STAGING_GA = {
  bn: "G-YS58L70JRD",
  en: "G-LGG9YCFEC3",
  bigganchinta: "G-3TT5TDRJLG",
  bondhushava: "G-QLN7P2NPM9",
  kishoralo: "G-NC4WP863MG",
  "nagorik-sangbad": "G-KF7WL1VS2C",
  trust: "G-PYQDF05DT4",
  protichinta: "G-PH29LW0HP9",
};

export const PRODUCTION_GA = {
  bn: "G-C4T51Z1NR8",
  en: "G-YMB9CT21SM",
  bigganchinta: "G-B5BQPSZ0RG",
  bondhushava: "G-BTBBZMC72R",
  kishoralo: "G-BWQ55QC14H",
  "nagorik-sangbad": "G-PPDWRDTGGR",
  trust: "G-E62BWDZY3X",
  protichinta: "G-GW82P5ZKDF",
};

export const PALO_HOSTS = [
  "localhost",
  "bn.prothom-alo.com",
  "www.prothomalo.com",
  "beta.prothomalo.com",
  "en.prothomalo.com",
  "en-beta.prothomalo.com",
];

export const BIGGANCHINTA_HOSTS = [
  "bigganchinta.lvh.me",
  "bc.prothom-alo.com",
  "bigganchinta.com",
  "beta.bigganchinta.com",
];
export const BONDUSHAVA_HOSTS = ["bondhushava.lvh.me", "bs.prothom-alo.com", "bondhushava.com", "beta.bondhushava.com"];
export const KISHORALO_HOSTS = ["kishoralo.lvh.me", "kia.prothom-alo.com", "kishoralo.com", "beta.kishoralo.com"];
export const NAGORIK_SONGBAD_HOSTS = [
  "nagorik.lvh.me",
  "ns.prothom-alo.com",
  "nagorik.prothomalo.com",
  "nagorik-beta.prothomalo.com",
];
export const PROTICHINTA_HOSTS = [
  "protichinta.lvh.me",
  "pc.prothom-alo.com",
  "protichinta.com",
  "beta.protichinta.com",
];
export const TRUST_HOSTS = ["trust.lvh.me", "trust.prothom-alo.com", "protichinta.com", "beta.protichinta.com"];
