import React from "react";
import { func, object, string } from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";

import NavSearch from "../../../../atoms/NavSearch";
import { SvgIcon } from "../../../../icons";
import Epaper from "../../../../atoms/Epaper";
import HamItem from "../HamItem";
import PaloAuth from "../../../../molecules/PaloAuth";
import LanguageEdition from "../../../../atoms/LanguageEdition";
import Anchor from "../../../../atoms/Anchor";
import NavIcons from "./nav";

import { staticTexts } from "../../../../utils/Translate";
import { appDownloadLinks } from "../../../../utils/utils";

import "./ham-utils.m.css";

const HamUtils = ({
  lang,
  publisherAttributes,
  footerMenu,
  onClose,
  googleNewsLink,
  customSocialLinks = {},
  domainSlug,
}) => {
  const socialShareLinks = customSocialLinks[lang] || {};

  const uddogIcons = [
    { img: "epaper", url: "https://epaper.prothomalo.com" },
    { img: "trust", url: "https://trust.prothomalo.com" },
    { img: "nagorik-songbad", url: "https://nagorik.prothomalo.com" },
    { img: "chironton71", url: "https://1971.prothomalo.com" },
    { img: "kishoralo", url: "https://www.kishoralo.com" },
    { img: "bigganchinta", url: "https://www.bigganchinta.com" },
    { img: "bondhushava", url: "https://www.bondhushava.com" },
    { img: "prothoma", url: "https://www.prothoma.com" },
  ];

  return (
    <div styleName="base">
      <div styleName="login-search-container">
        <PaloAuth onClick={onClose} hamburger={true} />
        <NavSearch lang={lang} onClick={onClose} />
      </div>
      <div styleName="epaper-edition">
        <Epaper styleName="epaper" />
        <LanguageEdition styleName="edition" publisherAttributes={publisherAttributes} />
      </div>
      <div styleName="nav-share">
        <p styleName="share-text">{staticTexts(lang).followUsText}</p>
        <div styleName="nav-share-icons">
          {socialShareLinks["facebook-url"] && (
            <a href={socialShareLinks["facebook-url"]} target="_blank" rel="noreferrer" styleName="share-icon-item">
              <SvgIcon onClick={onClose} type="facebook-outline" />
              <SvgIcon onClick={onClose} type="facebook-fill" />
            </a>
          )}
          {socialShareLinks["twitter-url"] && (
            <a href={socialShareLinks["twitter-url"]} target="_blank" rel="noreferrer" styleName="share-icon-item">
              <SvgIcon onClick={onClose} type="twitter-outline" />
              <SvgIcon onClick={onClose} type="twitter-fill" />
            </a>
          )}
          {socialShareLinks["instagram-url"] && (
            <a href={socialShareLinks["instagram-url"]} target="_blank" rel="noreferrer" styleName="share-icon-item">
              <SvgIcon onClick={onClose} type="instagram-outline" />
              <SvgIcon onClick={onClose} type="instagram-fill" />
            </a>
          )}
          {socialShareLinks["youtube-url"] && (
            <a href={socialShareLinks["youtube-url"]} target="_blank" rel="noreferrer" styleName="share-icon-item">
              <SvgIcon onClick={onClose} type="youtube-outline" />
              <SvgIcon onClick={onClose} type="youtube-fill" />
            </a>
          )}
          {domainSlug == null && googleNewsLink && (
            <a href={googleNewsLink} target="_blank" rel="noreferrer" styleName="share-icon-item">
              <SvgIcon onClick={onClose} type="google-news-outline" />
              <SvgIcon onClick={onClose} type="google-news-fill" />
            </a>
          )}
        </div>
      </div>
      <div styleName="apps-links">
        <p styleName="links-text">{staticTexts(lang).downloadMobileApps}</p>
        <div styleName="apps-links-icons">
          <a
            href={appDownloadLinks(lang, "google", domainSlug)}
            styleName="apps-link"
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
          >
            <img onClick={onClose} src={NavIcons["playstore-black"]} alt="app store" />
            <img onClick={onClose} src={NavIcons["playstore-colored"]} alt="app store" />
          </a>
          <a
            href={appDownloadLinks(lang, "apple", domainSlug)}
            styleName="apps-link"
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
          >
            <img onClick={onClose} src={NavIcons["appstore-black"]} alt="app store" />
            <img onClick={onClose} src={NavIcons["appstore-colored"]} alt="app store" />
          </a>
        </div>
      </div>
      <div styleName="others-palo">
        <p styleName="others-text">{staticTexts(lang).initiatives}</p>
        <div styleName="others-palo-items">
          {uddogIcons.map(({ img, url }, index) => (
            <Anchor
              onClick={onClose}
              href={url || "/"}
              styleName="icon-base"
              key={index}
              target="_blank"
              rel="noreferrer"
              className={img}
              aria-label="Close icon"
            >
              <img onClick={onClose} src={NavIcons[img + "-black"]} alt={img} />
              <img onClick={onClose} src={NavIcons[img + "-colored"]} alt={img} />
            </Anchor>
          ))}
        </div>
      </div>
      <div styleName="footer-menu">
        {footerMenu.children.map((item, index) => (
          <div styleName="footer-item" key={index}>
            <span styleName="bullet">&nbsp;&#8226;&nbsp;</span>
            <HamItem click={onClose} menu={item} target="_blank" styleName="footer-menu-item" />
          </div>
        ))}
      </div>
    </div>
  );
};

HamUtils.propTypes = {
  publisherAttributes: object,
  lang: string,
  footerMenu: object,
  onClose: func,
  customSocialLinks: object,
  domainSlug: string,
  googleNewsLink: string,
};

const mapStateToProps = (state) => {
  return {
    domainSlug: get(state, ["qt", "config", "domainSlug"]),
    googleNewsLink: get(state, ["qt", "config", "publisher-attributes", "googleNewsLink"], ""),
    customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  };
};

export default connect(mapStateToProps)(HamUtils);
