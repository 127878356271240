import React from "react";
import { connect } from "react-redux";
import { arrayOf, object, string } from "prop-types";
import get from "lodash/get";

import StoryTypeWithText from "../../atoms/StoryTypeWithText";

import "./header-collection.m.css";

const HeaderCollection = ({ stories = [], lang }) => {
  if (!stories.length) return null;

  return (
    <div styleName="base" className="print-none">
      {stories.map(({ story }, index) => (
        <StoryTypeWithText styleName="headline-item" story={story} key={index} lang={lang} />
      ))}
    </div>
  );
};

HeaderCollection.propTypes = {
  stories: arrayOf(object),
  lang: string,
};

const mapStateToProps = (state) => ({
  stories: state["header-stories"],
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], "bn"),
});

export default connect(mapStateToProps)(HeaderCollection);
