import React, { useEffect } from "react";
import { func, object, string } from "prop-types";

import HamItem from "./HamItem";
import HamTable from "./HamTable";
import HamUtils from "./HamUtils";
import Icons, { SvgIcon } from "../../../icons";

import { useDeviceType } from "../../../utils/react-utils";

import "./hamburger-menu.m.css";

const HamburgerMenu = ({ menu, lang, onClose = () => {}, publisherAttributes, footerMenu }) => {
  const icon = lang === "bn" ? "palo-bangla" : "palo-english";
  const { mobile, tab } = useDeviceType();

  const topMenu = menu?.children?.find(({ title }) => title?.match(/top/gim))?.children ?? [];
  const sectionMenu = menu?.children?.find(({ title }) => title?.match(/bottom/gim))?.children ?? [];

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector(".top-menus");
    const { left: parentLeft, bottom: parentBottom, width } = container.getBoundingClientRect();

    for (const child of container.children) {
      const { left, bottom } = child.getBoundingClientRect();
      if (left !== parentLeft) {
        child.classList.add("left-border");
      }
      if (left === parentLeft && bottom < parentBottom - 10) {
        child.classList.add("border");
        child.setAttribute("style", `--parent-width: ${width}px`);
      }
    }
  }, [mobile, tab]);

  return (
    <div styleName="base" className="print-none">
      <div styleName="head-container">
        <div styleName="menu-head">
          <a href="/">
            <Icons type={icon} styleName={icon} onClick={onClose} />
          </a>
          <SvgIcon type="close" styleName="close" onClick={onClose} />
        </div>
      </div>
      <div styleName="ham-bottom">
        <div styleName="bottom-container">
          <div styleName="ham-menus">
            <div styleName="top-menu" className="top-menus">
              {topMenu.map((item, index) => (
                <HamItem click={onClose} key={index} menu={item} className="top-menu-item" styleName="top-item" />
              ))}
            </div>
            <div styleName="bottom-menu">
              <HamTable click={onClose} menus={sectionMenu} />
            </div>
          </div>
          <div styleName="ham-utils">
            <HamUtils publisherAttributes={publisherAttributes} lang={lang} footerMenu={footerMenu} onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

HamburgerMenu.propTypes = {
  menu: object,
  lang: string,
  onClose: func,
  publisherAttributes: object,
  footerMenu: object,
};

export default HamburgerMenu;
