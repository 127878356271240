import React, { useEffect, useState } from "react";
import { bool, func, number, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { parseUrl } from "query-string";
import { sha256 } from "js-sha256";

import Button from "../../atoms/Button";
import ContributorImage from "../../atoms/ContributorImage";
import PopupOverlay from "../../molecules/PopupOverlay";
import Icons from "../../icons";

import { emailLink, logout } from "../../helper/api";
import { setBookmark, setJwtToken, setMember } from "../../helper/actions";
import { removeCookie, setCookie } from "../../utils/utils";

import "./auth-view.m.css";

const { BroadcastChannel } = require("broadcast-channel");

const AuthView = ({
  pageType,
  member,
  currentPath,
  authHost,
  borderColor,
  textColor,
  sketchesHost,
  setMember,
  setJwtToken,
  setBookmark,
  clientId,
  domainSlug,
  googleOneTapClientId,
  googleOneTapGSILink,
  isOneTapEnable,
}) => {
  const [redirectUrl, setRedirectUrl] = useState(sketchesHost);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    const memberTimeout = setTimeout(() => {
      if (!member && isOneTapEnable) {
        googleApi();
      }
    }, 3500);

    return () => clearTimeout(memberTimeout);
  }, [member]);

  function googleApi() {
    const tag = document.createElement("script");
    tag.async = true;
    tag.defer = true;
    tag.onload = function () {
      global.google.accounts.id.initialize({
        client_id: googleOneTapClientId,
        callback: handleCredentialResponse,
      });
      global.google.accounts.id.prompt();
    };
    tag.src = googleOneTapGSILink;
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(tag);
  }

  function handleCredentialResponse(response) {
    fetch("/api/auth/v1/login/google-one-tap", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: response.credential,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const { user = null } = res || {};
        setMember(user);
        setCookie("userID", sha256(user?.email));
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  const params = parseUrl(currentPath);
  const loginUrl =
    authHost +
    "/api/auth/v1/oauth/authorize?client_id=" +
    clientId +
    "&response_type=code&redirect_uri=" +
    authHost +
    "/api/auth/v1/oauth/token&callback_uri=" +
    authHost;

  const FallbackImage = () => <div styleName="first-letter">{member.email.charAt(0)}</div>;

  const getRedirectUrl = (defaultRedirectUrl) => {
    const redirectUrl = get(params, ["query", "redirect-url"], defaultRedirectUrl);
    setRedirectUrl(redirectUrl);
  };

  useEffect(() => {
    if (
      params.url.includes("sign-in-page") ||
      params.url.includes("sign-up-page") ||
      params.url.includes("forgot-password")
    ) {
      getRedirectUrl(sketchesHost);
    } else {
      params.url.includes("verify-email") ? getRedirectUrl(sketchesHost) : getRedirectUrl(global.location.href);
    }
  }, [params]);

  const sendVerificationLink = () => {
    emailLink(member.email, `${redirectUrl}`).then(() => {
      setShowPopUp(true);
      localStorage.setItem("redirectUrl", window.location.href);
    });
  };

  const logoutClick = () => {
    logout().then(() => {
      const channel = new BroadcastChannel("logout-channel");
      channel.postMessage("listen for logout");
      localStorage.removeItem("userID");

      removeCookie("userID");

      setMember(null);
      setJwtToken(null);
      setBookmark(null);
      window.location.href = localStorage.getItem("redirectUrl") || window.location.origin;
      localStorage.removeItem("redirectUrl");
    });
  };

  const isLogoutType = () => {
    return ["profile-page", "edit-profile-page", "change-password-page", "my-comments", "saved-page"].includes(
      pageType
    );
  };

  const changePopupView = () => setShowPopUp(false);

  const notVerifiedView = () => {
    if (pageType === "verify-email-page") {
      return (
        <div styleName={`auth-wrapper`}>
          <Button
            content="Logout"
            buttonType="type4"
            onClick={logoutClick}
            className="btn logout-btn"
            buttonStyle={{ borderColor: borderColor, background: "transparent" }}
            textStyle={{ color: textColor }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div styleName="auth-wrapper">
          <Button
            content="Verify Email"
            buttonType="type4"
            className="desktop-btn btn"
            onClick={sendVerificationLink}
            buttonStyle={{ borderColor: borderColor, background: "transparent" }}
            textStyle={{ color: textColor }}
          />
          <div styleName="mobile-btn btn-wrapper not-verified" onClick={sendVerificationLink}>
            {member["avatar-url"] ? <ContributorImage authorObj={member} memberProfilePic={true} /> : FallbackImage()}
          </div>
        </div>
        {showPopUp && (
          <PopupOverlay
            popupMessage={`We sent an activation email to you at ${member.email}. Please check your email box.`}
            onClickHandler={changePopupView}
          />
        )}
      </React.Fragment>
    );
  };

  // Verified User
  const loggedInView = () => {
    if (isLogoutType()) {
      return (
        <div styleName="auth-wrapper">
          <Button
            content="Logout"
            buttonType="type4"
            onClick={logoutClick}
            className="btn logout-btn"
            buttonStyle={{ borderColor: borderColor, background: "transparent" }}
            textStyle={{ color: textColor }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <a href="/profile">
          <div styleName={`auth-wrapper`}>
            <div styleName="btn-wrapper">
              {member["avatar-url"] ? <ContributorImage authorObj={member} memberProfilePic={true} /> : FallbackImage()}
            </div>
          </div>
        </a>
      </React.Fragment>
    );
  };

  const loginView = () => {
    return (
      <React.Fragment>
        <a aria-label="avatar icon" href={loginUrl} rel="nofollow">
          {domainSlug ? (
            <div styleName="auth-wrapper">
              <Button
                content="Login"
                buttonType="type4"
                className="desktop-btn btn"
                buttonStyle={{ borderColor: borderColor }}
                textStyle={{ color: textColor }}
              />
            </div>
          ) : (
            <div styleName="before-login" className="auth-view">
              <Icons type="user" styleName="avater" />
              <Button
                content="Login"
                styleName="login-button"
                buttonType=""
                className="desktop-btn btn"
                buttonStyle={{ borderColor: borderColor }}
                textStyle={{ color: textColor }}
              />
            </div>
          )}
        </a>
      </React.Fragment>
    );
  };

  if (member) {
    return member["verification-status"] ? loggedInView() : notVerifiedView();
  }

  return loginView();
};

AuthView.propTypes = {
  pageType: string,
  member: object,
  currentPath: string,
  authHost: string,
  sketchesHost: string,
  setMember: func,
  setJwtToken: func,
  setBookmark: func,
  clientId: number,
  domainSlug: string,
  googleOneTapClientId: string,
  googleOneTapGSILink: string,
  isOneTapEnable: bool,
};

const mapStateToProps = (state) => ({
  currentPath: get(state, ["qt", "currentPath"], ""),
  domainSlug: state.qt.config.domainSlug,
  pageType: get(state, ["qt", "pageType"], null),
  member: state.member || null,
  authHost: get(state, ["qt", "currentHostUrl"], ""),
  clientId: get(state, ["qt", "config", "publisher-attributes", "sso_login", "client_id"], 0),
  googleOneTapGSILink: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "google-gsi-link"], ""),
  googleOneTapClientId: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "google-client-id"], ""),
  isOneTapEnable: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "is_enable"], true),
  sketchesHost: get(state, ["qt", "config", "sketches-host"], "https://www.prothomalo.com"),
});

const mapDispatchToProps = (dispatch) => ({
  setMember: (obj) => dispatch(setMember(obj)),
  setJwtToken: (flag) => dispatch(setJwtToken(flag)),
  setBookmark: (obj) => dispatch(setBookmark(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthView);
