import React from "react";
import { func, object, string } from "prop-types";

import Anchor from "../../../../atoms/Anchor";

const HamItem = ({ menu, click = () => {}, className = "", target }) => {
  const { title, "item-type": type, url = "/" } = menu;

  const ops = {};
  if (type !== "placeholder") {
    ops.href = url;
  }

  if (target) {
    ops.target = target;
  }

  const Tag = type === "placeholder" ? "span" : Anchor;

  return (
    <Tag {...ops} className={className + (type === "placeholder" ? " placeholder" : "")}>
      <span onClick={type !== "placeholder" ? click : () => {}}>{title}</span>
    </Tag>
  );
};

HamItem.propTypes = {
  menu: object,
  click: func,
  className: string,
  target: string,
};

export default HamItem;
