export const staticTexts = (lang) => {
  if (lang === "en") {
    return {
      specificSearch: "Search more specifically",
      signIn: "Sign in",
      submit: "Submit",
      group: "Group",
      venue: "Venue",
      NUMBER_ARRAY: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      saveToRead: "Save to read on any device",
      localNews: "Local news",
      search: "Search",
      "e-mail": "e-mail",
      downloadMobileApps: "Download mobile apps",
      newsYouCommented: "No saved items here",
      newsYouSaved: "No saved items here",
      editProfile: "Edit Profile",
      saveToReadLater: "Save to read later",
      alsoRead: "Also Read",
      searchBoxText: "Search",
      language: "English",
      edition: "Edition",
      loadMore: "More",
      more: "Load More",
      relatedStories: "More on",
      morePhotoStories: "More on",
      moreAudioStories: "More on",
      readMore: "Read More",
      readMoreTo: "Read more from ",
      listenMoreTo: "Listen more from",
      advertising: "Advertisement",
      publishedAt: "Published",
      updatedAt: "Updated",
      section: "Section",
      district: "District",
      upazila: "Upazila",
      tag: "Topic",
      filterText: "Filter",
      filterClearText: "Reset Filter",
      author: "Author",
      notifications: "Notifications",
      advanceSearch: "Search",
      sekasana: "Section",
      news: "News",
      sortOrder: "Sort by",
      date: "Date",
      advanceSearchBox: "Search",
      resultsObtained: "The Results Obtained",
      breakingNews: "Breaking News",
      download: "Download",
      storyTypes: "Story types",
      fromTheDate: "Start date",
      upToNow: "End Date",
      shareOnFacebook: "Share on Facebook",
      pleaseEmail: "Email",
      copyLink: "Copy link",
      print: "Print",
      shareOnLinkedIn: "Share on LinkedIn",
      shareOnTwitter: "Share on Twitter",
      shareOnWatsapp: "Share on Watsapp",
      shareOnInsta: "Share on Instagram",
      share: "Share",
      noResultsFound: "No Results Found",
      noResultsBreif:
        "The object you are looking for could not be found. This topic is probably not related " +
        "to Prothom Alo or you are searching incorrectly. Please be sure about your search topic.",
      cover: "Back to Home",
      nextVideo: "Next Video",
      nextAudio: "Next Audio",
      readComments: "Read Comments",
      doComment: "Post Comment",
      liveComment: "Post Comment",
      yourCommentsWillBeSeenHere: "Your comments will show here.",
      save: "SAVE",
      logOut: "Log Out",
      savedPageTitle: "Saved Items",
      savedPageSubTitle: "Your saved item(s) have been added to your reading list. You can access on any device",
      seeAll: "See All",
      commentApproved: "Your comment has been published",
      commentAwaitingApproval: "Awaiting approval",
      liveText: "Live",
      liveEndText: "Live end",
      fewMomentsAgo: "Few moments ago",
      oneMinuteAgo: "1 minute ago",
      minutesAgo: "minutes ago",
      oneHourAgo: "1 hour ago",
      hoursAgo: "hours ago",
      detailsText: "Details...",
      newsletterText: "Newsletter",
      subscribeText: "Subscribe",
      followUsText: "Follow us",
      follow: "Follow us",
      searchMetaTitle: "Search News - Bangladesh, Education, Youth - Prothom Alo English",
      searchMetaDescription:
        "Search Bangladesh, World, Sports, Opinion, Business, Youth, Entertainment, Lifestyle, Science & Technology, Video, Photo, Corporate, Environment Latest News",
      notificationMetaTitle: "Notification - Latest News on Notification | Prothom Alo English",
      notificationMetaDescription:
        "Get latest updates on Notification. Read Notification Breaking News in English, see exclusive videos and photos only on Prothom Alo English",
      latestNews: "Latest News",
      sponsorType: "Paid Partnership",
      voted: "Voted",
      noNotifications: "There are no notifications",
      result: "Result",
      moreTeams: "More teams",
      and: "&",
      shareNow: "Share now",
      readDetails: "Details",
      googleNewsText: "Follow the Google news channel to get Prothom Alo's latest news",
      interview: "Interview",
      shorts: "Shorts",
      "visual-story": "Visual Story",
      video: "Video",
      photo: "Photo",
      audio: "Audio",
      seeMore: "Next",
      next: "Next",
      initiatives: "Initiatives of Prothom Alo",
      sompadok: "Editor & Publisher: Matiur Rahman",
      tickerHeadline: "Headline",
      onlyRegistered: "Only registered users will be able to read the complete text",
      alreadyMember: "If you have a Prothom Alo account",
      login: "Log in",
      orNew: "Or,",
      create: "",
      createAccount: "create a new account",
      question: "Q",
    };
  } else {
    return {
      specificSearch: "আরও নির্দিষ্ট করে খুঁজুন",
      signIn: "সাইন ইন",
      submit: "সাবমিট",
      group: "গ্রুপ",
      venue: "ভেন্যু",
      NUMBER_ARRAY: ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯", "১০"],
      saveToRead: "খবর সংরক্ষণ করে যেকোনো সময় যেকোনো ডিভাইস থেকে পড়তে পারবেন",
      localNews: "আমার এলাকার খবর",
      search: "খুঁজুন",
      "e-mail": "ই-মেইল",
      downloadMobileApps: "মোবাইল অ্যাপস ডাউনলোড করুন",
      newsYouCommented: "সংরক্ষিত কোন আইটেম নাই",
      newsYouSaved: "সংরক্ষিত কোন আইটেম নাই",
      editProfile: "এডিট প্রোফাইল",
      saveToReadLater: "পরবর্তীতে পড়ার জন্য সংরক্ষণ",
      alsoRead: "আরও পড়ুন",
      searchBoxText: "যা খুঁজতে চান",
      language: "বাংলা",
      edition: "সংস্করণ",
      loadMore: "আরও",
      more: "আরও",
      relatedStories: "নিয়ে আরও পড়ুন",
      morePhotoStories: "নিয়ে আরও দেখুন",
      moreAudioStories: "নিয়ে আরও শুনুন",
      readMore: "আরও পড়ুন",
      readMoreTo: " থেকে আরও পড়ুন",
      listenMoreTo: "থেকে আরও শুনুন",
      advertising: "বিজ্ঞাপন",
      publishedAt: "প্রকাশ",
      updatedAt: "আপডেট",
      section: "বিভাগ",
      district: "জেলা",
      upazila: "উপজেলা",
      tag: "বিষয়",
      filterText: "বাছাই করুন",
      filterClearText: "মুছে ফেলুন",
      author: "লেখক",
      notifications: "নোটিফিকেশন",
      advanceSearch: "অনুসন্ধান",
      sekasana: "সেকশন",
      news: "খবর",
      sortOrder: "সাজানো",
      date: "তারিখ",
      advanceSearchBox: "অনুসন্ধান করুন",
      resultsObtained: "প্রাপ্ত ফলাফল",
      breakingNews: "সদ্য পাওয়া",
      download: "ডাউনলোড",
      storyTypes: "ধরন",
      fromTheDate: "শুরুর তারিখ",
      upToNow: "শেষ তারিখ",
      shareNow: "শেয়ার করুন",
      shareOnFacebook: "ফেসবুকে শেয়ার করুন",
      pleaseEmail: "ইমেইল করুন",
      copyLink: "খবরের লিংক কপি করুন",
      print: "প্রিন্ট করুন",
      shareOnLinkedIn: "লিংকডইনে শেয়ার করুন",
      shareOnTwitter: "টুইটারে শেয়ার করুন",
      shareOnWatsapp: "হোয়াটসঅ্যাপে শেয়ার করুন",
      shareOnInsta: "ইনস্টাগ্রামে শেয়ার করুন",
      share: "শেয়ার",
      noResultsFound: "কিছু পাওয়া যায়নি",
      noResultsBreif:
        "আপনি যা খুঁজছেন, তা পাওয়া যায়নি। বিষয়টি সম্ভবত প্রথম আলোর নয় কিংবা আপনি ভুলভাবে খুঁজছেন। " +
        "দয়া করে, বিষয়টি সম্পর্কে নিশ্চিত হয়ে নিন।",
      cover: "প্রচ্ছদে ফিরে যান",
      nextVideo: "পরবর্তী ভিডিও",
      nextAudio: "পরবর্তী অডিও",
      readComments: "মন্তব্য পড়ুন",
      doComment: "মন্তব্য করুন",
      liveComment: "আলোচনায় অংশ নিন",
      yourCommentsWillBeSeenHere: "আপনার করা মন্তব্য এখানে পাওয়া যাবে",
      save: "সংরক্ষণ",
      logOut: "লগ আউট",
      savedPageTitle: "আপনার সংরক্ষিত খবর",
      savedPageSubTitle:
        "আপনার সংরক্ষণ করা গল্পগুলি আপনার পঠন তালিকায় যুক্ত করা হয়েছে। যে কোনও ডিভাইসে অ্যাক্সেস করুন।",
      seeAll: "সব দেখুন",
      commentApproved: "আপনার মন্তব্য প্রকাশিত হয়েছে",
      commentAwaitingApproval: "অনুমোদনের জন্য অপেক্ষমান",
      liveText: "সরাসরি",
      liveEndText: "সম্প্রচার শেষ",
      fewMomentsAgo: "কিছুক্ষণ আগে",
      oneMinuteAgo: "১ মিনিট আগে",
      minutesAgo: "মিনিট আগে",
      oneHourAgo: "১ ঘণ্টা আগে",
      hoursAgo: "ঘণ্টা আগে",
      detailsText: "বিস্তারিত...",
      newsletterText: "প্রথমআলো নিউজ লেটার",
      subscribeText: "সাবস্ক্রাইব",
      followUsText: "অনুসরণ করুন",
      follow: "ফলো করুন",
      searchMetaTitle: "নিউজ সার্চ - রাজনীতি, বাংলাদেশ, খেলা, বিনোদন, ছবি ও ভিডিও",
      searchMetaDescription:
        "নিউজ সার্চ করুন - বাংলাদেশ, রাজনীতি, খেলা, বিনোদন, বাণিজ্য, বিশ্ব, লাইফস্টাইল, জীবনযাপন, মতামত, চাকরি, শিক্ষা, ফিচার, বিজ্ঞান ও প্রযুক্তি এর খবর",
      notificationMetaTitle: "নোটিফিকেশন নিউজ - আপডেট ব্রেকিং নিউজ",
      notificationMetaDescription:
        "আপডেট ব্রেকিং নোটিফিকেশন নিউজ - ভিডিও, ছবি, বাংলাদেশ, খেলা, বিনোদন, রাজনীতি, বাণিজ্য, বিশ্ব, লাইফস্টাইল, ফিচার, শিক্ষা, বিজ্ঞান ও প্রযুক্তি",
      latestNews: "সর্বশেষ খবর",
      sponsorType: "বিজ্ঞাপন বার্তা",
      voted: "ভোট দিয়েছেন",
      noNotifications: "নতুন কোন নোটিফিকেশন নেই",
      result: "ফলাফল",
      moreTeams: "আরও দল পরিচিতি",
      and: "ও",
      readDetails: "বিস্তারিত পড়ুন",
      googleNewsText: "প্রথম আলোর খবর পেতে গুগল নিউজ চ্যানেল ফলো করুন",
      interview: "সাক্ষাৎকার",
      shorts: "শর্টস",
      "visual-story": "ভিজ্যুয়াল স্টোরি",
      video: "ভিডিও",
      photo: "ছবি",
      audio: "অডিও",
      seeMore: "আরও দেখুন",
      next: "পরবর্তী",
      initiatives: "প্রথম আলোর অন্যান্য উদ্যোগ",
      sompadok: "সম্পাদক ও প্রকাশক: মতিউর রহমান",
      tickerHeadline: "শিরোনাম",
      onlyRegistered: "শুধু নিবন্ধিত ব্যবহারকারীরাই পুরো লেখা পড়তে পারবেন।",
      alreadyMember: "প্রথম আলোতে আপনার অ্যাকাউন্ট থাকলে",
      login: "লগইন করুন",
      orNew: "অথবা নতুন",
      create: "করুন",
      createAccount: "অ্যাকাউন্ট তৈরি",
      question: "প্রশ্ন",
    };
  }
};
