import React from "react";
import { LoadingIndicator } from "@quintype/components";

import "./loading-indicator.m.css";

const LoadingIndicatorComponent = () => {
  return (
    <LoadingIndicator styleName="loading-indicator">
      <div styleName="qt-loading-animation" className="print-none">
        <LoadingIcon />
      </div>
    </LoadingIndicator>
  );
};

function LoadingIcon() {
  return (
    <svg
      width="128px"
      height="128px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="lds-ellipsis"
      style={{ background: "none" }}
    >
      <g transform="rotate(0 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-1.097560975609756s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(36 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.9756097560975608s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(72 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.8536585365853657s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(108 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.7317073170731706s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(144 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.6097560975609755s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.4878048780487804s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(216 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.3658536585365853s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(252 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.2439024390243902s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(288 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="-0.1219512195121951s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(324 50 50)">
        <rect x="49" y="27" rx="0" ry="0" width="2" height="10" fill="#d60000">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.2195121951219512s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  );
}

export default LoadingIndicatorComponent;
