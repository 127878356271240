import React, { useEffect, useState } from "react";
import { bool, func, number, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { sha256 } from "js-sha256";

import Button from "../../atoms/Button";
import ContributorImage from "../../atoms/ContributorImage";

import { SvgIcon } from "../../icons";

import { logout } from "../../helper/api";
import { useOuterClick } from "../../utils/react-utils";
import { setBookmark, setJwtToken, setMember } from "../../helper/actions";
import { removeCookie, setCookie } from "../../utils/utils";

import "./palo-auth.m.css";

const { BroadcastChannel } = require("broadcast-channel");

const PaloAuth = ({
  member,
  setMember,
  setJwtToken,
  setBookmark,
  clientId,
  googleOneTapClientId,
  googleOneTapGSILink,
  isOneTapEnable,
  hamburger,
  currentPath,
}) => {
  const [loggedin, setLoggedin] = useState(false);
  const [origin, setOrigin] = useState("");
  const innerRef = useOuterClick((ev) => {
    setLoggedin(false);
  });

  useEffect(() => {
    const memberTimeout = setTimeout(() => {
      if (!member && isOneTapEnable) {
        googleApi();
      }
    }, 3500);

    return () => clearTimeout(memberTimeout);
  }, [member]);

  useEffect(() => {
    if (window) {
      setOrigin(window.location.origin);
    }
  }, []);

  function googleApi() {
    const tag = document.createElement("script");
    tag.async = true;
    tag.defer = true;
    tag.onload = function () {
      global.google.accounts.id.initialize({
        client_id: googleOneTapClientId,
        callback: handleCredentialResponse,
      });
      global.google.accounts.id.prompt();
    };
    tag.src = googleOneTapGSILink;
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(tag);
  }

  function handleCredentialResponse(response) {
    fetch("/api/auth/v1/login/google-one-tap", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: response.credential,
        "redirect-url": global?.location?.href,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const { user = null } = res || {};
        setMember(user);
        setCookie("userID", sha256(user?.email));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        window.location.reload();
      });
  }

  const loginUrl =
    origin +
    "/api/auth/v1/oauth/authorize?client_id=" +
    clientId +
    "&response_type=code&redirect_uri=" +
    origin +
    "/api/auth/v1/oauth/token&callback_uri=" +
    origin +
    currentPath.split("?")[0];

  const FallbackImage = () => <div styleName="first-letter">{member?.email?.charAt(0) ?? ""}</div>;

  const logoutClick = () => {
    logout().then(() => {
      const channel = new BroadcastChannel("logout-channel");
      channel.postMessage("listen for logout");
      localStorage.removeItem("userID");
      removeCookie("userID");

      setMember(null);
      setJwtToken(null);
      setBookmark(null);
      window.location.href = localStorage.getItem("redirectUrl") || window.location.origin;
      localStorage.removeItem("redirectUrl");
    });
  };

  const loggedInView = () => {
    return (
      <div styleName="logged-in" className="auth-view">
        <div styleName="logged-in-container">
          <div styleName="user" className="user-info" ref={innerRef} onClick={() => setLoggedin((prev) => !prev)}>
            <div styleName="my-img">
              {member?.["avatar-url"] ? (
                <ContributorImage authorObj={member} memberProfilePic={true} />
              ) : (
                FallbackImage()
              )}
            </div>
            {hamburger && (
              <span styleName="user-name" className="member-name">
                {member?.name}
              </span>
            )}
            <SvgIcon type="arrow-up" styleName="arrow-down" />
          </div>
          {loggedin && (
            <div styleName="click-open" className="auth-click-open">
              <div styleName="profile-button">
                <Link href="/profile" target="_self" styleName="my-link">
                  {member?.["avatar-url"] ? (
                    <ContributorImage authorObj={member} memberProfilePic={true} />
                  ) : (
                    FallbackImage()
                  )}
                  <span styleName="my-account text">My Account</span>
                </Link>

                <SvgIcon type="arrow-up" styleName="arrow-up" />
              </div>
              <div styleName="logout-button" onClick={logoutClick}>
                <SvgIcon type="logout-circle" styleName="logout-circle" />
                <span styleName="logout-txt text">Log Out</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const loginView = () => {
    return (
      <a styleName="before-login-wrapper" className="auth-view" aria-label="avatar icon" href={loginUrl} rel="nofollow">
        <div styleName="before-login" className="before-login">
          <SvgIcon type="user" styleName="avater" />
          <Button content="Login" styleName="login-button" buttonType="" className="desktop-btn btn" />
        </div>
      </a>
    );
  };

  if (member) {
    return loggedInView();
  }

  return loginView();
};

PaloAuth.propTypes = {
  member: object,
  setMember: func,
  setJwtToken: func,
  setBookmark: func,
  clientId: number,
  googleOneTapClientId: string,
  googleOneTapGSILink: string,
  isOneTapEnable: bool,
  hamburger: bool,
  currentPath: string,
};

const mapStateToProps = (state) => ({
  member: state.member || null,
  clientId: get(state, ["qt", "config", "publisher-attributes", "sso_login", "client_id"], 0),
  googleOneTapGSILink: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "google-gsi-link"], ""),
  googleOneTapClientId: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "google-client-id"], ""),
  isOneTapEnable: get(state, ["qt", "config", "publisher-attributes", "one_tap_login", "is_enable"], true),
  sketchesHost: get(state, ["qt", "config", "sketches-host"], "https://www.prothomalo.com"),
  currentPath: get(state, ["qt", "currentPath"], ""),
});

const mapDispatchToProps = (dispatch) => ({
  setMember: (obj) => dispatch(setMember(obj)),
  setJwtToken: (flag) => dispatch(setJwtToken(flag)),
  setBookmark: (obj) => dispatch(setBookmark(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaloAuth);
