import {
  AppStore,
  Arrow,
  ArrowUp,
  AudioOutline,
  Close,
  DoubleArrow,
  FacebookFill,
  FacebookOutline,
  Globe,
  GoogleNews,
  GoogleNewsFill,
  GoogleNewsOutLine,
  Hamburger,
  InstagramFill,
  InstagramOutline,
  InterviewOutline,
  InterviewStoryIcon,
  LiveIcon,
  Location,
  LogoutCircle,
  NewsPaper,
  Pause,
  PhotoOutline,
  Play,
  PlayStore,
  Search,
  ShortsOutline,
  Star,
  TodaysDefault,
  TodaysTime,
  TodaysTV,
  TrendingOutline,
  TwitterFill,
  TwitterOutline,
  User,
  VideoOutline,
  VisualStoryOutline,
  YoutubeFill,
  YoutubeOutline,
  YoutubeSubscription,
} from "./icon-list";

export default {
  photo: PhotoOutline,
  video: VideoOutline,
  trending: TrendingOutline,
  shorts: ShortsOutline,
  interview: InterviewOutline,
  audio: AudioOutline,
  live: LiveIcon,
  globe: Globe,
  newspaper: NewsPaper,
  search: Search,
  hamburger: Hamburger,
  close: Close,
  user: User,
  arrow: Arrow,
  star: Star,
  location: Location,
  "interview-story-icon": InterviewStoryIcon,
  "todays-time": TodaysTime,
  "todays-tv": TodaysTV,
  "todays-default": TodaysDefault,
  "visual-story": VisualStoryOutline,
  "double-arrow": DoubleArrow,
  "google-news": GoogleNews,
  "youtube-subscription": YoutubeSubscription,
  "instagram-outline": InstagramOutline,
  "instagram-fill": InstagramFill,
  "arrow-up": ArrowUp,
  "logout-circle": LogoutCircle,
  "facebook-outline": FacebookOutline,
  "facebook-fill": FacebookFill,
  "twitter-outline": TwitterOutline,
  "twitter-fill": TwitterFill,
  "youtube-outline": YoutubeOutline,
  "youtube-fill": YoutubeFill,
  "google-news-fill": GoogleNewsFill,
  "google-news-outline": GoogleNewsOutLine,
  playstore: PlayStore,
  appstore: AppStore,
  play: Play,
  pause: Pause,
};
