import React, { useEffect, useState } from "react";
import { array, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import FooterMenuGroup from "../../../molecules/FooterMenuGroup";
import { Instagram } from "../../../atoms/icons/StoryPageIcons";
import FacebookIcon from "../FooterIcons/FacebookIcon";
import TwitterIcon from "../FooterIcons/TwitterIcon";
import YoutubeIcon from "../FooterIcons/YoutubeIcon";
import Icon from "../../../icons";

import { menuGroups } from "../../../helper/api";
import { completeUrlSetter, toBanglaNum } from "../../../utils/utils";

import "./bigganChintaFooter.m.css";

const BigganChintaFooterBase = ({ bigganChintaMenuItems, currentPath, customSocialLinks, socialLinks, sections }) => {
  const socialShareLinks = customSocialLinks.bigganchinta || customSocialLinks["biggan-chinta"] || socialLinks;
  const [authMenu, setAuthMenu] = useState({});
  const currentYear = toBanglaNum(new Date().getFullYear());

  useEffect(() => {
    if (global.location.origin.includes("auth")) {
      menuGroups().then((res) => {
        const menu = get(res, ["menu-groups", "biggan-chinta"], []);
        const footerMenu = menu.items.filter((item) => item.title === "footer");
        const footerChildren =
          footerMenu.length > 0 ? menu.items.filter((item) => item["parent-id"] === footerMenu[0].id) : [];
        const footerChildrenObj = { children: footerChildren };
        setAuthMenu(completeUrlSetter(footerChildrenObj, sections));
      });
    } else if (bigganChintaMenuItems) {
      const menuItems =
        bigganChintaMenuItems && bigganChintaMenuItems.length
          ? bigganChintaMenuItems.find((item) => item.title === "footer")
          : null;
      setAuthMenu(menuItems);
    }
  }, [bigganChintaMenuItems]);

  return (
    <div styleName="wrapper">
      <div styleName="container">
        <div className="logo-editors-wrapper">
          <a aria-label="Bigganchinta logo" href="/">
            <Icon type="biggan-chinta" styleName="biggan-chinta-logo" />
          </a>
          <ul styleName="editors-data" className="print-none">
            <li>প্রকাশক ও সম্পাদক: আব্দুল কাইয়ুম</li>
            <li>নির্বাহী সম্পাদক: আবুল বাসার</li>
          </ul>
        </div>
        <div styleName="menu-items-wrapper" className="print-none">
          {authMenu && authMenu.children && authMenu.children.length > 0 && (
            <FooterMenuGroup links={authMenu.children.slice(0, 20)} />
          )}
        </div>
        <div className="print-none">
          <div styleName="social-share-wrapper">
            {socialShareLinks["facebook-url"] && (
              <Link
                aria-label="Bigganchinta Facebook Link"
                href={socialShareLinks["facebook-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
            )}
            {socialShareLinks["twitter-url"] && (
              <Link
                aria-label="Bigganchinta Twitter Link"
                href={socialShareLinks["twitter-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </Link>
            )}
            {socialShareLinks["instagram-url"] && (
              <Link
                aria-label="Bigganchinta Instagram Link"
                href={socialShareLinks["instagram-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </Link>
            )}
            {socialShareLinks["youtube-url"] && (
              <Link
                aria-label="Bigganchinta Youtube Link"
                href={socialShareLinks["youtube-url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </Link>
            )}
          </div>
          <ul styleName="static-data">
            <li>প্রগতি ইনস্যুরেন্স ভবন</li>
            <li>২০-২১ কারওয়ান বাজার, ঢাকা - ১২১৫</li>
            <li>ফোন: ৫৫০১৩৪৩০-৩১</li>
            <li>ইমেইল: editor@bigganchinta.com</li>
            <li styleName="copyrights">© স্বত্ব বিজ্ঞানচিন্তা {currentYear}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sections: get(state, ["qt", "config", "sections"], []),
  bigganChintaMenuItems: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

BigganChintaFooterBase.propTypes = {
  bigganChintaMenuItems: array,
  currentPath: string,
  customSocialLinks: object,
  socialLinks: object,
  sections: array,
};

export const BigganChintaFooter = connect(mapStateToProps)(BigganChintaFooterBase);
