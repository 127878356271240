import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { array, bool, number, object, string, func } from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

import LeftArrowButton from "../icons/left-arrow-button";
import PlayPauseButton from "../icons/play-pause-icon";
import RightArrowButton from "../icons/right-arrow-button";

import { toBanglaNum } from "../../utils/utils";

import "./swiperStyle.scss";
import "./customSlider.m.css";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const CustomSlider = ({
  children,
  isPagination,
  isNavigation,
  isAutoplay,
  interval,
  slidesPerView,
  spacing,
  isNoPaddingMobile,
  pauseOnHover,
  allowTouchMove,
  hideDisableIcon,
  showPlayPauseBtn,
  showProgressBar,
  showSlideNumber,
  lang,
  slideNumOpen,
  navigateToPage,
  storySlug,
  currentSlide,
  setCurrentSlide,
  isPopup,
}) => {
  const { mobileSlides, tabSlides, desktopSlides } = slidesPerView;
  const { mobileSpace, tabSpace, desktopSpace } = spacing;

  const [swiper, setSwiper] = useState(null);
  const [isPlaying, setIsPlaying] = useState(isAutoplay);
  const [progressBar, setProgressBar] = useState(showProgressBar);
  const [countClick, setCountClick] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const toggleAutoplay = () => {
    if (swiper) {
      if (isPlaying) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }

      setProgressBar(!progressBar);
      setIsPlaying(!isPlaying);
    }
  };

  const handleSlideChange = ({ realIndex, isBeginning, isEnd }) => {
    setCurrentSlide && setCurrentSlide(realIndex);
    setIsBeginning(isBeginning);
    setIsEnd(isEnd);
  };

  const handleNextButtonClick = () => {
    setCountClick(countClick + 1);
    swiper.slideNext();
    setProgressBar(false);
    setIsPlaying(false);

    if (slideNumOpen > 0 && slideNumOpen === countClick + 1) {
      navigateToPage(`/${storySlug}`);
    }
  };

  const handlePrevButtonClick = () => {
    setCountClick(countClick - 1);
    swiper.slidePrev();
    setProgressBar(false);
    setIsPlaying(false);
  };

  useEffect(() => {
    if (isPopup) {
      swiper?.slideTo(currentSlide);
    }
  }, [swiper, currentSlide]);

  const DEFAULT_SWIPER_SPEED = 300;
  const noOfItems = children.length;

  const breakpoints = {
    320: {
      slidesPerView: mobileSlides,
      spaceBetween: mobileSpace,
    },

    769: {
      slidesPerView: tabSlides,
      spaceBetween: tabSpace,
    },

    1025: {
      slidesPerView: desktopSlides,
      spaceBetween: desktopSpace,
    },
  };

  const customNavigation = () => (
    <>
      {hideDisableIcon && isBeginning ? null : (
        <span
          onClick={handlePrevButtonClick}
          styleName={`left-arrow-icon ${isBeginning ? "disable-arrow" : ""}`}
          className={`left-arrow ${isAutoplay ? "auto-play" : ""} ${isBeginning ? "disable-slider-arrow" : ""}`}
        >
          <LeftArrowButton
            color="var(--rgbBlack)"
            opacity={isBeginning ? "var(--opacity-38)" : "var(--opacity-60)"}
            opacity2={isBeginning ? "var(--opacity-38)" : "var(--opacity-100)"}
            color2="var(--white)"
          />
        </span>
      )}

      {showPlayPauseBtn && isAutoplay && (
        <span styleName="pause-play-icon" className="pause-play-icon" onClick={toggleAutoplay}>
          <PlayPauseButton isPlaying={!isPlaying} />
        </span>
      )}

      {hideDisableIcon && isEnd ? null : (
        <span
          onClick={handleNextButtonClick}
          styleName={`right-arrow-icon ${isEnd ? "disable-arrow" : ""}`}
          className={`right-arrow ${isEnd ? "disable-slider-arrow" : ""}`}
        >
          <RightArrowButton
            color="var(--rgbBlack)"
            color2="var(--white)"
            opacity={isEnd ? "var(--opacity-38)" : "var(--opacity-60)"}
            opacity2={isEnd ? "var(--opacity-38)" : "var(--opacity-100)"}
          />
        </span>
      )}
    </>
  );

  return (
    <div
      styleName={`wrapper ${isNoPaddingMobile ? "no-padding-mobile" : ""}`}
      className={`custom-swiper ${isAutoplay ? "auto-play" : ""}`}
    >
      {isAutoplay && progressBar && (
        <div styleName="slider-progress" style={{ "--interval": interval + DEFAULT_SWIPER_SPEED + "ms" }} />
      )}

      {showSlideNumber && (
        <div styleName="slide-number" className="slide-show-icons">
          <span styleName="slider-image-index" className="image-index">{`${
            lang === "en" ? currentSlide + 1 : toBanglaNum(currentSlide + 1)
          } / ${lang === "en" ? noOfItems : toBanglaNum(noOfItems)}`}</span>
        </div>
      )}

      <Swiper
        onSwiper={setSwiper}
        navigation={false}
        slidesPerView={1}
        spaceBetween={mobileSpace}
        pagination={
          isPagination
            ? {
                clickable: true,
              }
            : false
        }
        autoplay={
          isAutoplay
            ? {
                delay: interval,
                disableOnInteraction: pauseOnHover,
              }
            : false
        }
        breakpoints={breakpoints}
        allowTouchMove={allowTouchMove}
        onSlideChange={handleSlideChange}
      >
        {children.map((element, i) => (
          <SwiperSlide key={i}>{element}</SwiperSlide>
        ))}
      </Swiper>

      {isNavigation ? customNavigation() : null}
    </div>
  );
};

CustomSlider.defaultProps = {
  children: [],
  isNavigation: true,
  isPagination: true,
  isAutoplay: false,
  isNoPaddingMobile: false,
  pauseOnHover: true,
  allowTouchMove: true,
  hideDisableIcon: false,
  showPlayPauseBtn: false,
  showProgressBar: false,
  showSlideNumber: false,
  interval: 5000,
  storySlug: "",
  slideNumOpen: 0,
  currentSlide: 0,
  isPopup: false,
  setCurrentSlide: null,
  slidesPerView: { mobileSlides: 1, tabSlides: 3, desktopSlides: 4 },
  spacing: { mobileSpace: 16, tabSpace: 24, desktopSpace: 24 },
};

CustomSlider.propTypes = {
  children: array.isRequired,
  isNavigation: bool,
  isPagination: bool,
  isAutoplay: bool,
  isNoPaddingMobile: bool,
  pauseOnHover: bool,
  interval: number,
  slidesPerView: object,
  spacing: object,
  allowTouchMove: bool,
  hideDisableIcon: bool,
  showPlayPauseBtn: bool,
  showProgressBar: bool,
  showSlideNumber: bool,
  lang: string,
  navigateToPage: func,
  setCurrentSlide: func,
  slideNumOpen: number,
  storySlug: string,
  currentSlide: number,
  isPopup: bool,
};

const mapStateToProps = (state) => ({
  lang: state.qt.config["publisher-attributes"].lang || "bn",
});

const mapDispatchToProps = (dispatch) => ({
  navigateToPage: (url) => global.app.navigateToPage(dispatch, url),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSlider);
