import React from "react";
import { bool, func, number, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import Button from "../../atoms/Button";
import CloseIcon from "../../atoms/icons/close";

import "./popupOverlay.m.css";

const PopupOverlay = ({
  popupMessage,
  onClickHandler,
  showButton = true,
  buttonType = "",
  buttonContent = "Okay",
  onCloseHandler,
  clientId,
}) => {
  const buttonText = buttonType === "login" && buttonContent ? buttonContent : "Login";
  const loginUrl = `/api/auth/v1/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${global.location.origin}/api/auth/v1/oauth/token&callback_uri=${global.location.href}`;
  return (
    <div className="popup-wrapper">
      <div styleName="popup-container" className="popup-container" id="popup-container">
        <div styleName="popup-content">{popupMessage}</div>
        <div styleName="close-btn" onClick={onCloseHandler}>
          <CloseIcon width={20} height={20} color="#555555" />
        </div>
        {showButton && buttonType !== "login" && buttonType !== "signup" && (
          <Button content={buttonContent} buttonType="type3" onClick={onClickHandler} />
        )}
        {buttonType === "login" && (
          <div styleName="login-popup">
            <a aria-label="Login link" target="_self" href={loginUrl} rel="nofollow">
              <Button content={buttonText} buttonType="type4" />
            </a>
            <Button content="Cancel" onClick={onCloseHandler} buttonType="type3" />
          </div>
        )}
      </div>
      <div styleName="bg-overlay"></div>
    </div>
  );
};

PopupOverlay.propTypes = {
  popupMessage: string,
  onClickHandler: func,
  showButton: bool,
  buttonType: string,
  buttonContent: string,
  onCloseHandler: func,
  clientId: number,
};

const mapStateToProps = (state) => ({
  clientId: get(state, ["qt", "config", "publisher-attributes", "sso_login", "client_id"], 0),
});

export default connect(mapStateToProps)(PopupOverlay);
