import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { object } from "prop-types";
import { Link } from "@quintype/components";

import FacebookIcon from "../FooterIcons/FacebookIcon";
import TwitterIcon from "../FooterIcons/TwitterIcon";
import YoutubeIcon from "../FooterIcons/YoutubeIcon";
import { Instagram } from "../../../atoms/icons/StoryPageIcons";

import { toBanglaNum } from "../../../utils/utils";

import "./bondhushavaFooter.m.css";

const BondhushavaFooter = ({ customSocialLinks, socialLinks }) => {
  const [currentYear, setCurrentYear] = useState("");
  const socialShareLinks = customSocialLinks.bondhushava || socialLinks;

  useEffect(() => {
    setCurrentYear(toBanglaNum(new Date().getFullYear()));
  }, []);

  return (
    <div styleName="bondhushava-footer">
      <div styleName="address" className="print-none">
        <span className="line-1">বন্ধুসভা কক্ষ, প্রথম আলো ভবন (দ্বিতীয় তলা)</span>
        <span className="line-2">১৯ কারওয়ান বাজার, ঢাকা-১২১৫</span>
        <span className="line-4">ফোন: ৮১৮০০৭৮–৮১ (২২৩০) </span>
        <span className="line-5">মোবাইল: ০১৯৫৫৫৫২০৮৮</span>
        <span className="line-6">
          ইমেইল:{" "}
          <a aria-label="bondhushava@prothomalo.com" href="mailto:bondhushava@prothomalo.com">
            bondhushava@prothomalo.com
          </a>
        </span>
      </div>
      <div styleName="social-links" className="print-none">
        {socialShareLinks["facebook-url"] && (
          <Link
            aria-label="Bondhushava Facebook Link"
            href={socialShareLinks["facebook-url"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon color="#26afe8" />
          </Link>
        )}
        {socialShareLinks["twitter-url"] && (
          <Link
            aria-label="Bondhushava Twitter Link"
            href={socialShareLinks["twitter-url"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon color="#26afe8" />
          </Link>
        )}
        {socialShareLinks["instagram-url"] && (
          <Link
            aria-label="Bondhushava Instagram Link"
            href={socialShareLinks["instagram-url"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </Link>
        )}
        {socialShareLinks["youtube-url"] && (
          <Link
            aria-label="Bondhushava Youtube Link"
            href={socialShareLinks["youtube-url"]}
            className="youtube-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon color="#26afe8" />
          </Link>
        )}
      </div>
      <span>© স্বত্ব বন্ধুসভা ১৯৯৮–{currentYear}</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

BondhushavaFooter.propTypes = {
  socialLinks: object,
  customSocialLinks: object,
};

export default connect(mapStateToProps)(BondhushavaFooter);
